import React, { Component, useRef, useEffect } from "react";
import StoreIcon from "../assets/images/store_pickup.png";
import HomeIcon from "../assets/images/map-marker.svg"
const GMap = (props) => {
  const googleMapRef = useRef(null);
  let googleMap = null;
  console.log(props.data)
  // list of icons

  // list of the marker object along with icon
  const markerList = [
    { lat: parseFloat(props.data?.pickup_lat), lng: parseFloat(props.data?.pickup_lng), icon: StoreIcon, title: props.data.pickup_address },
    { lat: parseFloat(props.data?.delivery_lat), lng: parseFloat(props.data?.delivery_lng), icon: HomeIcon, title: props.data.delivery_address },
    // { lat: parseFloat(props.data?.agent?.latitude), lng: parseFloat(props.data?.agent?.longitude), icon: HomeIcon, title: '' },
  ]
  console.log(markerList)
  useEffect(() => {
    googleMap = initGoogleMap();
    var bounds = new window.google.maps.LatLngBounds();
    markerList.map(x => {
      const marker = createMarker(x);
      bounds.extend(marker.position);
    });
    googleMap.fitBounds(bounds); // the map to contain all markers
  }, []);


  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: { lat: parseFloat(props.data?.pickup_lat), lng: parseFloat(props.data?.pickup_lng) },
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false
    });
  }

  // create marker on google map
  const createMarker = (markerObj) => new window.google.maps.Marker({
    position: { lat: markerObj.lat, lng: markerObj.lng },
    map: googleMap,
    icon: {
      url: markerObj.icon,
      title: markerObj.title,
      // set marker width and height
      scaledSize: new window.google.maps.Size(25, 25)
    }
  });

  return <div
    ref={googleMapRef}
    style={{ maxWidth: 400, height: 250 }}
  />
}

export default GMap;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyAl8q9s5LY5qUCPybXG-FWIp717RMB8IqE'
// })(MapContainer)

import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { useHistory } from "react-router-dom"
import { ArrowBackIos } from "@material-ui/icons"
import GButton from "../gComponents/gButton";
import GInfo from "../gComponents/gInfo";
import GTableShimmer from "../gComponents/gTableShimmer"
import GItemTable from "../gComponents/gCartItems";
import {
  GContent,
  GInlineContent,
  GTableContent,
} from "../gComponents/gContent";
import Modal from "react-modal";
import CancelReasonsView from "./orderCancelReasons";
import OrderFeedback from "./orderFeedback";
import OrderReport from "./orderReport";
import OrderMessages from "./orderMesaages";
import phoneIcon from "../assets/images/phone.png"
import chatIcon from "../assets/images/chat.png"
import NavigateIcon from "../assets/images/navigation.png"
import OrderTrack from "../gComponents/googleMap";
import { RepeatOrder } from "../Models/RepeatOrder";
import useCart from "../useCart";
import CartModal from "./cartModal";

var sectionTitle = {
  fontStyle: "italic",
  color: "#555",
  textTransform: "uppercase",
  margin: "20px 0 10px",
}

export default function OrderDetail(props) {
  const [orderData, setOrderData] = useState({});
  const [ocrvisible, setOcrVisible] = useState(false);
  const [omvisible, setOmvisible] = useState(false);
  const [ofvisible, setOfvisible] = useState(false);
  const [oReportvisible, setOReportvisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { setCart, setCartStore } = useCart();
  // const [repeatOrder, setRepeatOrder] = useState(false);
  const [cartvisible, setCartvisible] = useState(false);
  const [refresh, setRefesh] = useState(false);
  const [orderMessages, setOrderMessages] = useState([]);
  const {
    status,
    store,
    delivery,
    order_products,
    payment_summary,
    order_returns, order_review
  } = orderData;
  const [openSnackbar] = useSnackbar();

  let history = useHistory();
  // const location = useLocation();

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "c/orders/" + props.linkedId })
      .then((response) => {
        console.log("gh" + response);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setIsLoading(false)
          setOrderMessages(response.data.data.order_messages);
        } else {
          console.log(response.data);
          openSnackbar(response.data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [props,omvisible,refresh]);


  if (props.orderInfovisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos className="backIcon" onClick={() => props.setOrderInfovisible(false)} /> Order Detail
        {(status?.title === 'Pending' || status?.title === 'Accepted') ? <GButton variant="linkable" children="Problem?" type="button" style={{ marginLeft: 'auto' }} onClick={() => setOcrVisible(true)} /> : ''}
        {(status?.title === 'Completed' && !order_review) ? <GButton variant="linkable" children="Feedback" type="button" style={{ marginLeft: 'auto' }} onClick={() => setOfvisible(true)} /> : ''}
      </div>

      <div className="modal-body" style={{ padding: "0 25px", height: status?.title === 'Completed' ? 'calc(100vh - 110px)' : 'calc(100vh - 70px)' }}>

        {isLoading === true ? <GTableShimmer /> :
          <React.Fragment>
            {status?.title === 'Completed' ?
              !order_returns ?
                <div style={{ textAlign: 'right', background: '#f4f5f9', margin: '0 -25px' }}><GButton
                  variant="linkable"
                  children="Report Faulty or Expired?"
                  type="button"
                  style={{ marginLeft: 'auto', padding: '10px 20px', fontSize: '14px' }}
                  onClick={() => setOReportvisible(true)}
                /></div>
                : <div style={{ background: '#f4f5f9', margin: '0 -25px', display: 'flex', padding: '5px 24px 8px' }}>
                  <p style={{ color: '#f08c37', fontSize: '14px' }}>Faulty/Expired Requested Status</p>
                  <p style={{ textTransform: 'uppercase', marginLeft: 'auto', fontSize: '13px', color: '#777', letterSpacing: '0.2px' }}>{order_returns.status}</p>
                </div>
              : ''}


            {!(status?.title === 'Completed' || status?.title === 'Cancelled') ? delivery?.id && delivery?.agent?.id ? <React.Fragment> <OrderTrack deliveryData={delivery} />  <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} /><h5 style={Object.assign({}, sectionTitle, { margin: '15px 0 10px' })} > Details</h5></React.Fragment> : '' : ''}
            <span
              className="orderStatus"
              style={{ background: "#" + status?.color1, float: "right", marginTop: '15px' }}
            >
              {status?.title}
            </span>
            <h3 style={{ margin: "12px 0 5px", fontSize: "16px" }}>
              Order #{orderData.alpha_order_no}
            </h3>
            <h5 style={{ margin: "0 0 15px", color: "#a2a2a2" }}>
              {orderData.created}
            </h5>
            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <h5 style={sectionTitle}>Store Info</h5>
              <div style={{ display: 'flex', paddingBottom: '10px' }}>
                <GInfo
                  title={store?.title}
                  subtitle2={store?.address}
                  imgType="store"
                  photo={store?.thumb_photo}
                />
                <div style={{
                  width: '60px',
                  textAlign: 'right'
                }}>
                  <a target="_blank" rel="noopener noreferrer" href={'tel:' + store?.phone}><img style={{ width: '30px', cursor: 'pointer' }} src={phoneIcon} alt="Call" /></a>
                  <img style={{ width: '30px', cursor: 'pointer' }} src={chatIcon} alt="Chat" onClick={() => setOmvisible(true)} />
                </div>
              </div>
            </div>
            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div style={{ paddingBottom: '10px' }}>
              <h5 style={sectionTitle}>Order Info</h5>
              <GInlineContent
                title="Order Type"
                value={orderData?.order_type?.replace("_", " ")}
                textTransform="capitalize"
              />

              <GInlineContent
                title="Delivery Type"
                value={orderData.delivery_type || "-"}
                textTransform="capitalize"
              />

              {orderData.delivery_time ? (
                <GInlineContent
                  title="Delivery Time"
                  value={orderData.delivery_time}
                />
              ) : (
                  ""
                )}
              {orderData.instructions ? (
                <GInlineContent
                  title="Instructions"
                  value={orderData.instructions}
                />
              ) : (
                  ""
                )}
            </div>

            {delivery?.delivery_address ? (
              <div style={{ paddingBottom: '10px' }}>
                <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />

                <h5 style={sectionTitle}>Delivery Address</h5>
                <div style={{ width: '88%', display: 'inline-block' }}>
                  <GContent title="Home" value={delivery?.delivery_address} />
                </div>
                <a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps?q=" + delivery?.delivery_lat + "," + delivery?.delivery_lng}><img style={{ float: 'right', marginTop: '10px', width: '30px', cursor: 'pointer' }} src={NavigateIcon} alt="Address" /></a>

              </div>
            ) : (
                ""
              )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div style={{ paddingBottom: '10px' }}>
              <h5 style={sectionTitle}>
                {order_products?.length}{" "}
                {order_products?.length > 1 ? "ITEMS" : "ITEM"}
              </h5>
              {order_products ? (
                <GItemTable
                  items={order_products}
                  currencySymbol={props.settings.currency_symbol}
                />
              ) : (
                  ""
                )}
            </div>

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <h5 style={sectionTitle}>Payment Summary</h5>
              {payment_summary?.data.map((p) => {
                return (
                  <GTableContent
                    key={p.title}
                    title={p.title}
                    value={props.settings.currency_symbol + "" + p.value}
                    align="right"
                    lAlign="right"
                    padding="2px 5px"
                  />
                );
              })}
              <hr style={{ border: "0", borderTop: "1px solid #e2e2e2" }} />

              <GTableContent
                title="Grand Total"
                value={
                  props.settings.currency_symbol +
                  "" +
                  payment_summary?.total_display
                }
                align="right"
                lAlign="right"
                fontSize="15px"
                color="#000"
              />
              {/*               
              <GContent title="Order Type" value={orderData.order_type} /> */}
            </div>

          </React.Fragment>}
      </div>

      {status?.title === 'Completed' ?
        <GButton
          variant="condensed"
          children="Repeat Order"
          type="button"
          style={{ width: '100%', borderRadius: '0', position: 'fixed', bottom: '0' }}
          onClick={() => {
            localStorage.setItem('area', store.geofences[0])
            setCartStore(store.id);
            setCart(order_products.map((o) => new RepeatOrder(o)));
            setTimeout(function () {
              setCartvisible(true);
            }, 1000)
          }}
        /> : ''}


      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrVisible(false)}
        style={{
          content: {
            left: 'auto',
            right: '0', top: '0', bottom: '0', width: '450px'
          }
        }}
        contentLabel="Order cancel Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrVisible={setOcrVisible}
          title={status?.title}
          linkedId={orderData.id}
          onSubmit={() => { getOrderDetail(); props.onCancelOrder() }}
        />
      </Modal>


      <Modal
        isOpen={omvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOmvisible(false)}
        style={{
          content: {
            left: 'auto',
            right: '0', top: '0', bottom: '0', width: '450px', padding: '20px 0 0'
          }
        }}
        contentLabel="Order message Modal"
      >
        <OrderMessages
          omvisible={omvisible}
          setOmvisible={setOmvisible}
          orderId={orderData.id}
          alphaOrderId={orderData.alpha_order_no}
          data={orderMessages}
          setRefesh={setRefesh}
          refresh={refresh}
          onSubmit={(data) => {
            setOrderMessages([...orderMessages, data]);
          }} />
      </Modal>

      <Modal
        isOpen={ofvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfvisible(false)}
        style={{
          content: {
            width: '375px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)', borderRadius: '5px!important'
          }
        }}
        contentLabel="Order Feedback Modal"
      >
        <OrderFeedback
          ofvisible={ofvisible}
          setOfvisible={setOfvisible}
          orderId={orderData.id}
          onSubmit={getOrderDetail} />
      </Modal>

      <Modal
        isOpen={oReportvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOReportvisible(false)}
        style={{
          content: {
            top: '0',
            left: 'auto',
            right: '0',
            bottom: '0',
            width: '450px'
          }
        }}
        contentLabel="Order Report Modal"
      >
        <OrderReport
          oReportvisible={oReportvisible}
          setOReportvisible={setOReportvisible}
          orderId={orderData.id}
          products={order_products}
          onSubmit={getOrderDetail} />
      </Modal>


      <Modal
        isOpen={cartvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCartvisible(false)}
        style={{
          content: {
            top: '0',
            left: 'auto',
            right: '0',
            bottom: '0',
            width: '450px'
          }
        }}
        contentLabel="Cart Modal"
      >
        <CartModal
          cartvisible={cartvisible}
          setCartvisible={setCartvisible}
          storeId={store?.id}
          onProceed={() => {
            console.log(history);
            history.push({ pathname: "/checkout" });
          }}
        />
      </Modal>


    </React.Fragment>
  );
}

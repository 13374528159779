import React from "react";
import styled from "styled-components";
import { Add, Remove, Delete } from "@material-ui/icons";
import { ImgUrl, addDefaultSrc } from "../services";
import { Table, TableBody, TableRowData } from "../styledComponents/tableStyle";

const Small = styled.small`
  font-size: 0.8rem;
  font-weight: 400;
  display: inline-block;
  padding-right: 5px;
  /* display: block; */
`;

const Tr = styled.tr`
  td {
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;

    div {
      border: 1px solid ${({ theme }) => theme.button};
      display: inline-flex;
      color: ${({ theme }) => theme.button};
      padding: 5px;
    }

    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.button};
      cursor: pointer;
      font-size: 15px;
    }
  }

  &:last-child {
    td {
      border-bottom: 0px;
    }
  }

  p.quantity {
    color: ${({ theme }) => theme.button};
  }

  h5 {
    color: ${({ theme }) => theme.button};
    margin: 5px 0;
    cursor: pointer;
  }
`;

function GItemTable(props) {
  return (
    <Table className="table">
      <TableBody>
        {props.items.map((i, ind) => {
          return (
            <Tr key={ind}>
              <td style={{ width: "60px" }}>
                <img
                  alt=""
                  src={ImgUrl("item") + "/" + i.thumb_photo}
                  onError={(e) => addDefaultSrc(e, "item")}
                  type="item"
                  className="thumbnail"
                  style={{
                    maxWidth: "45px",
                    maxHeight: "45px",
                    objectFit: "cover",
                  }}
                />
              </td>
              {props.editable === true ? (
                ""
              ) : (
                <td style={{ width: "27px" }}>{i.quantity} &nbsp;x</td>
              )}
              <TableRowData>
                <span
                  style={{
                    fontFamily:
                      props.editable === true ? "LatoWebSemibold" : "LatoWeb",
                  }}
                >
                  {i.title}
                </span>
                {props.editable === true ? (
                  <p className="quantity">
                    {props.currencySymbol}
                    {(parseFloat(i.price) * i.quantity).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </p>
                ) : (
                  <br />
                )}
                {/* <i>({i.category.title})</i> */}
                {i.variants.length > 0 &&
                  i.variants.map((v, $index) => {
                    return (
                      <Small key={v.id}>
                        <span style={{ color: "#333" }}>{v.title}</span>
                        {v.value ? (
                          <span style={{ color: "#777" }}> : {v.value}</span>
                        ) : (
                          ""
                        )}
                        {i.variants.length > $index + 1 ? <span>, </span> : ""}
                      </Small>
                    );
                  })}
                {props.editable === false ? ( //for turning onn special request change false to true, before it was true changed on 01-02-22 to remove special request
                  <h5 onClick={() => props.onSpecialRequest(i)}>
                    Special Request
                  </h5>
                ) : i.instructions ? (
                  <h5
                    style={{
                      fontSize: "13px",
                      color: "#a2a2a2",
                      cursor: "auto",
                      margin: "3px 0",
                    }}
                  >
                    Notes : {i.instructions}{" "}
                  </h5>
                ) : (
                  ""
                )}
              </TableRowData>
              {props.editable === true ? (
                <td>
                  <div>
                    <Remove onClick={() => props.onDecQuantity(i)} />
                    <span style={{ padding: "0 8px", fontSize: "14px" }}>
                      {i.quantity}
                    </span>
                    <Add onClick={() => props.onIncQuantity(i)} />
                  </div>
                </td>
              ) : (
                <TableRowData style={{ textAlign: "right" }}>
                  {props.currencySymbol}
                  {(parseFloat(i.price) * i.quantity).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </TableRowData>
              )}
              {props.editable === true ? (
                <td style={{ textAlign: "right" }}>
                  <Delete onClick={() => props.onRemove(i)} />
                </td>
              ) : (
                ""
              )}
            </Tr>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default GItemTable;

import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { Send, ArrowBackIos } from "@material-ui/icons";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { MessageBox } from "../styledComponents/messageStyles";
import RefreshIcon from '@material-ui/icons/Refresh';


const fixedDivStyle = {
  display: "flex",
  bottom: "10px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "9",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function OrderMesaages(props) {
  const [openSnackbar] = useSnackbar();
  const [messageVal, setMessageValue] = useState("");
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    setMessages(props.data);
  }, [props.data]);

  function updateField() {
    var post_note = {
      order_id: props.orderId,
      type: "text",
      text: messageVal,
    };
    ApiService({ method: "POST", route: "c/order/messages", body: post_note })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setMessageValue("");
          setMessages([...messages, response.data.data]);
          props.submit(response.data.data);
          openSnackbar(response.data.message);
        } else {
          console.log(response.data.data);
          openSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  if (props.omvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header" style={{display:"inline-flex", alignItems:"center", justifyContent:"space-evenly"}} >
        <div style={{display:"inline-flex", alignItems:"center"}}><ArrowBackIos className="backIcon" onClick={() => props.setOmvisible(false)} /> Order #{props.alphaOrderId}</div>

        &nbsp; &nbsp;
        <div style={{display:"inline-flex", cursor:"pointer", alignItems:"center",width:"35%"}} onClick={()=>props.setRefesh(!props.refresh)}>
         <p style={{fontSize:"12px"}}>Refresh to receive new message.</p>
        <RefreshIcon/>
        </div>
      </div>
      <div className="modal-body" style={{ height: 'calc(100vh - 120px)' }}>

        <div
          style={{
            padding: "5px 30px 15px",
            maxHeight: "75vh",
            overflow: "overlay",
            marginBottom: "40px",
          }}
        >
          {messages.map((p) => {
            return (
              <div
                key={p.id}
                style={{ textAlign: p.user_type === 4 ? "right" : "left" }}
              >
                <MessageBox
                  className={p.user_type === 4 ? "send" : "received"}
                >
                  <h5>{p.text}</h5>

                  <p>
                    {p.user_type === 4 ? "" : p.user_title + ", "}
                    {p.created}
                  </p>
                </MessageBox>
              </div>
            );
          })}
        </div>
      </div>
      <div style={fixedDivStyle}>
        <input
          type="text"
          value={messageVal}
          id="messages"
          placeholder="Add a message.."
          onChange={(e) => setMessageValue(e.target.value)}
          style={formInputStyle}
        />
        <GButton
          variant="linkable"
          onClick={() => {
            updateField("messages");
          }}
          style={{
            borderRadius: "50%",
            color: "#fff",
            background: "#f23938",
            marginTop: "7px",
            height: "30px",
            padding: "3px 5px 0 7px",
          }}
        >
          <Send style={{ fontSize: "1.2rem" }} />
        </GButton>
      </div>

    </React.Fragment>
  );
}

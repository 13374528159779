import React, { useEffect, useState } from "react";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { ApiService } from "../services";
import GTabs from "../gComponents/gTabs";
import styled from "styled-components";
import { ThumbDownAlt, ThumbUpAlt } from "@material-ui/icons";
import useDataFctory from "../useDataFactory";
import { useSnackbar } from "react-simple-snackbar";

// FOR Accordion ##
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const FaqDiv = styled.div`
  margin: 20px 0;
  h3 {
    margin: 10px 0;
    font-family: LatoWeb;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #555;
  }
  h5 {
    margin: 10px 0;
    color: #a2a2a2;
    font-size: 15px;
  }
`;

export default function Faqs() {
  const faqTypes = ["order", "offers", "account", "other"];
  const [typeValue, setTypeValue] = React.useState(faqTypes[0]);
  const [openSnackbar] = useSnackbar();
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    refreshData,
  } = useDataFctory("faqs", false);
  const classes = useStyles();


  const handleChangeTab = (newVal) => {
    console.log(newVal);
    setTypeValue(newVal);
  };
  const handleQuery = (value, id) => {
    let userData = JSON.parse(localStorage.getItem("settings"));
    if (!userData.user?.id) {
      openSnackbar('');
      return;
    }
    ApiService({ method: "POST", route: "c/faq/feedback", body: { faq_id: id, liked: value } })
      .then((response) => {
        console.log("gh" + response);
        if (response.data.status_code === 1) {
          openSnackbar(response.data.message);
          refreshData();
        } else {
          openSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="main-content">
      <br />
      <CardHead>Help & FAQs</CardHead>
      <Card>
        <GTabs
          routes={faqTypes}
          active={typeValue}
          type="single"
          onClick={handleChangeTab}
        ></GTabs>

        <div style={{ padding: "20px 0" }}>
          {loading === true ? <Shimmer /> : data
            .filter((r) => r.faq_type === typeValue).length === 0 ? <Placeholder /> :
            data
              .filter((r) => r.faq_type === typeValue)
              .map((f, i) => {
                {/* console.log("string replace # # ",f.answer.replace(/#/g, "<br/>")) */}
                return (
                  <FaqDiv key={i}>
                  <div className={classes.root}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>{f.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          {f.answer}
                            {/* {f.answer.replace(/#/g, "<br></br>")} */}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                   
                    {/* <h3>{f.question}</h3> */}
                    {/* <p>{f.answer}</p> */}
                    <div style={{ textAlign: "right" }}>
                      <h5>
                        Was this answer helpful ?
                      <ul
                          style={{
                            display: "inline-flex",
                            color: "#ccc",
                            listStyleType: "none",
                            margin: "0 0 10px",
                            padding: "0 5px",
                          }}
                        >
                          <li
                            style={{ padding: "0 5px", cursor: "pointer" }}
                            onClick={() => handleQuery("1", f.id)}
                          >
                            <ThumbUpAlt
                              fontSize="small"
                              style={{ verticalAlign: "bottom" }}
                            />{" "}
                          </li>
                          |
                        <li
                            style={{ padding: "0 5px", cursor: "pointer" }}
                            onClick={() => handleQuery("2", f.id)}
                          >
                            <ThumbDownAlt
                              fontSize="small"
                              style={{
                                verticalAlign: "bottom",
                              }}
                            />
                          </li>
                        </ul>
                      </h5>
                    </div>
                    </div>
                  </FaqDiv>
                );
              })}
        </div>
      </Card>
      <br />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ApiLoginService, ImgUrl } from "../services";
import { Card, CardHead } from "../styledComponents/cardStyles";
import Slider from "../gComponents/gSlideCarousel";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import { LocationSearch } from "../components/areaSearchComponent";
import { usePosition } from "../usePosition";
import { useSnackbar } from "react-simple-snackbar";
import useLocalStorage from "../useAsyncStorage";
import useSettings from "../useSettings";
import ContentSection from "./indexContent";
import GCardShimmer from "../gComponents/gShimmer";
import Store from "./store";
import FeaturesCard from "./customFeaturesCard";
import { Typography, Button } from "@material-ui/core";
import aboutus from "../assets/images/aboutus.jpeg";
import aboutusW from "../assets/images/aboutus.webp";

const useStyles = makeStyles((theme) => ({
  register: {
    padding: "20px 0 80px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  registerHeroText: {
    textAlign: "center",
    marginLeft: "2.5rem",
    height:"90px"
  },
  registerSections: {
    maxWidth: "1290px",
  },
  registerSection1: {
    display: "flex",

    padding: "5px",
    margin: "20px 0 0 0",
    gap:"20px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      textAlign: "center",
    },
  },
  registerSection1Left: {
    width: "50%",
    padding: "7px",
    // marginRight: "17px",
    height: "100%",
    transform: "scale(0.90)",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  registerSection1Right: {
    width: "50%",
    // backgroundColor: "#c3c3c3",
    // margin: " 0 10px",
    borderRadius: "14px",
    height: "500px",
    // background: ` linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.4)), url('${driverCardImage}')`,
    backgroundImage: `url('${aboutusW}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const AboutUs   = () => {
  const classes = useStyles();
  console.log("navigator", window.navigator.platform)

  return (
    <div id="about_us" className={classes.register}>
      <div className={classes.registerHeroText}>
      
        <Typography
          style={{
            color: "#343538",
            textAlign: "center",
            fontWeight: "lighter",
          }}
          variant="h4"
        >
          <b>About Us</b>
        </Typography>
      </div>
      {/* <div className={classes.registerSections}> */}
        <div className={classes.registerSection1}>
          <div className={classes.registerSection1Left}>
          <Typography
          style={{
            color: "#343538",
            fontWeight: "lighter",
          }}
          variant="h4"
        >
          <b style={{fontSize:"24px"}}>Quality. Service. Delivered.</b>
        </Typography>
        <br/>
            <Typography
              style={{
                color: "#343538",
                fontWeight: "lighter",
                fontSize:"18px",
              }}
             paragraph={true}
            >
              <span>RipeOnTime's</span> mission is very simple – make life easy for our customers by providing fast, fresh, and affordable ethnic groceries to your doorsteps!  We understand the importance of picking out the freshest vegetables and best quality producs - our promise to our customers is that we will get it right no matter what it takes because customer satisfaction is paramount for us! 
              </Typography>
             
              <Typography
              style={{
                color: "#343538",
                fontWeight: "lighter",
                fontSize:"18px",
              }}
             paragraph={true}
            >
              <span>RipeOnTime</span> aims to deliver quality groceries right to your footsteps at an affordable price. 
              </Typography>
              <Typography
              style={{
                color: "#343538",
                fontWeight: "lighter",
                fontSize:"18px",
              }}
             paragraph={true}
            >
              <span>RipeOnTime</span> allows you to shop from your favorite local stores. We can deliver your order the same day* or you can plan ahead and schedule a delivery up to 5 days in advance.
              </Typography>
              <Typography
              style={{
                color: "#343538",
                fontWeight: "lighter",
                fontSize:"18px",
              }}
             paragraph={true}
            >
              Our motto summarizes who we are –<span>Quality. Service. Delivered. </span>  
              </Typography>
              <Typography
              style={{
                color: "#343538",
                fontWeight: "lighter",
                fontSize:"18px",
              }}
             paragraph={true}
            >
              Still need more information – we are here to help.  
            </Typography>
        
            <br />
            <a href="/contact_us">
              <Button
                variant="contained"
                style={{ backgroundColor: "#f5a623", color: "#f4f4f4" }}
                disableElevation
              >
                Contact RipeOnTime
              </Button>
            </a>
            <br/>
            <br/>
            <small><i>*Orders must be placed before our cut-off time.  Cut-off time varies by our partner store hours.</i></small>
          </div>
          <div className={classes.registerSection1Right}></div>
        </div>

    
      </div>
    // </div>
  );
};

export default AboutUs;

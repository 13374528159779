import styled from "styled-components";

export const MessageBox = styled.div`
display:inline-block;

  & h5 {
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px;
    margin: 15px 0 5px;
    position: relative;
    font-weight: 400;

  }
  & p {
    color: #ccc;
    font-size: 12px;
    margin: 0 0 5px;
  }
  
&.received {
  max-width:80%;

  h5{
  background:#f1f2f4;
  
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    left: -10px;
    transform: rotate(-90deg);
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #f1f2f4 transparent transparent;
  }
}
}
&.send {
  h5{
  background:#e5f2f8;
  
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    right: -7px;
    transform: rotate(-90deg);
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #e5f2f8 transparent transparent;
  }
}
p{
  text-align: right;
}

}
 
`;

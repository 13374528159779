import React, { useEffect, useState } from "react";
import {ArrowBackIos} from "@material-ui/icons"
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";



export default function CancelReasons(props) {
  const [openSnackbar] = useSnackbar();
  const [orderCancelReasons, setOrderCancelReasons] = useState([]);

  useEffect(() => {
    function getCancelReasons() {
      ApiService({ method: "GET", route: "c/cancel-reasons?user_type=1" }).then((response) => {
        console.log("r" + response.data.status_code);

        response.data.data.push({ title: 'Other' });
        setOrderCancelReasons(response.data.data);

      });
    }
    getCancelReasons();
  }, [props]);
  const initialValues = {
    cancel_reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    console.log(values);
    if (values.cancel_reason !== "Other") {
      delete values.other_reason;
    }
    else {
      values.cancel_reason = values.other_reason;
    }

    ApiService({ method: "PUT", route: "c/order/cancel/" + props.linkedId, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          props.setOcrVisible(false);
          props.onSubmit();

          openSnackbar(response.data.message);
        } else {
          openSnackbar(response.data.message);
          return;
        }
      }
    );
  };

  const validationSchema = Yup.object({
    cancel_reason: Yup.string().required("Required"),
    other_reason: Yup.string().when("cancel_reason", {
      is: "Other",
      then: Yup.string().required("Required"),
    }),
  });


  if (props.ocrvisible === false) {
    return null;
  }

  return (
    <React.Fragment>
       <div className="modal-header">
                <ArrowBackIos className="backIcon" onClick={() => props.setOcrVisible(false)} />Cancel Order
            </div>
    <div className="modal-body">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <label style={{ margin: "15px 15px 5px" }}>
              Please choose the cancellation reason below
                </label>
            {orderCancelReasons.length > 0 ? <FormikControl
              control="radio"
              name="cancel_reason"
              options={orderCancelReasons}
              key_title="title"
              key_value="title"
              divstyle={{ display: "block", padding: "5px 0" }}
            /> : ''}
            {values.cancel_reason === "Other" ? (
              <FormikControl
                control="input"
                as="textarea"
                placeholder="Type reason here"
                name="other_reason"
              // style={{ display: "inline-block", width: "30%" }}
              />
            ) : (
                ""
              )}

            <br />
            {(props.title==="Accepted")?(<div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",alignItems:"center"}}>
            <p style={{fontSize:"14px"}}>Are you sure you want to cancel? There will be a $10 fee since your order has already been Accepted.</p> 
            <br/>
            <GButton variant="condensed" children="Yes, cancel my order" style={{ margin: '0 15px', width: '100%' }} type="submit" />
            <br />
            <GButton variant="condensed" children="No, don't cancel my order" style={{ margin: '0 15px', width: '100%' }} onClick={() => props.setOcrVisible(false)} /></div>):
            <GButton variant="condensed" children="Cancel Order" style={{ margin: '0 15px', width: '93%' }} type="submit" />}
            

          </Form>
        )}
      </Formik>
    </div></React.Fragment>
  );
}

import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
} from 'react-native';
import GText from '../gComponents/gText';
// import Colors from 'assets/colors/Main';

const Placeholder = ({ type, action }) => {
  const data = PlaceholderData[type] || PlaceholderData['other'];

  return (
    <View
      style={{
        padding: 30,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100%',
        marginTop: 10,
        paddingBottom: 100,
        flex: 1,
      }}>
      {data.icon && (
        <Image
          source={data.icon}
          style={{
            height: 150,
            width: 245,
            marginBottom: 40,
          }}
        />
      )}
      {data.title != '' && (
        <GText
          g1
          bold
          text={data.title}
          style={{ marginBottom: 5, textAlign: 'center', color: '#555' }}
        />
      )}
      <GText
        g3
        text={data.subtitle}
        style={{ textAlign: 'center', color: '#555' }}
      />

      {data.button && (
        <TouchableOpacity
          onPress={() => (action ? action() : null)}
          style={{
            padding: 40,
            paddingVertical: 7,
            backgroundColor: 'white',
            marginTop: 20,
            borderRadius: 30,
            borderWidth: 1,
            borderColor: '#ccc',
          }}>
          <GText g3 text={data.button.title} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Placeholder;

const PlaceholderData = {
  coupon: {
    icon: require('../assets/images/placeholders/e-coupon.png'),
    title: 'No Offers Available',
    subtitle: 'Please come back to later to check available offers.',
  },
  cart: {
    icon: require('../assets/images/placeholders/e-cart.png'),
    title: 'Nothing To Order',
    subtitle: 'Your cart is empty. Add some items from the menu page.',
  },
  address: {
    icon: require('../assets/images/placeholders/e-address.png'),
    title: 'NO ADDRESSES SAVED YET',
    subtitle:
      'You can add your Home, Office or Other address details here for faster checkout.',
  },
  faqs: {
    icon: require('../assets/images/placeholders/e-faq.png'),
    title: 'NO FAQs YET',
    subtitle: 'We will add FAQ here soon. \nBear with us :)',
  },
  order: {
    icon: require('../assets/images/placeholders/e-order.png'),
    title: 'NO ORDERS PLACED',
    subtitle:
      'Looks like you havn’t tried us yet! Trust us, its worth trying :) ',
  },
  reviews: {
    icon: require('../assets/images/placeholders/e-review.png'),
    title: 'NO REVIEWS YET',
    subtitle:
      'Looks like no one have reviewed this store yet. You can be the first one!',
  },
  store: {
    icon: require('../assets/images/placeholders/e-store.png'),
    title: 'NO STORES FOUND',
    subtitle:
      'Sorry, we are not serving at your location yet. Try Choosing another location.',
  },
  products: {
    icon: require('../assets/images/placeholders/e-product.png'),
    title: 'NOTHING TO BUY',
    subtitle:
      'Looks like there is nothing to order here yet. Come back later please.',
  },
  wishlist: {
    icon: require('../assets/images/placeholders/e-favourite.png'),
    title: 'NO Favorite YET',
    subtitle: 'You can add your Favorite products here to access quickly.',
  },
  wallet: {
    icon: require('../assets/images/placeholders/e-wallet.png'),
    title: 'NO TRANSACTIONS',
    subtitle:
      'Looks like there is nothing in your wallet yet. Come back later please.',
  },
  other: {
    icon: require('../assets/images/placeholders/e-other.png'),
    title: 'NO RECORDS',
    subtitle: 'No data available yet.',
  },
  substitute: {
    icon: null,
    title: '',
    subtitle: 'No Products Available.',
  },
  categories: {
    icon: require('../assets/images/placeholders/e-product.png'),
    title: 'NOTHING TO BUY',
    subtitle:
      'Looks like there is nothing to order here yet. Come back later please.',
  },
  login: {
    icon: require('../assets/images/placeholders/e-product.png'),
    title: 'ACCOUNT',
    subtitle: 'Please Login or Create Account first.',
    button: {
      title: 'Login',
    },
  },
  internet: {
    icon: require('../assets/images/placeholders/e-internet.png'),
    title: 'NETWORK FAILED',
    subtitle: 'Please check your internet connection.',
    button: {
      title: 'Retry',
    },
  },
};

import React, { useEffect, useState } from "react";
import {
  ApiLoginService,
  ApiService,
  ImgUrl,
  addDefaultSrc,
} from "../services";
import GShimmer from "../gComponents/gShimmer";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { FavoriteBorder, Favorite } from "@material-ui/icons";
// import BackgroundCarousel from "../gComponents/gSlider"

const ProductViewDiv = styled.div`
  p {
    margin: 5px 0;
    color: #777;
    .price {
      color: ${({ theme }) => theme.button};
    }
  }
`;

export default function OrderProductInfo(props) {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [favourite, setFavourite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();
  console.log(props.data + "--");

  useEffect(() => {
    ApiService({ method: "GET", route: "c/products/" + props.item.id }).then(
      (response) => {
        console.log("product response ### ", JSON.stringify(response));
        if (response.data.status_code === 1) {
          setSelectedProduct(response.data.data);
          setFavourite(response.data.data.favourite);
          setIsLoading(false);
        } else {
          //openSnackbar(response.message);
        }
      }
    );
  }, []);

  const addToFavourite = () => {
    let setting = JSON.parse(localStorage.getItem("settings"));
    console.log(props.settings);
    console.log(props.settings);
    if (!setting?.user?.id) {
      openSnackbar("unauthenticate");
      return;
    }
    ApiService({
      method: "POST",
      route: "c/wishlist",
      body: { product_id: selectedProduct.id },
    }).then((response) => {
      console.log(JSON.stringify(response));
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        openSnackbar(response.data.message);
      }
    });
  };
  const removeFavourite = () => {
    ApiService({
      method: "DELETE",
      route: "c/wishlist",
      body: { product_id: selectedProduct.id },
    }).then((response) => {
      console.log(JSON.stringify(response));
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
        // setSelectedProduct(response.data);
      } else {
        openSnackbar(response.data.message);
      }
    });
  };

  if (props.itemInfoVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div style={{ padding: "10px 20px" }}>
        {isLoading === true ? (
          <GShimmer />
        ) : selectedProduct ? (
          <ProductViewDiv>
            {selectedProduct?.images.length > 1 ? (
              // <BackgroundCarousel imgType="item" images={selectedProduct?.images} />
              ""
            ) : (
              <div>
                <img
                  src={ImgUrl("item") + "/" + selectedProduct.photo}
                  alt={selectedProduct.title}
                  onError={(e) => addDefaultSrc(e, "item")}
                  style={{
                    maxWidth: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
            <h4 style={{ margin: "10px 0 5px" }}>{selectedProduct.title}</h4>
            <p>
              <span className="price">
                {props.settings.currency_symbol}
                {selectedProduct.price}
              </span>{" "}
              {selectedProduct.unit ? "/ " + selectedProduct.unit : ""}
              <span style={{ float: "right", marginTop: "-10px" }}>
                {favourite === true ? (
                  <Favorite
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => {
                      removeFavourite();
                    }}
                  />
                ) : (
                  <FavoriteBorder
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addToFavourite();
                    }}
                  />
                )}
              </span>
            </p>
            {selectedProduct.description ? (
              <p>{selectedProduct.description}</p>
            ) : (
              ""
            )}
          </ProductViewDiv>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

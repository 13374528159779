import { useState, useEffect } from 'react';
export const usePosition = () => {
    const [position, setPosition] = useState({});
    const [error, setError] = useState(null);


    // function initializeCurrent(latcurr, longcurr) {
    //     currgeocoder = new google.maps.Geocoder();
    //     console.log(latcurr + "-- ######## --" + longcurr);
    //     if (latcurr !== '' && longcurr !== '') {
    //         var myLatlng = new google.maps.LatLng(latcurr, longcurr);
    //         return getCurrentAddress(myLatlng);
    //     }
    // }

    const onChange = ({ coords }) => {
        console.log(coords)
        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
        });

        // initializeCurrent(coords.latitude, coords.longitude);
    };
    const onError = (error) => {
        setError(error.message);
    };
    useEffect(() => {
        const geo = navigator.geolocation;
        if (!geo) {
            setError('Geolocation is not supported');
            return;
        }

        let watcher = geo.watchPosition(onChange, onError);
        return () => geo.clearWatch(watcher);
    }, []);

    const getCurrentLocation = () => {
        const geo = navigator.geolocation;
        if (!geo) {
            setError('Geolocation is not supported');
            return;
        }

        let watcher = geo.watchPosition(onChange, onError);
        return () => geo.clearWatch(watcher);
    }
    return { ...position, error, getCurrentLocation };
}
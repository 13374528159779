import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Route } from "react-router-dom";
import { ApiLoginService, ImgUrl } from "../services";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import { LocationSearch } from "../components/areaSearchComponent";
import { usePosition } from "../usePosition";
import { useSnackbar } from "react-simple-snackbar";
import useLocalStorage from "../useAsyncStorage";
import useSettings from "../useSettings";
import Store from "./store";
import { Typography } from "@material-ui/core";
import Register from "./registerDriverRestraunt";
import ContentSection from "./indexContent";

import "../css/testing.css";
import AboutUs from './AboutUs'

export const SearchDiv = styled.div`
  { /* // background-image: url("https://www.ordefy.com/api/goteso-assets/food/web/image_1574828545.jpg"); */}
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // text-align: center;
  padding: 40px 0;
  display: flex;
  width: 100%;
  align-items: center;
  .left-search-section {
    margin-left: 20px;
    h2 {
      margin: 10px 0;
      font-size:41px;
      font-weight: 500;
      letter-spacing: 1px
    }
    p {
      margin: 15px 0;
      font-size:17px;
      line-height:22px;
      max-width:60%;
      line-height:23px;
    }
  }
  .right-img-section {
    width: 50%;
    img {
      width: 100%;
    }

  }


  @media (max-width: 767px) {
    & {
      flex-direction:column;
      padding:30px 0 50px;
      .left-search-section{
      width: 100%; 
      text-align:center;
p{
  max-width:100%;
}
    }
   .right-img-section {
      width:100%;
      margin-top:20px
   }
  }

`;

const useStyles = makeStyles({
  heroContainer: {
    backgroundColor: "#f1f1f1",
    background: "url('../assets/images/bgImage.webp')",
    color: "#666",
    minHeight: "360px",
    width: "100%",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    padding: "3rem",
  },
  storesSection: {
    margin: "30px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  stores: {
    maxWidth: "90%",
    marginTop: "20px",
    display: "flex",
    flexWrap: "wrap",
    align: "center",
    justifyContent: "center",
  },
  store: {
    transform: "scale(1.07)",
    minWidth: "323px",
    margin: "15px 20px",
  },
  moreStoresBtn: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "600",
    marginTop: "60px",
    color: "#f5a623",
    background: "none",
    border: "none",
    cursor: "pointer",
    outline: "none",
    fontFamily: "inherit",
  },
});

export default function Index(props) {
  const classes = useStyles();
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [bannersData, setBannersData] = useState([]);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const [selectedGeofence, setSelectedGeofence] = useLocalStorage("area", "");
  const [selectedCoordinates, setSelectedCoordinates] = useState();
  const [selectedLocation, setSelectedLocation] = useLocalStorage(
    "location",
    {}
  );

  const [watch, setWatch] = useState(true);
  const {
    latitude,
    longitude,
    speed,
    timestamp,
    accuracy,
    error,
    getCurrentLocation,
  } = usePosition(watch);

  useEffect(() => {
    const getCouponsList = () => {
      ApiLoginService("GET", "c/offer-banners?type=mobile")
        .then((response) => {
          console.log("gh" + response);
          setIsBannerLoading(false);
          if (response.status_code === 1) {
            setBannersData(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getCouponsList();
    setSelectedLocation({ lat: latitude, lng: longitude, address: "" });
  }, []);

  const goToStores = () => {
    // && selectedCoordinates
    if (!selectedGeofence && !selectedLocation.lat) {
      openSnackbar("Select area to view stores");
      return;
    }
    props.history.push({
      pathname: "/stores",
      state: { geofence: selectedGeofence, coordinates: selectedLocation },
    });
  };

  return (
    <React.Fragment>
       <div className="main-content">
        <div className={classes.heroContainer} className="hero">
          <SearchDiv className="search">
            <div id="search_section" className="left-search-section">
              <Typography variant="h2" component="h2" style={{textAlign: 'left'}}>
                {/* Find Grocery Stores Near You */}
                Quality. Service. Delivered.
              </Typography>
              <Typography variant="p" component="p">
                {settings?.desc_1 || "Get Grocery delivered at your doorstep"}
              </Typography>
              <div
                style={{
                  display: "inline-flex",
                  width: "90%",
                  margin: "10px 0",
                }}
              >
              {/* Uncomment Below to show search boxxx at home page */}
                 <LocationSearch
                  className={classes.searchField}
                  value={
                    selectedGeofence
                      ? selectedGeofence
                      : selectedLocation.lat
                      ? selectedLocation.address
                      : ""
                  }
                  handleAreaChange={(value) => {
                    setSelectedGeofence(value);
                    setSelectedLocation({});
                    console.log(value);
                  }}
                  handleLocationChange={(value) => {
                    setSelectedLocation(value);
                    setSelectedGeofence();
                    console.log(value);
                  }}
                /> 

                {/* <code>
                        latitude: {latitude}<br />
                        longitude: {longitude}<br />
                        speed: {speed}<br />
                        timestamp: {timestamp}<br />
                        accuracy: {accuracy && `${accuracy}m`}<br />
                        error: {error}
                      </code> */}
                {/* <GButton variant="condensed" children="get location" onClick={getCurrentLocation} /> */}

                {/* GButton below one uncomment to show find store button */}
                 <GButton
                  variant="condensed"
                  children="Find Store"
                  onClick={goToStores}
                  style={{
                    borderRadius: "0px",
                    padding: "0.6em 1.5em",
                    width: "120px",
                  }}
                /> 
                {/* <span style={{backgroundColor:"#f3921d", padding:"5px"}}><h3>Coming Soon... January 2022</h3></span> */}
              </div>
              {/* <Dropdown /> */}
            </div>
            {/* <div className="right-img-section">
              <img
                src={
                  settings?.header_image
                    ? ImgUrl("website") + "/" + settings?.header_image
                    : "https://www.ordefy.com/api/goteso-assets/food/web/image_1574828545.jpg"
                }
              />
            </div> */}
          </SearchDiv>
        </div>
        <div
          style={{
            background: "#f2e8cf",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {/* {isBannerLoading == true ? (
            <GCardShimmer />
          ) : bannersData.length > 0 ? (
            <>
              <CardHead>Offers</CardHead>
              <Slider data={bannersData} />{" "}
            </>
          ) : (
            ""
          )} */}

          {'' /* <div className={classes.storesSection}>
            <Typography
              style={{
                color: "#343538",
                textAlign: "center",
                fontWeight: "lighter",
              }}
              variant="h4"
            >
              Browse stores in Sugar Land Texas
            </Typography>

            <div className={classes.stores}>
              <div className={classes.store}>
                <Store
                  avatar="S"
                  store_name="Sprouts Farmers Market"
                  delivery_by="11:07 AM"
                />
              </div>
              <div className={classes.store}>
                <Store
                  avatar="F"
                  store_name="FoodsCo Delivery Now"
                  delivery_by="2:00 AM"
                />
              </div>
              <div className={classes.store}>
                <Store
                  avatar="C"
                  store_name="The Container Store"
                  delivery_by="01:05 AM"
                />
              </div>
              <div className={classes.store}>
                <Store
                  avatar="G"
                  store_name="Grocery Outlet"
                  delivery_by="11:10 AM"
                />
              </div>
              <div className={classes.store}>
                <Store
                  avatar="D"
                  store_name="Dollar Tree"
                  delivery_by="07:11 AM"
                />
              </div>
              <div className={classes.store}>
                <Store
                  avatar="7"
                  store_name="The Seven"
                  delivery_by="06:17 AM"
                />
              </div>
            </div>
            <button className={classes.moreStoresBtn}>Show All</button>
          </div> */}
          <section id="how_it_works">
            <ContentSection />
          </section>
          <br />
          <br />
          <br />
          <AboutUs/>
          <Register />
        </div>
      </div>
    </React.Fragment>
  );
}

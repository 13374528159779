import React, { useEffect, useState, useContext } from "react";
import { ImgUrl, addDefaultSrc } from "../services";
import { Grid } from "@material-ui/core";
import useCart from "../useCart";
import { Add, Remove } from "@material-ui/icons";
import Modal from "react-modal";
import OrderProductVariants from "./orderProductVariants";
import { CartContext } from "../contexts/CartStore";
import OrderProductInfo from "./productInfo";
import GButton from "../gComponents/gButton";
import useSettings from "../useSettings";
import styled from "styled-components";
import { Item } from "../Models/Item";
import useDataFactory from "../useDataFactory";
import { GSearch } from "../gComponents/gSearch";
import BottomToTop from "./BottomToTop";

import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "./Spinner";

const ProductDiv = styled.div`
  min-height: 450px;
  p {
    .price {
      color: ${({ theme }) => theme.button};
    }
  }
`;

const QuantityDiv = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.45rem 1rem;
  border: 1px solid ${({ theme }) => theme.button};
  color: ${({ theme }) => theme.button};
  .MuiSvgIcon-root {
    font-size: 1.2rem;
    height: 0.9rem;
    width: 0.9rem;
  }
`;

const AddDiv = styled.div`
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.button};
    font-size: 0.56rem;
    margin-left: -11px;
    position: absolute;
    margin-top: 2px;
  }
`;
const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "450px",
    padding: "20px 0",
    // transform: 'translate(0%, -50%)'
  },
};

const 




Products = (props) => {
  const { itemCart } = useContext(CartContext);
  const [products, setProducts] = useState([]);
  const [searchProduct, setSearchWord] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCat, setSelectedCat] = useState(props.category);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  const [searchRes, setSearchRes] = useState(false);


  const { cart, addProduct, decreaseQuantity } = useCart();
  const settings = useSettings();
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFactory("products", true, {
    store_id: props.storeId,
    category: selectedCat || props.category?.id,
    sale:props.sale?1:""
  });

  console.log("search =====>",props.search);
  console.log("search cat =====>",props.category);

  useEffect(() => {
    setProducts(data.map((o) => new Item(o))); //Item Constructor to get limited data of products
  }, [data]);

  useEffect(() => {
    // setCart(cart);
    setSelectedCat(props.category);
    refreshData({
      store_id: props.storeId,
      category: props.category?.id,
      sale:props.sale?1:""
    });
    setSearchRes(false)
    //  setProducts(data.map((o) => new Item(o)));
  }, [props.category,props.sale, searchProduct,window.location.pathname]);
  useEffect(() => {
    
    refreshData({
      store_id: props.storeId,
      category: props.sale?"":props.category?.id,
      sale:props.sale?1:""
    });
    setSearchRes(false)
    //  setProducts(data.map((o) => new Item(o)));
  }, [props.sale]);
  useEffect(() => {
    
    refreshData({
      store_id: props.storeId,
      search: searchProduct,
    });
    setSearchRes(true)
    props.setSale(false)
    //  setProducts(data.map((o) => new Item(o)));
  }, [searchProduct]);
  
  // console.log("props products", props);
  // console.log("data products", data);
  // console.log("products** products", products);
  // console.log("pagination** products", pagination);
  return (
    <ProductDiv>
      <div style={{display:"flex", flexWrap:"nowrap", justifyContent:"space-between", alignItems:"center", flexDirection:"row-reverse"}}>
        <GSearch
          placeholder="Search Products.."
          value={searchProduct}
          handleChange={(val) => {
            setSearchWord(val);
          }}
          style={{
            margin: "0 15px 10px",
            marginLeft: "auto",
            display: "inherit",
          }}
        />
        {loading === true && selectedCat?.title ?  (
        <Shimmer />
      ) : products.length === 0 ? (
        ""
      ):(<h3 style={{ padding: "10px 20px" }}>
         {searchRes?"Search Results":selectedCat?.title} ({products.length} Items)
        </h3>)}
      </div>
      {loading === true ? (
        <Shimmer />
      ) : products.length === 0 ? (
        <Placeholder />
      ) : (
        <React.Fragment>
        <BottomToTop/>
          <InfiniteScroll
            dataLength={products.length}
            next={loadMore}
            hasMore={pagination.next_page_url}
            loader={<Spinner />}
          >
            <Grid
              className="productScreen"
              container
              spacing={0}
              style={{ maxHeight: "100%" }}
            >
            {console.log("products===>",products)}
              {products.map((product, index) => {
                console.log("producct ##", product);
                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={index}
                    style={{
                      borderRight: "1px dashed #f3921d",
                      borderBottom: "2.5px outset #f3921d",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* {product.status === '0' ? '' : product.unlimited === 0 & product.stock === 0 ? <span style={{
                          color: '#a2a2a2', background: '#fff',
                          border: '1px solid #e2e2e2',
                          position: 'absolute',
                          padding: '3px 9px',
                          right: '10px',
                          fontStyle: 'italic'
                        }}>Out of stock</span> : ''} */}
                        {
                          (product.sale===1)?(<span
                        style={{
                          position: "absolute",
                          padding: "2px 4px",
                          backgroundColor: "#f3921d",
                          top: "5px",
                          left: "10px",
                          borderRadius: "10px",
                          color:"#fff",
                          fontWeight:"400"
                        }}>
                        {product.sale_discount}% Off
                      </span>):""
                        }
                      <div style={{ height: "130px" }}>
                        <img
                          src={ImgUrl("item") + "/" + product.icon}
                          alt={product.title}
                          onError={(e) => addDefaultSrc(e, "item")}
                          style={{
                            height: "130px",
                            objectFit: "cover",
                          }}
                          onClick={() => {
                            setSelectedProduct(product);
                            setItemInfoVisible(true);
                          }}
                        />
                      </div>
                      <h4 style={{ margin: "10px 0 5px" }}>{product.title}</h4>
                      {
                        (product.sale===1)?(<small style={{ margin: "5px 0 10px", color: "#777" }}>
                        {" "}
                        {settings?.currency_symbol}
                        <strike>{product.price}</strike>
                      </small>):""
                      }
                      <p style={{ margin: "5px 0 10px", color: "#777" }}>
                        <span className="price">
                          {settings?.currency_symbol}
                          {
                            (product.sale===1)?(<b>{product.sale_price}</b>):(product.price)
                          } 
                        </span>{" "}
                        {product.unit ? "/ " + product.unit : ""}
                      </p>

                      {itemCart && itemCart.some((c) => c.id === product.id) ? (
                        itemCart
                          .filter((c) => c.id === product.id)
                          .slice(0, 1)
                          .map((c, i) => {
                            return (
                              <QuantityDiv key={i}>
                                <Remove onClick={() => decreaseQuantity(c)} />
                                <span
                                  style={{
                                    padding: "1px 10px 0",
                                    fontSize: "14px",
                                  }}
                                >
                                  {itemCart
                                    .filter((c) => c.id === product.id)
                                    .map((o) => o.quantity)
                                    .reduce((a, c) => a + c, 0)}
                                </span>
                                <Add
                                  onClick={() => {
                                    if (product.variants_available === false) {
                                      product.variants = [];
                                      addProduct(c, c.variants);
                                    } else {
                                      setSelectedProduct(product);
                                      setOrderVarVisible(true);
                                    }
                                  }}
                                />
                              </QuantityDiv>
                            );
                          })
                      ) : (
                        <AddDiv>
                          {product.status === "0" ? (
                            <GButton
                              variant="outlined"
                              children="NOT AVAILABLE"
                              disabled
                              style={{
                                borderRadius: "0",
                                color: "#a2a2a2",
                                border: "1px solid #d2d2d2",
                                fontSize: "14px",
                              }}
                            />
                          ) : (product.unlimited === 0) &
                            (product.stock === 0) ? (
                            <GButton
                              variant="outlined"
                              children="OUT OF STOCK"
                              disabled
                              style={{
                                borderRadius: "0",
                                color: "#a2a2a2",
                                border: "1px solid #d2d2d2",
                                fontSize: "14px",
                                padding: "0.6rem 2rem",
                              }}
                            />
                          ) : (
                            <React.Fragment>
                              <GButton
                                variant="outlined"
                                children="Add"
                                onClick={() => {
                                  if (
                                    product.unlimited === 0 &&
                                    product.stock === 0
                                  ) {
                                    return false;
                                  }
                                  if (product.variants_available === false) {
                                    product.variants = [];
                                    addProduct(product);
                                  } else {
                                    setSelectedProduct(product);
                                    setOrderVarVisible(true);
                                  }
                                }}
                                style={{
                                  padding: "0.45rem 2rem",
                                  borderRadius: "0",
                                  cursor:
                                    (product.unlimited === 0) &
                                    (product.stock === 0)
                                      ? "auto"
                                      : "pointer",
                                }}
                              />
                              {product.variants_available === true ? (
                                <Add
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      (product.unlimited === 0) &
                                      (product.stock === 0)
                                    ) {
                                      return false;
                                    }
                                    setSelectedProduct(product);
                                    setOrderVarVisible(true);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          )}
                        </AddDiv>
                      )}
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>

          {/* {pagination.next_page_url ? (
            <GButton
              variant="condensed"
              children="Load More"
              type="button"
              onClick={() => loadMore()}
              style={{ display: "flex", margin: "10px auto" }}
            />
          ) : (
            ""
          )} */}
        </React.Fragment>
      )}

      <Modal
        isOpen={orderVarVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={customStyles}
        contentLabel="Variant Modal"
      >
        <OrderProductVariants
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(itemVariants) => {
            console.log(itemVariants);
            addProduct(selectedProduct, itemVariants);
            // setCart([...cart, item]);
          }}
        />
      </Modal>

      <Modal
        isOpen={itemInfoVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemInfoVisible(false)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minHeight: "325px",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderProductInfo
          item={selectedProduct}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
          settings={settings}
        />
      </Modal>
    </ProductDiv>
  );
};

export default Products;

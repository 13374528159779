// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { ApiLoginService, ApiService } from "./services";
import useLocalStorage from "./useAsyncStorage";

// ##...Function Start...###
const useSettings = (refresh = false) => {
  const [settings, setSettings] = useLocalStorage("settings", data);
  // console.log(process.env.REACT_APP_API_URL + "/c/settings");
  const getSettings = () => {
    var token = localStorage.getItem("accessToken");
    var rtoken = localStorage.getItem("refreshToken");
    var expires = localStorage.getItem("expires_in");
    console.log(token);
    if (token && rtoken && expires && expires > 0) {
      ApiService({
        method: "GET",
        route: "c/me",
      }).then((response) => {
        //alert("3");
        console.log(response);
        if (response.data) {
          // console.log(response.data);
          // global.settings = response.data.data;
          setSettings(response.data.data || {});
        }
      });
    } else {
      ApiLoginService("GET", "c/settings").then((response) => {
        //alert("3");
        //console.log("response==" + JSON.stringify(response));
        if (response.data) {
          // console.log(response.data);
          // global.settings = response.data.data;
          setSettings(response.data || {});
        } else {
          setSettings(response || {});
          return response;
        }
      });
    }
  };
  useEffect(() => {
    let settingsData = localStorage.getItem("settings");
    settingsData =
      settingsData === "" ||
      settingsData === "undefined" ||
      settingsData === null ||
      settingsData === {}
        ? settingsData
        : JSON.parse(settingsData);
    console.log(settingsData);
    if (refresh) {
      getSettings();
    } else if (settingsData && settingsData.currency_symbol) {
      var token = localStorage.getItem("accessToken");
      if (token && !settingsData.user) {
        getSettings();
      } else {
        return;
      }
    } else {
    }
  }, []);
  return settings;
};

export default useSettings;

const data = {
  user: {
    wallet: 0,
  },
};

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Home } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { useHistory } from "react-router-dom";

const OrderPlacedDiv = styled.div`
  text-align: center;
  padding: 7vh 0;

  h4 {
    color: ${({ theme }) => theme.button};
    margin: 5px 0;
  }
  p {
    font-size: 15px;
    margin: 5px 0;
    color: #555;
  }
  hr {
    margin: 12px 0;
    border: 0;
    border-bottom: 1px solid #e2e2e2;
  }
`;

export default function OrderPlaced(props) {
  let history = useHistory();
  if (props.showOrderSuccess === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div style={{ padding: "10px 20px", textAlign: "center" }}>
        <h3>
          Order Placed
          <Link to={"/"} style={{ float: "right", marginTop: "-5px" }}>
            <Home />
          </Link>
        </h3>

        <OrderPlacedDiv>
          <h4>Order Placed Successfully!</h4>
          <p>Thanks for placing the order</p>
          <hr />
          <p>Your order no is</p>
          <h3>{props.alphaOrderId}</h3>
        </OrderPlacedDiv>

        <GButton
          variant="condensed"
          children="View Order Details"
          onClick={() =>
            history.push({
              pathname: "/profile/orders",
              state: { order_id: props.orderId },
            })
          }
        />
      </div>
    </React.Fragment>
  );
}

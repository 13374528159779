import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FeaturesCard from "./customFeaturesCard";
import { Typography } from "@material-ui/core";

import featureImageOne from "../assets/images/featureImageOne.webp";
import featureImageTwo from "../assets/images/featureImageTwo.webp";
import featureImageThree from "../assets/images/featureImageThree.webp";
import featureImageFour from "../assets/images/featureImageFour.webp";

const useStyles = makeStyles({
  features: {
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cards: {
    display: "flex",
    marginTop: "20px",
    flexWrap: "wrap",
    justifyContent: "center",
    gap:"12px",
  },
  card: {
    margin: "auto",
  },
  shoppingBtn: {
    border: "none",
    padding: "0",
    margin: "0",
    cursor: "pointer",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    userSelect: "none",
    touchaction: "manipulation",
    position: "relative",
    backgroundColor: "#f5a623",
    color: "#FFFFFF",
    borderRadius: "12px",
    height: "56px",
    width: "100",
    fontFamily: "inherit",
    marginTop: "48px",
    minWidth: "350px",
    display: "block",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "600",
    marginLeft: "8px",
    marginRight: "8px",
  },
});


const IndexContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.features}>
      <Typography
        style={{
          color: "#343538",
          textAlign: "center",
          fontWeight: "lighter",
        }}
        variant="h4"
      >
        The <span>RipeOnTime</span> Difference 
      </Typography>
      <hr style={{margin:"10px auto", width:"25%",minWidth:"150px", border:"1px solid #f3921d"}}/>
      <Typography
        style={{
          color: "#343538",
          textAlign: "center",
          fontWeight: "400",
          fontSize:"20px",
        }}
        paragraph={true}
        // variant="h4"
      >
        We understand the unique shopping habits that vary by ethnicities. Our agents, who represents multiple ethnicities, also understand this uniqueness and thus can shop for your groceries as if you were hand picking them yourself!
      </Typography>
      <div className={classes.cards}>
        <div className={classes.card}>
          <FeaturesCard
            img={featureImageOne}
            heading="Select your Store"
            body="Select your favorite local go to store on our platform and start adding your items to your cart using our app or website."
          />
        </div>
        <div className={classes.card}>
          <FeaturesCard
            img={featureImageTwo}
            heading="Same Day Delivery"
            body="We can deliver on the same day or you can schedule your delivery up to 5 days in advance!"
          />
        </div>
        <div className={classes.card}>
          <FeaturesCard
          img={featureImageThree}
            heading="Real Time tracking"
            body="You can check status of your order from the time you place the order until your order is completed."
          />
        </div>
        <div className={classes.card}>
          <FeaturesCard
          img={featureImageFour}
            heading="Earn Rewards"
            body="For every dollar spent, you will earn reward points which can be used for cash discount on future orders."
          />
        </div>
      </div>
      <button className={classes.shoppingBtn}
       onClick={() => {
                  
                  window.location.pathname = "/";
               
              }}
      > Start Shopping</button>
      {/* <a href="#search_section"><button className={classes.shoppingBtn}> Start Shopping</button></a> */}
    </div>
  );
};

export default IndexContent;

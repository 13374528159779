import React, { useState, useEffect, useRef } from "react";
import { ApiService, ImgUrl } from "../services";
import styled from "styled-components";
import { Search, Close, ArrowDropDown, Settings } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import useDebounce from "../useDebounce";

const LI = styled.li`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #f4f5f9;
    // color: #fff;
  }
`;

const Input = styled.input`
  width: calc(100% - 52px);
  /* min-width: 350px; */
  max-width: 450px;
  // margin: 5px 0 0;
  padding: 0 5px;
  height: auto;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
`;
export const ProductFilter = ({
  value,
  handleProductChange,
  handleBlur,
  handleFocus,
  storeId,
  type,
  placeholder,
  style,
  settings,
}) => {
  const [searchWord, setSearchWord] = useState("");
  const debouncedSearchTerm = useDebounce(searchWord, 500);
  const wrapperSRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [productsFilterList, setProducts] = useState([]);

  useEffect(() => {
    var hitUrl =
      "c/products?per_page=30&store_id=" + storeId + "&search=" + searchWord;
      // "c/products?per_page=30&store_id=" + storeId + "&sale=1";

    ApiService({ method: "GET", route: hitUrl })
      .then((response) => {
        console.log("gh" + response);
        if (response.data.status_code === 1) {
          console.log( response.data);
          setProducts(response.data.data.data);
          response.data.data.data.map((values) => {
            if (values.id === value) {
              setSelectedTitle(values.title);
            }
          });

          //setSelectedValue(value);
        } else {
          console.log( response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [debouncedSearchTerm, storeId]);

  return (
    <div
      ref={wrapperSRef}
      style={Object.assign(
        {},
        {
          width: "240px",
          display: "inline-block",
          marginLeft: "10px",
          verticalAlign: "middle",
        },
        style
      )}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid rgb(226, 226, 226)",
          height: "40px",
          fontSize: "0.97rem",
          color: "#555",
          boxSizing: "border-box",
          padding: "10px",
          fontFamily: "sans-serif",
          width: "100%",
        }}
        onClick={() => {
          setShowFilterList(true);
          setSearchWord("");
        }}
      >
        {selectedTitle ? (
          <span
            style={{
              width: "calc(100% - 40px)",
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: "20px",
              verticalAlign: "top",
            }}
          >
            {selectedTitle}
          </span>
        ) : (
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search Products.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          // <span style={{ color: "#555" }}>Search Products</span>
        )}
        <ArrowDropDown
          style={{
            float: "right",
            fontSize: "19px",
            color: "#555",
          }}
        />
        {selectedTitle ? (
          <Close
            fontSize="small"
            style={{
              float: "right",
              fontSize: "18px",
              color: "#777",
              cursor: "pointer",
            }}
            onClick={() => {
              handleProductChange();
              setSelectedTitle();
            }}
          />
        ) : (
          ""
        )}
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            width: "228px",
            padding: "0 5px 5px",
            border: "1px solid rgb(226, 226, 226)",
          }}
        >
          {/* <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment> */}
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              height: "220px",
              overflow: "auto",
            }}
          >
            {productsFilterList.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    setSelectedTitle(c.title);
                    setShowFilterList(false);
                    handleProductChange(c.id, c);
                  }}
                >
                  <img
                    src={ImgUrl("item") + "/" + c.thumb_photo}
                    style={{ width: "40px", marginRight: "10px" }}
                  />
                  <div>
                    <p> {c.title}</p>
                    <p style={{ margin: "5px 0", color: "#777" }}>
                      {settings?.currency_symbol + c.price}
                    </p>
                  </div>
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

// export default storeFilterComponent;

import React, { useState, useEffect } from "react";
import useCart from "../useCart";
import { ApiService } from "../services";
import { Card } from "../styledComponents/cardStyles";
import styled, { ThemeConsumer } from "styled-components";
import CustomerAddress from "../components/orderCustomerAddress";
import AddressView from "../gComponents/gAddressView";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import OrderTimeslots from "../components/orderTimeslots";
import Modal from "react-modal";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/untick.svg";

const CheckoutDiv = styled.div`
  label {
    display: block;
    margin: 10px 0 15px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.2px;
    /* font-style:italic; */
    color: #555;
  }
  .inputDiv {
    display: flex;
    padding: 5px;
    cursor: pointer;
    align-items: center;

    label {
      font-family: latoWebSemibold;
      // display: inline-block;letter-spacing:0;
      padding-left: 8px;
      font-style: normal;
      text-transform: capitalize;
      color: #333;
      margin: 0;
      font-size: 16px;
      vertical-align: top;
      p {
        color: #555;
        font-size: 14px;
        font-family: LatoWeb;
        padding: 5px 0;
      }
      span {
        font-family: latoWeb;
        padding: 5px 0;
      }
    }
    svg {
      width: 22px;
      height: 22px;
    }

    span.active svg {
      path {
        fill: ${({ theme }) => theme.button};
      }
    }
  }

  .checkbox {
    font-size: 1rem;
    padding: 12px 10px 12px 5px;
    display: inline-flex;
    text-transform: none;
    margin-bottom: 0px;
  }

  .checkbox__control {
    display: inline-grid;
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    border: 0.1em solid #ccc;
    margin-right: 10px;

    svg {
      transition: transform 0.1s ease-in 25ms;
      transform: scale(0);
      transform-origin: bottom left;
    }
  }

  .checkbox__input {
    color: ${({ theme }) => theme.button};
    input {
      opacity: 0;
      width: 1em;
      height: 1em;
      display: none;
      &:checked + .checkbox__control {
        border: 0.1em solid ${({ theme }) => theme.button};
        svg {
          transform: scale(1);
        }
      }

      &:disabled + .checkbox__control {
        color: var(--disabled);
      }
    }
  }
`;
// export default function Checkout(props) {

export const CheckoutForm = ({
  CheckoutData,
  setCheckoutData,
  placeOrderBtn,
  OnProceedPayment,
  onChange,
}) => {
  const [ocAddvisible, setOcAddVisible] = useState(false);
  const [otslotsvisible, setOTslotsVisible] = useState(false);
  const [deliveryAddressVisible, setDeliveryAddressVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [leaveGrocery, setLeaveGrocery] = useState(false);

  useEffect(() => {
    setDeliveryAddressVisible(
      CheckoutData?.order_type === "store_pickup" ? false : true
    );
    console.log("checkout datt ### ",CheckoutData);
  }, [CheckoutData]);

  return (
    <CheckoutDiv>
      {CheckoutData?.order_type_visible === true ? (
        <Card style={{padding: "5px 10px", margin:"0", borderBottom:"1px solid #f3921d"  }}>
          <label>Order Type</label>
          <div>
            <div
              className="inputDiv"
              onClick={() => {
                CheckoutData.order_type = "home_delivery";
                setDeliveryAddressVisible(true);
                onChange(CheckoutData);
              }}
            >
              <input
                name="option_order_type"
                type="radio"
                id="v_option_home_delivery"
                value="home_delivery"
                style={{ display: "none" }}
                defaultChecked={CheckoutData?.order_type === "home_delivery"}
              />
              {CheckoutData?.order_type === "home_delivery" ? (
                <span className="active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span>
                  <Uncheck style={{ fontSize: "1rem" }} />
                </span>
              )}
              &nbsp;&nbsp;
              <label htmlFor="v_option_home_delivery">
                Home Delivery
                <p style={{ textTransform: "none" }}>
                  Get delivered at your doorstep. Delivery charges may apply.
                </p>
              </label>
            </div>
            <br />
            <div
              className="inputDiv"
              onClick={() => {
                CheckoutData.order_type = "store_pickup";
                setDeliveryAddressVisible(false);
                CheckoutData.delivery_address = "";
                onChange(CheckoutData);
              }}
            >
              <input
                name="option_order_type"
                type="radio"
                id="v_option_store_pickup"
                style={{ display: "none" }}
                value="store_pickup"
                defaultChecked={CheckoutData?.order_type === "store_pickup"}
              />
              {CheckoutData?.order_type === "store_pickup" ? (
                <span className="active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span>
                  <Uncheck style={{ fontSize: "1rem" }} />
                </span>
              )}
              &nbsp;&nbsp;
              <label htmlFor="v_option_store_pickup">
                Store Pickup
                <p style={{ textTransform: "none" }}>
                  Collect the Order from the store.
                </p>
              </label>
            </div>
          </div>
        </Card>
      ) : (
        ""
      )}

      {deliveryAddressVisible === true ? (
        <Card style={{ padding: "5px 10px", margin:"0 0 5px 0", borderBottom:"1px solid #f3921d"  }}>
          <label>Delivery Address</label>
          {!CheckoutData?.delivery_address ? (
            <GButton
              style={{ margiBottom: "10px" }}
              onClick={() => {
                setOcAddVisible(true);
              }}
              variant="linkable"
              children="Choose Address"
            />
          ) : (
            <div
              style={{
                margin: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() => setOcAddVisible(true)}
            >
              <h4 style={{ textTransform: "uppercase", lineHeight: "18px" }}>
                {CheckoutData?.delivery_address.title}
              </h4>
              <h5
                style={{
                  color: "#777",
                  fontSize: "0.9rem",
                  display: "-webkit-box",
                  padding: "5px 0",
                  height:
                    CheckoutData?.delivery_address.default === true
                      ? "80px"
                      : "auto",
                }}
              >
                {CheckoutData?.delivery_address.formatted}{" "}
              </h5>
            </div>
          )}
        </Card>
      ) : (
        ""
      )}

      <Card style={{ padding: "5px 10px", margin:"0 0 5px 0", borderBottom:"1px solid #f3921d"  }}>
        <label>Select Delivery Time</label>
        <div>
          {CheckoutData?.asap_visible === true ? (
            <React.Fragment>
              <div
                className="inputDiv"
                onClick={() => {
                  CheckoutData.delivery_type = "asap";
                  CheckoutData.delivery_from = "";
                  CheckoutData.delivery_to = "";
                  CheckoutData.formatted_schedule_time = "";
                  setSelectedDay("");
                  setCheckoutData(CheckoutData);
                  onChange(CheckoutData);
                }}
              >
                <input
                  name="option_delivery_type"
                  type="radio"
                  id="v_option_asap"
                  value="asap"
                  style={{ display: "none" }}
                  checked={CheckoutData?.delivery_type === "asap"}
                />
                {CheckoutData?.delivery_type === "asap" ? (
                  <span className="active">
                    <Check style={{ fontSize: "1rem" }} />
                  </span>
                ) : (
                  <span>
                    <Uncheck style={{ fontSize: "1rem" }} />
                  </span>
                )}
                &nbsp;&nbsp;
                <label htmlFor="v_option_asap">ASAP</label>
              </div>
              <br />
            </React.Fragment>
          ) : (
            ""
          )}

          {CheckoutData?.delivery_type_visible === true ? (
            <div
              className="inputDiv"
              onClick={() => {
                setOTslotsVisible(true);
              }}
            >
              <input
                name="option_delivery_type"
                type="radio"
                id="v_option_scheduled"
                style={{ display: "none" }}
                value="scheduledd"
                // disabled={true}
                checked={CheckoutData?.delivery_type === "scheduled"}
              />
              {CheckoutData?.delivery_type === "scheduled" ? (
                <span className="active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span>
                  <Uncheck style={{ fontSize: "1rem" }} />
                </span>
              )}
              &nbsp;&nbsp;
              <label htmlFor="v_option_scheduled">
                Later
                <br />
                <span>
                  {selectedDay
                    ? CheckoutData?.formatted_schedule_time
                    : "Choose Timeslot"}
                </span>
              </label>
            </div>
          ) : (
            ""
          )}
        </div>
      </Card>
      <Card style={{ padding: "5px 10px", margin:"0 0 5px 0", borderBottom:"1px solid #f3921d" }}>
        <label>Delivery Instructions</label>
        <GInput
          type="text"
          placeholder="Type here..."
          name="service_url"
          id="service_url"
          value={CheckoutData?.instructions}
          display="block"
          onChange={(e) => {
            CheckoutData.instructions = e.target.value;
            setCheckoutData(CheckoutData);
          }}
          onBlur={(e) => {
            CheckoutData.instructions = e;
            setCheckoutData(CheckoutData);
          }}
        />

        {/* <br /> */}

        <label className="checkbox">
          <span className="checkbox__input">
            <input
              type="checkbox"
              name="checkbox"
              onClick={(e) => {
                setLeaveGrocery(!leaveGrocery);
                CheckoutData.leave_at_door = e.target.value;
                setCheckoutData(CheckoutData);
              }}
            />
            <span className="checkbox__control">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  d="M1.73 12.91l6.37 6.37L22.79 4.59"
                />
              </svg>
            </span>
          </span>
          Leave groceries at my door.
        </label>
      </Card>

      {placeOrderBtn === true ? (
        ""
      ) : (
        <GButton
          variant="condensed"
          children="Proceed to Payment"
          style={{ marginBottom: "20px" }}
          onClick={() => OnProceedPayment(CheckoutData)}
        />
      )}

      <Modal
        isOpen={ocAddvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcAddVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
            // transform: 'translate(0%, -50%)'
          },
        }}
        contentLabel="Address Modal"
      >
        <CustomerAddress
          ocAddvisible={ocAddvisible}
          setOcAddVisible={setOcAddVisible}
          defaultAddress={CheckoutData?.delivery_address}
          onSelectAddress={(address) => {
            CheckoutData.delivery_address = address;
            setCheckoutData(CheckoutData);
            onChange(CheckoutData);
          }}
        />
      </Modal>

      <Modal
        isOpen={otslotsvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOTslotsVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "550px",
            // transform: 'translate(0%, -50%)'
          },
        }}
        contentLabel="Address Modal"
      >
        <OrderTimeslots
          selectedDate={selectedDay}
          otslotsvisible={otslotsvisible}
          setOTslotsVisible={setOTslotsVisible}
          onChooseTimeslot={(slotDate, slotTime) => {
            console.log(slotDate);
            CheckoutData.delivery_type = "scheduled";
            setSelectedDay(slotDate.display);
            CheckoutData.delivery_from =
              slotDate.date + " " + slotTime.from_time;
            CheckoutData.delivery_to = slotDate.date + " " + slotTime.to_time;
            CheckoutData.formatted_schedule_time = `${slotDate.display}  ${slotTime.show_time}`

            // CheckoutData.formatted_schedule_time =
            //   slotDate.display +
            //   " " +
            //   slotTime.from_time +
            //   "-" +
            //   slotTime.to_time;
            setCheckoutData(CheckoutData);
            onChange(CheckoutData);
          }}
        />
      </Modal>
    </CheckoutDiv>
  );
};

import React, { useState, useEffect } from 'react';
import { Formik, Form } from "formik";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton"
import styled from "styled-components";
import { GSearch } from "../gComponents/gSearch";
import useDataFactory from "../useDataFactory";

const RequestedDiv = styled.div`

h4{

    font-size:14px;
padding: 10px 15px 2px
} 

p{
    font-size:13px; 
padding: 0 15px 5px;
    color:#777;
}
`;


var substituteOptions = [{
    title: 'Do not substitute the Item',
    value: 'false'
}, {
    title: 'Pick a substitute Item',
    value: 'true'
}]
export default function CartItemFeatureRequest(props) {
    const [searchProduct, setSearchWord] = useState('');

    const { loading, Shimmer, Placeholder, data, refreshData } = useDataFactory('products', false, { store_id: props.item.store_id, category: props.item?.categories, search: searchProduct });

    useEffect(() => {
        console.log(searchProduct)

        refreshData({ store_id: props.item.store_id, category: props.item?.categories, search: searchProduct })
    }, [searchProduct])


    const initialValues = {
        instructions: props.item.instructions || '',
        substitute: props.item.substitute || '',
        isSubstitute: props.item.substitute ? 'true' : 'false',
    };


    const onSubmit = (values) => {
        console.log(values);
        delete (values.isSubstitute);
        let newCartItem = { ...props.item }
        newCartItem.instructions = values.instructions;
        newCartItem.substitute = values.substitute

        props.onSubmit(newCartItem)

        props.setFeatureRequestVisible(false);
    };

    if (props.featureRequestVisible === false) {
        return null;
    }


    return (
        <React.Fragment>
            {/* <div className="modal-header">
                <ArrowBackIos className="backIcon" onClick={() => props.setCartvisible(false)} />Cart
            </div> */}
            <div className="modal-body" style={{ overflowX: 'hidden' }}>

                <h4 style={{ margin: '0 15px 5px' }}>Special Requests</h4>
                <RequestedDiv>


                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    // validationSchema={validationSchema}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>


                                <h4>Item Instructions</h4>

                                <FormikControl
                                    control="input"
                                    as="textarea"
                                    placeholder="Example: Pick slightly green bananas"
                                    name="instructions"
                                />


                                <h4>Substitute Options</h4>
                                <p>Select below option in case item is out of stock</p>
                                <FormikControl
                                    control="radio"
                                    name="isSubstitute"
                                    options={substituteOptions}
                                    key_title="title"
                                    selected={values.title}
                                    icons={false}
                                    key_value="value"
                                    // style={{ display: "none" }}
                                    divstyle={{
                                        display: "block", margin: '5px 0'
                                    }}
                                    labelStyle={{
                                        boxSizing: "border-box", fontSize: '14px',
                                        margin: "0px 3px", verticalAlign: 'bottom'
                                    }}
                                />


                                {values.isSubstitute === 'true' ?
                                    <div style={{}}>
                                        <GSearch
                                            placeholder="Search Products.."
                                            value={searchProduct}
                                            handleChange={(val) => {
                                                setSearchWord(val);
                                            }}
                                            style={{ margin: '0 12px' }}
                                        />
                                        {loading === true ? <Shimmer /> :
                                            data?.data.length === 0 ? <div>
                                                <p style={{ textAlign: 'center' }}>No Products Available..</p>
                                            </div> : <FormikControl
                                                    control="radio"
                                                    name="substitute"
                                                    options={data?.data}
                                                    key_title="title"
                                                    imgType='item'
                                                    // selected={values.title}
                                                    showImg={true}
                                                    icons={false}
                                                    key_value="id"
                                                    mainStyle={{ display: "flex", width: '100%', overflow: 'auto' }}
                                                    divstyle={{
                                                        display: "block", margin: '5px 0'
                                                    }}
                                                    labelStyle={{
                                                        boxSizing: "border-box", fontSize: '14px', display: 'block',
                                                        margin: "0px 3px", verticalAlign: 'bottom'
                                                    }}
                                                />}
                                    </div>
                                    : ''
                                }

                                <br />
                                <GButton variant="condensed" children='Add Special Request' type="submit" style={{ width: '94%', margin: '0 15px 15px' }} />
                            </Form>
                        )}
                    </Formik>
                </RequestedDiv>
            </div>
        </React.Fragment>
    )
}

import React, { useState, useEffect, useContext } from "react";
import { ApiLoginService, ApiService } from "../services";
import { Grid } from "@material-ui/core";
import useCart from "../useCart";
import { CheckoutForm } from "./checkoutForm";
import { GTableContent } from "../gComponents/gContent";
import GItemTable from "../gComponents/gCartItems";
import { CartContext } from "../contexts/CartStore";
import useSettings from "../useSettings";
import { Card } from "../styledComponents/cardStyles";
import GInfo from "../gComponents/gInfo";
import ApplyCoupon from "./checkoutOrderCoupon";
import AddTip from "./checkoutOrderTip";
import GButton from "../gComponents/gButton";
import PaymentMethod from "../paymentComponents/checkoutPaymentMethods";
import OrderWallet from "./checkoutWallet";
import { useSnackbar } from "react-simple-snackbar";
import OrderPlaced from "./orderPlaced";
import Modal from "react-modal";
import WebPaymentCheckout from "./webPaymentCheckout";
import CartItemFeatureRequest from "./cartItemFeatureRequest";

export default function Checkout(props) {
  const { itemCart } = useContext(CartContext);
  const {
    cart,
    setCart,
    cartStore,
    setCartStore,
    addProduct,
    removeProduct,
    decreaseQuantity,
  } = useCart();
  const settings = useSettings(true);
  const [newOrderData, setNewOrderData] = useState({
    products: [],
    summary: {
      data: [],
    },
    wallet: {},
  });
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [useWallet, setUseWallet] = useState(false);
  const [selectedTip, setSelectedTip] = useState("");
  const [CheckoutFormData, setCheckoutFormData] = useState({});
  const [cPaymentMethod, setCPaymentMethod] = useState({});
  const [placeOrderBtn, stPlaceOrderBtn] = useState(false);
  const [CheckoutData, setCheckoutData] = useState({});
  const [featureRequestVisible, setFeatureRequestVisible] =
    React.useState(false);
  const [selectedCartItem, setSelectedCartItem] = useState({});
  const [openSnackbar] = useSnackbar();
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [placedOrderId, setOrderId] = useState("");
  const [alphaOrderId, setAlphaOrderId] = useState("");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [gateways, setGateways] = useState({
    card: {},
    gateways: [],
    wallet: {},
  });
  const [couponVisible, setCouponVisible] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [webPaymentVisible, setWebPaymentVisible] = useState(false);
  const [paymentPage, setPaymentPage] = useState("");
  const [webPaymentUrl, setWebPaymentUrl] = useState({});
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    ApiService({
      method: "GET",
      route: `c/gateways`,
      body: { amount: newOrderData?.summary?.total },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setGateways(response.data.data);
        // openSnackbar(response.data.message);
      } else {
        return;
      }
    });
  }, [newOrderData]);

  useEffect(() => {
    if (showOrderSuccess !== true) {
      if (
        !(localStorage.getItem("area") && localStorage.getItem("cartStore"))
      ) {
        props.history.push({
          pathname: "/",
          state: {},
        });
      }
    }

    console.log(cartStore);
    console.log(cart);

    if (!cartStore) {
      return;
    }
    ApiService({
      method: "GET",
      route: `c/stores/${cartStore}`,
    }).then((response) => {
      console.log(response.data);

      console.log(settings);
      setCheckoutData({
        store: response.data.data,
        order_type: response.data.data.order_type || "home_delivery",
        order_type_visible: response.data.data.order_type === "",
        delivery_type:
          response.data.delivery_type !== "scheduled" ? "asap" : "",
        asap_visible: response.data.data.delivery_type !== "scheduled",
        delivery_type_visible: response.data.data.delivery_type !== "asap",
        delivery_address:
          response.data.data.order_type !== "store_pickup"
            ? settings?.default_address || ""
            : "",
        delivery_from: "",
        delivery_to: "",
        formatted_schedule_time: "",
        instructions: "",
        leave_at_door: false,
      });

      setTimeout(function () {
        console.log(CheckoutData);
        OrderCheckout();
      }, 1000);

      if (response.data.status_code === 0) {
        // openSnackbar(response.data.message);
      }
    });

    console.log(cartStore);
    console.log(cart);
  }, [cartStore]);

  const OrderCheckout = () => {
    if (!cartStore) {
      return;
    }
    if (!CheckoutData?.order_type) {
      return;
    }
    let newCheckoutData = { ...CheckoutData };

    if (newCheckoutData.order_type === "home_delivery") {
      if (!newCheckoutData.delivery_address?.id) {
        stPlaceOrderBtn(false);
      }
    }
    if (newCheckoutData.delivery_type === "scheduled") {
      if (!newCheckoutData.delivery_from) {
        stPlaceOrderBtn(false);
      }
    }

    console.log(newCheckoutData);
    ApiService({
      method: "POST",
      route: "c/order/checkout",
      body: {
        store_id: cartStore,
        customer_id: settings?.user?.id,
        products: cart || itemCart,
        order_type: newCheckoutData?.order_type,
        delivery_address: newCheckoutData?.delivery_address,
        delivery_type: newCheckoutData?.delivery_type,
        delivery_from: newCheckoutData?.delivery_from,
        delivery_to: newCheckoutData?.delivery_to,
        instructions: newCheckoutData?.instructions,
        coupon_code: selectedCoupon || "",
        points: useWallet,
        tip: selectedTip,
        gateway: cPaymentMethod,
        leave_at_door: newCheckoutData.leave_at_door,
      },
    })
      .then((response) => {
        console.log("response 2 =====>",response.data);
        if (response.data.status_code === 1) {
          setNewOrderData(response.data.data);
          // setDetail(response.data.data.summary?.data[1]?.detail)
          setCouponVisible(false);
        } else {
          setNewOrderData(response.data.data);
          setSelectedCoupon("")
          // setDetail(response.data.data.summary?.data[1]?.detail)
          if (couponVisible === true) {
            setIsCouponValid(false);
          }
          openSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (showOrderSuccess !== true) {
      if (!localStorage.getItem("area")) {
        props.history.push({
          pathname: "/",
          state: {},
        });
      } else if (
        JSON.parse(localStorage.getItem("cart")).length <= 0 &&
        localStorage.getItem("cartStore")
      ) {
        props.history.push({
          pathname: "/stores",
          state: { geofence: localStorage.getItem("area") },
        });
      } else {
      }
    }
    OrderCheckout();
  }, [itemCart, CheckoutData, selectedCoupon, useWallet, selectedTip]);

  const orderPlaced = () => {
    console.log(cPaymentMethod);

    if (cPaymentMethod?.gateway_type === "web") {
      getWebToken();
    } else {
      finalOrderPlaced();
    }
  };

  // const winOpen = (response)=>{
  //   var win = window.open(response.data.data.payment_page,"","width:600, height:600");
    
  //   win.open=(e)=>{
  //     console.log("Payment Method == T");
  //   }
  //   // cPaymentMethod.identifier === "paypal"?window.open():setWebPaymentVisible(true); window.location.href

  // }

  const getWebToken = () => {
    ApiService({
      method: "POST",
      route: `c/webpayments/token`,
      body: {
        amount: newOrderData.summary.total,
        gateway: cPaymentMethod.identifier,
        callback_url: window.location.href
      },
    }).then((response) => {
      // console.log(response);
      if (response.data.status_code === 1) {
        setPaymentPage(response.data.payment_page)
        setWebPaymentUrl(response.data.data);
        setCPaymentMethod({
          ...cPaymentMethod,
          token: response.data.data.token,
        });
        setWebPaymentVisible(true);
        // winOpen(response);
        console.log("Payment Method == R", response.data,cPaymentMethod.identifier)
      } else {
        openSnackbar(response.data.message);
        return;
      }
    });
  };

 

  const finalOrderPlaced = () => {
    setBtnLoading(true);
    const placedData = {
      store_id: cartStore,
      customer_id: settings?.user?.id,
      products: newOrderData.products,
      order_type: CheckoutFormData?.order_type,
      delivery_address: CheckoutFormData?.delivery_address,
      delivery_type: CheckoutFormData?.delivery_type,
      delivery_from: CheckoutFormData?.delivery_from,
      delivery_to: CheckoutFormData?.delivery_to,
      instructions: CheckoutFormData?.instructions,
      coupon_code: selectedCoupon || "",
      points: useWallet,
      tip: selectedTip,
      gateway: cPaymentMethod,
      leave_at_door: CheckoutFormData.leave_at_door,
    };
    console.log(placedData);
    ApiService({ method: "POST", route: "c/orders", body: placedData }).then(
      (response) => {
        console.log("r" + response.status_code);

        if (response.data.status_code === 1) {
          setBtnLoading(false);
          setOrderId(response.data.data.id);
          setAlphaOrderId(response.data.data.alpha_order_no);
          setShowOrderSuccess(true);
          setCart([]);
          setCartStore("");
          localStorage.setItem("area", "");
          // openSnackbar(response.data.message);
        } else {
          openSnackbar(response.data.message);
          setBtnLoading(false);
          return;
        }
      }
    );
  };
  console.log("Payment Method == ", cPaymentMethod)
  return (
    <div className="main-content">
      <Grid container spacing={2}>
        <Grid item xl={8} lg={7} md={7} sm={12} xs={12}>
          <div
            style={{
              maxHeight: window.innerWidth > 991 ? "85vh" : "auto",
              overflow: "overlay",
            }}
          >
            <CheckoutForm
              CheckoutData={CheckoutData}
              onChange={(data) => {
                console.log(data);
                setCheckoutData(data);
                OrderCheckout();
              }}
              setCheckoutData={setCheckoutData}
              placeOrderBtn={placeOrderBtn}
              stPlaceOrderBtn={stPlaceOrderBtn}
              OnProceedPayment={(formData) => {
                setCheckoutFormData(formData);
                stPlaceOrderBtn(true);
              }}
            />

            {placeOrderBtn === true ? (
              <Card>
                <h4>Payment Method</h4>
                <br />
                <PaymentMethod
                  paymentGateways={gateways}
                  cPaymentMethod={cPaymentMethod}
                  onSelecteGateway={(gateway) => {
                    setCPaymentMethod(gateway);
                    console.log("Gateway == ",gateway)
                  }}
                  setCPaymentMethod={setCPaymentMethod}
                />
              </Card>
            ) : (
              ""
            )}

            {placeOrderBtn === true && window.innerWidth > 959 ? (
              <GButton
                variant="condensed"
                children="Place Order"
                style={{ marginBottom: "20px", width: "100%" }}
                onClick={() => orderPlaced()}
                loading={isBtnLoading}
                disabled={
                  gateways && gateways.gateways.length > 0 ? false : true
                }
              />
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Grid item xl={4} lg={5} md={5} sm={12} xs={12}>
          <Card
            style={{
              maxHeight: window.innerWidth > 991 ? "85vh" : "auto",
              overflow: "overlay",
            }}
          >
           <div style={{display:"flex",flexWrap:"nowrap",justifyContent:"space-evenly", alignItems:"center"}}>
           <GInfo
              title={CheckoutData?.store?.title}
              subtitle2={CheckoutData?.store?.address}
              imgType="store"
              photo={CheckoutData?.store?.thumb_photo}
            />
              <h4>Items ({newOrderData?.products.length})</h4>
            </div>

           
            

            {/* <hr
              style={{
                border: "0",
                borderBottom: "1px solid #f2f2f2",
                margin: "20px 0",
              }}
            /> */}
            {itemCart && newOrderData?.products.length > 0 ? (
              <React.Fragment>
                <br />
                <GItemTable
                  items={newOrderData?.products}
                  currencySymbol={settings.currency_symbol}
                  editable={true}
                  onRemove={(item) => removeProduct(item)}
                  onIncQuantity={(item) => addProduct(item, item.variants)}
                  onDecQuantity={(item) => decreaseQuantity(item)}
                  onSpecialRequest={(item) => {
                    setSelectedCartItem(item);
                    setFeatureRequestVisible(true);
                  }}
                />
              </React.Fragment>
              
            ) : (
              ""
            )}
            

            {CheckoutData.order_type === "home_delivery" ? (
              <React.Fragment>
                <hr
                  style={{
                    border: "0",
                    borderBottom: "1px solid #202124",
                    margin: "5px 0 5px 0",
                  }}
                />

                <div>
                  <AddTip
                    selectedTip={selectedTip}
                    setSelectedTip={(tip) => setSelectedTip(tip)}
                    currencySymbol={settings.currency_symbol}
                  />
                </div>
              </React.Fragment>
            ) : (
              ""
            )}

            <hr
              style={{
                border: "0",
                borderBottom: "1px solid #202124",
                margin: "5px 0 5px 0",
              }}
            />

            <div>
              <ApplyCoupon
                selectedCoupon={selectedCoupon}
                setSelectedCoupon={setSelectedCoupon}
                setIsCouponValid={setIsCouponValid}
                isCouponValid={isCouponValid}
                storeId={cartStore}
                couponVisible={couponVisible}
                setCouponVisible={setCouponVisible}
              />
            </div>

            {newOrderData?.wallet?.points ? (
              <div>
                <hr
                  style={{
                    border: "0",
                    borderBottom: "1px solid #202124",
                    margin: "5px 0 5px 0",
                  }}
                />
                <h4>Use Wallet Cash </h4>
                <OrderWallet
                  useWallet={useWallet}
                  setUseWallet={setUseWallet}
                  wallet={newOrderData?.wallet?.points}
                  balance={
                    settings?.currency_symbol + newOrderData?.wallet?.amount
                  }
                  text={newOrderData?.wallet?.text}
                  status={newOrderData?.wallet?.status}
                />
              </div>
            ) : (
              ""
            )}

            {itemCart && newOrderData ? (
              <React.Fragment>
                <hr style={{border:"0", borderBottom: "1px solid #202124" }} />
                {/* <h4>Bill Details</h4> */}
                {newOrderData?.summary?.data.map((p) => {
                  return (
                    <GTableContent
                      key={p.title}
                      title={p.title}
                      detail={p.detail}
                      value={settings.currency_symbol + "" + p.value}
                      align="right"
                      lAlign="right"
                      color="#555"
                      padding="2px 5px"
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              ""
            )}
            <hr
              style={{
                border: "0",
                borderBottom: "1px solid #e2e2e2",
                margin: "5px 0",
              }}
            />

            <GTableContent
              title="To Pay"
              value={
                settings.currency_symbol +
                "" +
                newOrderData?.summary?.total_display
              }
              align="right"
              lAlign="right"
              bold={true}
            />
          </Card>
          {placeOrderBtn === true && window.innerWidth <= 959 ? (
            <GButton
              variant="condensed"
              children="Place Order"
              style={{ marginBottom: "20px", width: "100%" }}
              onClick={() => orderPlaced()}
              loading={isBtnLoading}
              disabled={gateways && gateways.gateways.length > 0 ? false : true}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Modal
        isOpen={showOrderSuccess}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowOrderSuccess(true)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderPlaced
          orderId={placedOrderId}
          alphaOrderId={alphaOrderId}
          showOrderSuccess={showOrderSuccess}
        />
      </Modal>

      <Modal
        isOpen={webPaymentVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWebPaymentVisible(false)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Payment Modal"
      >
        <WebPaymentCheckout
          selectedGateway={cPaymentMethod}
          orderId={placedOrderId}
          tokenData={webPaymentUrl}
          amount={newOrderData?.summary?.total}
          setWebPaymentVisible={setWebPaymentVisible}
          onSuccessPayment={() => {
            setWebPaymentVisible(false);
            finalOrderPlaced();
          }}
        />
      </Modal>

      <Modal
        isOpen={featureRequestVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFeatureRequestVisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Item Special Request Modal"
      >
        <CartItemFeatureRequest
          featureRequestVisible={featureRequestVisible}
          setFeatureRequestVisible={setFeatureRequestVisible}
          settings={settings}
          item={selectedCartItem}
          onSubmit={(item) => {
            let existing = cart.findIndex((c) => c.id == item.id);
            if (existing >= 0) {
              let newArray = [...cart];
              let newItem = item;
              newArray[existing] = newItem;
              setCart(newArray);
            }
          }}
        />
      </Modal>
    </div>
  );
}

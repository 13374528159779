import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
// import { Platform } from "react-native"
import { ApiLoginService } from "../services";
import GShimmer from "../gComponents/gShimmer";
import GEmpty from "../gComponents/gEmpty";
import { CardHead } from "../styledComponents/cardStyles";
// import GroceryStores from "./gComponents/gStoreType2";
import FoodStores from "../gComponents/gStoreType1";
import GDropdown from "../gComponents/gDropdown";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import styled from "styled-components";
import StoreFiltersView from "./storeFilters";
import FilterIcon from "../assets/images/filter.svg";
import StoreFilterPopup from "./storeFilterPopup";
import Modal from "react-modal";
import StoreReviewPopup from "./storeReviews";
import StoreCarousel from "./StoreCarousel";
import GettingEmail from "./GettingEmail"; 

const FiltersDiv = styled.div`
  width: 10%;
  min-width:150px;
  display: inline-block;
  vertical-align: top;
  margin-right:"25px";
  border-right: 2px solid #f3921d;
    padding: 10px 10px 10px 20px;
 
`;

const MainDiv = styled.div`
  &.resize {
    padding: 15px 20px 10px 10px;
    box-sizing: border-box;
    display: inline-block;
    width: calc(100% - 180px);
  }
`;

export default function Stores(props) {
  const [storesList, setStoresList] = useState([]);
  const [storeFilters, setStoreFilters] = useState({ value: [] });
  const [selectedFilterVal, setSelectedFilterVal] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [sfvisible, setSFVisible] = useState(false);
  const [srvisible, setSRVisible] = useState(false);
  const [storeSorting, setStoreSorting] = useState({ value: [] });
  const [selectedSortVal, setSelectedSortVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isStoreLoading, setIsStoreLoading] = useState(true);
  const [storeGeo, setStoreGeo] = useState(
    props.location?.state?.geofence || ""
  );
  const [storeCoordinates, setStoreCoordinates] = useState(
    props.location?.state?.coordinates || ""
  );
  const AssignDropdownRef = useRef(null);
  const [isAssignDropdownActive, setIsAssignDropdownActive] =
    useDetectOutsideClick(AssignDropdownRef, false);
  const [selectedStore, setSelectedStore] = useState({});

  console.log(window.innerWidth);

  console.log(props.location.state.geofence);
  useEffect(() => {
    const getStoresList = () => {
      setIsStoreLoading(true);
      console.log(storeSorting);
      console.log(storeFilters);
      var url = "c/stores?geofence=" + storeGeo;

      if (storeSorting.identifier) {
        url = url + "&" + storeSorting?.identifier + "=" + selectedSortVal;
      }
      if (storeFilters?.identifier) {
        url =
          url +
          "&" +
          storeFilters.identifier +
          "=" +
          selectedFilterVal.toString();
      }
      if (storeCoordinates?.lat) {
        url =
          url +
          "&location=" +
          storeCoordinates.lat +
          "," +
          storeCoordinates.lng;
      }
      console.log(url);
      ApiLoginService("GET", url)
        .then((response) => {
          console.log("ghhhh ======>" + response.data.data);
          if (response.status_code === 1) {
            setStoresList(response.data.data);
            setIsStoreLoading(false);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getStoresList();
  }, [storeGeo, selectedSortVal, selectedFilterVal]);

  useEffect(() => {
    const getStoresFilters = () => {
      ApiLoginService("GET", "c/store/filters")
        .then((response) => {
          console.log(response);
          if (response.status_code === 1) {
            setIsLoading(false);
            let sort = response.data.filter(function (s) {
              return s.identifier === "orderby";
            });
            setStoreSorting(sort[0]);
            let filter = response.data.filter(function (s) {
              return s.identifier !== "orderby";
            });
            console.log(filter);
            setStoreFilters(filter[0] || []);
            setShowFilters(filter && filter[0].value.length > 0 ? true : false);
            console.log("aa");
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getStoresFilters();
  }, []);

  const goToCategories = (selectedStore) => {
    props.history.push({
      // pathname: "/store/" + selectedStore.id + "/categories",
      pathname: "/store/" + selectedStore.id + "/products",
      state: { store: selectedStore },
    });
    console.log("selectedStore=======>",selectedStore);
  };

  return (
    <div className="main-content" style={{ height:"100%" }} >
    
      <div style={{ display:"flex", flexWrap:"nowrap", justifyContent:"center"}}>
        {isLoading === true ? (
          <GShimmer></GShimmer>
        ) : (
          <React.Fragment>
          
            {showFilters === true && window.innerWidth > 576 ? (
              <FiltersDiv>
                <CardHead>Filters</CardHead>
                <StoreFiltersView
                  data={storeFilters.value}
                  applyBtn={false}
                  submitBtn={false}
                  selectedFilterVal={selectedFilterVal}
                  onSubmit={(values) => setSelectedFilterVal(values)}
                  setSelectedFilterVal={setSelectedFilterVal}
                />
              </FiltersDiv>
            ) : (
              ""
            )}
            <MainDiv
              className={
                showFilters === true || window.innerWidth > 576 ? "resize" : ""
              }
              style={{width:"100%"}}
            >
            <StoreCarousel props={props} onSelectStore={goToCategories} data={storesList}/>

              <div style={{ float: "right" }}>
                {/* <p
                  onClick={() => setIsAssignDropdownActive(true)}
                  style={{
                    margin:
                      showFilters === true && window.innerWidth > 576
                        ? "10px 0"
                        : "0",
                    cursor: "pointer",
                    display: "inline-block",
                    color: ({ theme }) => theme.button,
                  }}
                >
                  Sort by{" "}
                  {selectedSortVal
                    ? storeSorting?.value
                        .filter((f) => f.value === selectedSortVal)
                        .map((s) => {
                          return <React.Fragment>: {s.title}</React.Fragment>;
                        })
                    : ""}
                </p> */}
                {isAssignDropdownActive === true ? (
                  <div ref={AssignDropdownRef} style={{ position: "absolute" }}>
                    <GDropdown
                      id="storeSorting"
                      className={`menu ${
                        isAssignDropdownActive ? "active" : "inactive"
                      }`}
                      listData={storeSorting?.value}
                      onSelectValue={(value) => {
                        setIsAssignDropdownActive(false);
                        setSelectedSortVal(value);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {showFilters === true && window.innerWidth <= 576 ? (
                  <img
                    src={FilterIcon}
                    alt="Filters"
                    style={{
                      cursor: "pointer",
                      width: "20px",
                      paddingLeft: "20px",
                    }}
                    onClick={() => setSFVisible(true)}
                  />
                ) : (
                  ""
                )}
              </div>
              <h4 style={{ fontSize: "18px", margin: "10px 15px" }}>
                {storesList.length > 1 ? "Total Stores" : "Total Store"} - {storesList.length}
              </h4>

              <hr
                style={{
                  borer: "0",
                  border: "0.8px solid #f3921d",
                  margin: "5px 0 30px",
                }}
              />

              {isStoreLoading === true ? (
                <GShimmer></GShimmer>
              ) : storesList.length === 0 ? (
              <GettingEmail  storeCoordinates={storeCoordinates} />
              ) : (
                <Grid container spacing={4}>
                  {storesList.map((store, i) => {
                    return (
                      <Grid
                        item
                        xl={3}
                        lg={showFilters === true ? 4 : 10}
                        md={showFilters === true ? 6 : 4}
                        sm={showFilters === true ? 12 : 12}
                        xs={12}
                        key={i}
                      >
                        <FoodStores
                          data={store}
                          onSelectStore={goToCategories}
                          onShowReview={(store) => {
                            setSelectedStore(store);
                            setSRVisible(true);
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </MainDiv>
          </React.Fragment>
        )}
      </div>
      <br />
      <br />

      <Modal
        isOpen={sfvisible}
        className="modal modalBottom"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSFVisible(false)}
        style={{
          content: {
            width: "100vw",
            top: "50%",
            left: "0",
            right: "0",
            bottom: "0",
            height: "auto",
          },
        }}
        contentLabel="Filter Modal"
      >
        <StoreFilterPopup
          sfvisible={sfvisible}
          setSFVisible={setSFVisible}
          data={storeFilters.value}
          selectedFilterVal={selectedFilterVal}
          setSelectedFilterVal={setSelectedFilterVal}
        />
      </Modal>

      <Modal
        isOpen={srvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSRVisible(false)}
        style={{
          content: {
            width: "400px",
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Filter Modal"
      >
        <StoreReviewPopup
          srvisible={srvisible}
          setSRVisible={setSRVisible}
          store={selectedStore}
        />
      </Modal>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import WalletImg from "../assets/images/piggy.png";
import GButton from "../gComponents/gButton";
const WalletDiv = styled.div`
  padding: 20px 0;
  img {
    width: 40px;
    padding-right: 20px;
  }
  p {
    padding: 5px 0;
    color: #555;
    font-size: 14px;
  }

  label {
    float: right;
  }

  .checkbox {
    font-size: 1rem;
    padding: 12px 5px;
    color: ${({ theme }) => theme.button};
  }

  .checkbox__control {
    display: inline-grid;
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    border: 0.1em solid currentColor;

    svg {
      transition: transform 0.1s ease-in 25ms;
      transform: scale(0);
      transform-origin: bottom left;
    }
  }

  .checkbox__input {
    /* display: grid;
  grid-template-areas: "checkbox";

  > * {
    grid-area: checkbox;
  } */

    input {
      opacity: 0;
      width: 1em;
      height: 1em;

      /* &:focus + .checkbox__control {
      box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
    } */

      &:checked + .checkbox__control svg {
        transform: scale(1);
      }

      &:disabled + .checkbox__control {
        color: var(--disabled);
      }
    }
  }
`;

export default function CheckoutWallet(props) {
  const [appliedWallet, setAppliedWallet] = useState(props.useWallet);
  useEffect(() => {
    setAppliedWallet(props.useWallet);
  }, [props]);
  return (
    <WalletDiv>
      <img src={WalletImg} alt="" />
      <React.Fragment>
        {/* <GButton
          variant="linkable"
          children="Apply"
          onClick={() => setAppliedWallet(!appliedWallet)}
        /> */}

        <label className="checkbox">
        {props?.status===1?<span className="checkbox__input">
            <input
              type="checkbox"
              name="checkbox"
              onClick={() => {
                setAppliedWallet(!appliedWallet);
                props.setUseWallet(!appliedWallet);
              }}
            />
            <span className="checkbox__control">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  d="M1.73 12.91l6.37 6.37L22.79 4.59"
                />
              </svg>
            </span>
          </span>:""}
        </label>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            width: "calc(100% - 105px)",
          }}
        >
          <h4>Wallet ({props?.wallet } Points)</h4>
          <p>{props.text ? props.text : ""}</p>
        </div>
      </React.Fragment>
    </WalletDiv>
  );
}

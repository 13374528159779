import React, { useState, useEffect, useContext } from 'react';
import Placeholder from './Placeholders/Placeholder';
import CouponShimmer from './gComponents/gShimmer';
import { ApiService } from "./services";
import GEmpty from './gComponents/gEmpty';
// import { StackContext } from '../../Context';

const useDataFactory = (type, paginate = false, bodyData = {}) => {
  // const { loginRedirect } = useContext();

  const EmptyPlaceholder = () => <Placeholder type={type} />;
  const LoginRequiredPlaceholder = () => (
    <GEmpty />
    // <Placeholder type={'login'} action={() => loginRedirect()} />
  );
  const internetPlaceholder = () => (
    <Placeholder type={'internet'} action={refreshData} />
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [body, setBody] = useState(bodyData);
  const [pagination, setPagination] = useState({
    total: 0,
    current_page: 0,
    last_page: 1,
  });

  const [loginRequired, setLoginRequired] = useState(false);
  const [internetFailed, setInternetFailed] = useState(false);

  const fetchData = () => {
    // console.log('here 1'+ JSON.stringify(body));

    if (paginate) {
      if (pagination.current_page == pagination.last_page) {
        // console.log('here 2');
        return;
      }
      // console.log(JSON.stringify(body));
      // console.log(type)
      // console.log(routes['order'])
      // console.log(routes[type])
      ApiService({
        method: 'GET',
        route: routes[type],
        body: { ...body, ...{ page: pagination.current_page + 1 } },
      }).then((response) => {
        console.log(response)
        if (response.status === 26) {
          setInternetFailed(true);
          setLoading(false);
          return;
        } else {
          setInternetFailed(false);
        }

        if (response.status === 401) {
          setLoginRequired(true);
          setLoading(false);
          return;
        } else {
          setLoginRequired(false);
        }

        setLoading(false);
        setData([...data, ...response.data.data.data]);
        console.log("search data====>",[...data, ...response.data.data.data])
        setPagination(response.data.data);
      });
    } else {
      ApiService({
        method: 'GET',
        route: routes[type],
        body: body,
      }).then((response) => {

        console.log(response)
        if (response.status === 26) {
          setInternetFailed(true);
          setLoading(false);
          return;
        } else {
          setInternetFailed(false);
        }

        if (response.status === 401) {
          setLoginRequired(true);
          setLoading(false);
          return;
        } else {
          setLoginRequired(false);
        }

        setData(response.data.data);
        setLoading(false);
      });
    }
  };

  const loadMore = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const refreshData = (filters = {}) => {
    setBody(filters);
    setPagination({
      total: 0,
      current_page: 0,
      last_page: 1,
    });
    setLoading(true);
    setData([]);
    setRefresh(!refresh);
  };

  return {
    loading: loading,
    Shimmer: CouponShimmer,
    Placeholder: internetFailed
      ? internetPlaceholder
      : loginRequired
        ? LoginRequiredPlaceholder
        : EmptyPlaceholder,
    data: data,
    loadMore: loadMore,
    pagination: pagination,
    refreshData: refreshData,
  };
};

export default useDataFactory;


const routes = {
  coupon: 'c/coupons',
  order: 'c/orders',
  wallet: 'c/wallet',
  social: 'c/social',
  faqs: 'c/faqs',
  address: 'c/addresses',
  store: 'c/stores',
  reviews: 'c/reviews',
  banners: 'c/offer-banners',
  categories: 'c/product/group',
  products: 'c/products',
  substitute: 'c/products',
  wishlist: 'c/wishlist',
  timeslots: 'c/timeslots',
  cancelReasons: 'c/cancel-reasons',
  storeFilters: 'c/store/filters',
};

import React, { useEffect, useState } from "react";
import useCart from "../useCart";

const CartContext = React.createContext(); // line A - creating the context

const CartItemStore = ({ children }) => {
  let cartItems = localStorage.getItem("cart");
  const {cart} = useCart();
  console.log(cartItems);
  const [itemCart, setItemCart] = useState([]); // line B - setting the initial cart

  const updateCart = (item) => setItemCart(item); // line C - changing the cart
  useEffect(() => {
    let cartItems = localStorage.getItem("cart");
    cartItems = cartItems ? JSON.parse(cartItems) : cartItems;
    console.log(cartItems);
    updateCart(cartItems);
  }, [cart]);
  return (
    <CartContext.Provider value={{ updateCart, itemCart }}>
      {children}
    </CartContext.Provider>
  );
};
export { CartItemStore, CartContext };

import React from "react";
import { Close } from "@material-ui/icons"
import { CardHead } from "../styledComponents/cardStyles";
import StoreFiltersView from "./storeFilters"
import GButton from "../gComponents/gButton";


export default function mobileStoreFilters(props) {

  console.log(props.data + "--");

  if (props.sfvisible === false) {
    return null;
  }
  return (
    <React.Fragment>

      <div className="modal-body" style={{ padding: '0 20px' }}>
        <GButton variant="linkable" style={{ float: 'right', marginTop: '10px' }} type="button" onClick={() => props.setSFVisible(false)}><Close /></GButton>

        <CardHead style={{ fontSize: "18px" }}>
          Filters
          </CardHead>

        <StoreFiltersView data={props.data} applyBtn={true} selectedFilterVal={props.selectedFilterVal} onSubmit={(values) => { props.setSelectedFilterVal(values); props.setSFVisible(false) }} />
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FiberManualRecord } from "@material-ui/icons";
import { ImgUrl } from "../services";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/untick.svg";
import GButton from "../gComponents/gButton";
import CardPayment from "./cardPayments";

const PaymentDiv = styled.div`
  display: flex;
  .sidebar {
    width: 160px;
    background: #f4f5f9;
    display: inline-block;
    vertical-align: top;
    padding: 10px 0 10px 10px;
    box-sizing: border-box;
    ul {
      list-style-type: none;
      li {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 12px;
        padding: 8px;
        margin: 5px 0;

        &.active {
          background: #fff;
        }
      }
    }
  }
  label {
    display: block;
    margin: 10px 0 15px;
    text-transform: uppercase;
    font-size: 14px;
    color: #555;
  }
  .inputDiv {
    display: flex;
    padding: 5px;
    cursor: pointer;
    align-items: center;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    padding: 8px 10px 6px;
    position: relative;

    &.active {
      border: 1px solid ${({ theme }) => theme.button};
    }
    span.active {
      position: absolute;
      right: -6px;
      top: -6px;
    }
    label {
      font-family: latoWebSemibold;
      // display: inline-block;
      /* padding-left:8px; */
      cursor: pointer;
      text-transform: capitalize;
      color: #333;
      margin: 0;
      font-size: 15px;
      vertical-align: top;
      p {
        color: #555;
        font-size: 14px;
        font-family: LatoWeb;
        text-transform: none;
        padding-top: 3px;
      }
      span {
        font-family: latoWeb;
        padding: 5px 0;
        position: relative;
      }
    }
    svg {
      font-size: 0.8rem;
      width: 16px;
      height: 16px;
    }

    img {
      margin-left: auto;
      width: 25px;
      /* height:20px */
    }
    span.active svg {
      path {
        fill: ${({ theme }) => theme.button};
      }
    }
  }
`;

const CardDiv = styled.div`
  h4 {
    font-family: "LatoWeb";
    font-size: 14px;
  }
  ul {
    list-style-type: none;
    li {
      border: 1px solid #e2e2e2;
      padding: 5px 10px;
      border-radius: 4px;
      margin: 10px 0;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      cursor:pointer span {
        svg {
          height: 20px;
          width: 20px;
        }
      }
      span.active svg {
        path {
          fill: ${({ theme }) => theme.button};
        }
      }
      label {
        margin: 0;
        svg {
          font-size: 8px;
          color: #ccc;
          padding: 0 1px;
        }
      }
    }
  }
`;

export default function PaymentMethod(props) {
  const [selectedPaymentType, setSelectedPaymentType] = useState("web");
  const [selectedGateway, setSelectedGateway] = useState(
    props.setCPaymentMethod || {}
  );

  const [cardPayment, setCardPayment] = useState(
    props.paymentGateways?.card || {}
  );
  const [paymentGateways, setPaymentGateways] = useState(
    props.paymentGateways?.gateways || []
  );
  return (
    <PaymentDiv>
      {cardPayment?.identifier ? (
        <div className="sidebar">
          <ul>
          {/* Remove below comment to acivate paypal */}
             <li
              className={selectedPaymentType === "web" ? "active" : ""}
              onClick={() => setSelectedPaymentType("web")}
            >
              Payment through
            </li> 
            {/* Till here for paypal */}
            <li
              className={selectedPaymentType === "card" ? "active" : ""}
              onClick={() => setSelectedPaymentType("card")}
            >
              CREDIT / DEBIT CARDS
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          width: cardPayment?.identifier ? "calc(100% - 160px)" : "100%",
          display: "inline-block",
          paddingLeft: "20px",
          boxSizing: "border-box",
        }}
      >
        {selectedPaymentType === "web" ? (
          paymentGateways.map((gateway, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className={
                    selectedGateway?.identifier === gateway.identifier
                      ? "inputDiv active"
                      : "inputDiv"
                  }
                  onClick={() => {
                    setSelectedGateway(gateway);
                    props.onSelecteGateway(gateway);
                  }}
                >
                  <input
                    name="option_payment_method"
                    type="radio"
                    id={"v_option_" + gateway.identifier}
                    value={gateway.identifier}
                    style={{ display: "none" }}
                    defaultChecked={
                      selectedGateway?.identifier === gateway.identifier
                    }
                  />
                  
                  {selectedGateway?.identifier === gateway.identifier ? (
                    <span className="active">
                      <Check style={{ fontSize: "1rem" }} />
                    </span>
                  ) : (
                    <span style={{ display: "none" }}>
                      <Uncheck style={{ fontSize: "1rem" }} />
                    </span>
                  )}
                  &nbsp;&nbsp;
                  {/*<============== Online Pay =======> */}
                   <label htmlFor={"v_option_" + gateway.identifier}>
                    {gateway.title}  
                    {gateway.identifier === "cash" ? (
                      <p>Please keep the change ready.</p>
                    ) : (
                      ""
                    )}
                  </label> 
                  {/* Online Pay Image */}
                   <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/assets/" +
                      gateway.identifier +
                      ".png"
                    }
                    alt={gateway.identifier}
                  /> 
                  {/* Below down was commented out from starting */}
                  {/* {gateway.identifier === 'cash' ? <img src={process.env.REACT_APP_API_URL + "/assets/"+gateway.identifier+".png"} alt="Cash" /> :
                  gateway.identifier === 'paygate' ? <img src={process.env.REACT_APP_API_URL + "/assets/paygate.png"} alt="Paygate" /> :
                    gateway.identifier === 'orange' ? <img src={process.env.REACT_APP_API_URL + "/assets/orange.png"} alt="Orange" /> :
                      gateway.identifier === 'orange' ? <img src={process.env.REACT_APP_API_URL + "/assets/orange.png"} alt="Orange" /> :
                        gateway.identifier === 'orange' ? <img src={process.env.REACT_APP_API_URL + "/assets/orange.png"} alt="Orange" /> :
                      ''} */}
                </div>
                {/* {paymentGateways.filter((g) => g.identifier === 'cash').length > i + 1 ? <hr style={{ border: '0', borderBottom: '1px solid #e2e2e2', margin: '5px 0 5px auto', width: 'calc(100% - 40px)' }} /> : ''} */}
              </React.Fragment>
            );
          })
        ) : (
          <CardPayment
            paymentGateways={props.paymentGateways}
            onSelecteCard={(card) => {
              cardPayment.card_id = card.id;
              props.onSelecteGateway(cardPayment);
            }}
          />
        )}
      </div>
    </PaymentDiv>
  );
}

import React from "react";
import GButton from "../gComponents/gButton";
import { ApiService } from "../services";
import GStarRating from "../gComponents/gStarRating";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "react-simple-snackbar";

const FeedbackDiv = styled.div`
  text-align: center;
  h4 {
    font-size: 16px;
    padding: 0 15px;
    color: ${({ theme }) => theme.button};
  }

  p {
    font-size: 13px;
    padding: 5px 15px;
    color: #555;
  }
  .star-rating {
    margin: 0 15px 10px;
    .star {
      width: 25px;
      height: 25px;
    }
  }
`;

export default function OrderFeedback(props) {
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    rating: "",
    review: "",
    order_id: props.orderId,
  };
  const onSubmit = (values) => {
    ApiService({
      method: "POST",
      route: "c/order/feedback",
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.onSubmit();
        openSnackbar(response.data.message);
        props.setOfvisible(false);
      } else {
        openSnackbar(response.data.message);
      }
    });
  };

  if (props.ofvisible === false) {
    return null;
  }

  const validationSchema = Yup.object({
    rating: Yup.string().required("Required"),
  });

  return (
    <div className="modal-body">
      <FeedbackDiv>
        <h4>Order Feedback</h4>
        <p>Rate on the scale of 1-5</p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GStarRating
                totalStars={5}
                selectedStars={0}
                selected={true}
                onSelect={(val) => {
                  values.rating = val;
                  setFieldValue("rating", val);
                }}
              />

              <FormikControl
                control="input"
                as="textarea"
                placeholder="Enter Feedback..."
                name="instructions"
              />

              <GButton
                variant="condensed"
                disabled={!values.rating ? true : false}
                children="Submit"
                type="submit"
                style={{ width: "94%", margin: "10px 15px 15px" }}
              />
            </Form>
          )}
        </Formik>
      </FeedbackDiv>
    </div>
  );
}

import React, { useEffect, useContext } from "react";
// import {useAsyncStorage} from '@react-native-community/async-storage';
import useLocalStorage from "./useAsyncStorage";
import { CartContext } from "./contexts/CartStore";
// import {EventRegister} from 'react-native-event-listeners';
// import { Alert } from 'react-native';

const CartContextTest = React.createContext();
// const { updateCart } = useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartStore, setCartStore] = useLocalStorage("cartStore", "");
  const [cart, setCart] = useLocalStorage("cart", []);
  console.log(cart);
  // useEffect(() => {
  //   // console.log(cart);
  //   //EventRegister.emit('cartUpdated', cart);
  //   //setCart(cart);
  //   updateCart(cart);
  //   console.log(cart);
  // }, [cart]);

  console.log("Use Cart ==== before",cart)

  const addProduct = (product, variants = []) => {
    console.log("Use Cart ==== before",cart)
    if (cart.length === 0) {
      product.quantity = 1;
      product.variants = variants;
      console.log("Variants=====>",product)
      setCartStore(product.store_id);
      setCart([product]);
      return;    
    }
    // else{
    //   console.log("Variants=====> Pro>",product)
    //   console.log("Variants=====> else",variants)
    // }
    // CHECK FOR ANY ITEM FROM DIFFERENT STORE
    if (cartStore && product.store_id != cartStore) {
      // Alert.alert(
      //   'Clear Cart',
      //   'Your cart contains products from another store. Would you like to clear cart and add this product?',
      //   [
      //     {
      //       text: 'Cancel',
      //       onPress: () => console.log('Cancel Pressed'),
      //       style: 'cancel',
      //     },
      //     {
      //       text: 'OK',
      //       onPress: () => {
      //         product.variants = variants;
      //         setCart([product]);
      //         setCartStore(product.store_id);
      //         return;
      //       },
      //     },
      //   ],
      //   { cancelable: false },
      // );
      if (
        window.confirm(
          "Your cart contains products from another store. Would you like to clear cart and add this product?"
        )
      ) {
        product.quantity = 1;
        product.variants = variants;
        setCart([product]);
        setCartStore(product.store_id);
        return;
      }
    }

    // VALIDATE VARIANTS
    if (variants.length > 0) {
      //return;
    }

    // CHECK FOR SIMILAR PRODUCT -> INCREASE QUANTITY
    let existing = cart.findIndex(
      (c) => c.id == product.id && isSameArray(c.variants, variants)
      // (c) => c.id == product.id && c.variants.id == product.variants.id
      // (c) => c.id == product.id &&  product.variants.id  // try 
    );
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity + 1;
      tempArray[existing] = temp;
      setCart(tempArray);
    } else {
      // ADD THE PRODUCT
      product.quantity = 1;
      product.variants = variants;
      setCart([...cart, product]);
    }
    setCartStore(product.store_id);
  };

  const decreaseQuantity = (product) => {
    //
    let existing = cart.findIndex(
      (c) => c.id === product.id && isSameArray(c.variants, product.variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity - 1;
      tempArray[existing] = temp;
      console.log(tempArray);
      tempArray = tempArray.filter((t) => t.quantity > 0);
      setCart(tempArray);
      //   writeItemToStorage(tempArray);

      if (tempArray.length == 0) {
        setCartStore(null);
      }
    } else {
      let tempArrayNew = [...cart];
      tempArrayNew = tempArrayNew.filter((t) => t.quantity > 0);
      setCart(tempArrayNew);
    }
    setCartStore(product.store_id);
    //return;
  };
  const removeProduct = (product) => {
    //
    let existing = cart.findIndex(
      (c) => c.id == product.id && isSameArray(c.variants, product.variants)
    );
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = 0;
      tempArray[existing] = temp;
      tempArray = tempArray.filter((t) => t.quantity > 0);
      console.log(tempArray);
      setCart(tempArray);
      //   writeItemToStorage(tempArray);
      setTimeout(() => {
        let cartItems = localStorage.getItem("cart");
        console.log("##AfterRemovingProduct", cartItems);
      }, 2000);

      if (tempArray.length == 0) {
        setCartStore(null);
      }
      setCartStore(product.store_id);
      return;
    }
  };

  return (
    <CartContextTest.Provider
      value={{
        cart,
        addProduct,
        removeProduct,
        cartStore,
        setCartStore,
        setCart,
        decreaseQuantity,
      }}
    >
      {children}
    </CartContextTest.Provider>
  );
};

export default () => useContext(CartContextTest);

const isSameArray = (first, second) => {
  if (first.length == second.length) {
    let firstIds = first.map((f) => f.id);
    let secondIds = second.map((s) => s.id);
    if (firstIds.every((f) => secondIds.includes(f))) {
      return true;
    }
  }
  return false;
};

import React, { useState, useEffect } from "react";
import { Card } from "../styledComponents/cardStyles";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import ProfileSidebar from "../gComponents/profileLinks";
import Orders from "./orders";
import Addresses from "./addresses";
import Wallet from "./wallet";
import Invite from "./invite";
import GInfo from "../gComponents/gInfo";
import Modal from "react-modal";
import EditProfile from "./editProfile"
import GButton from "../gComponents/gButton";

export const ProfileDiv = styled.div`
  display: inline-flex;
  width: 100%;
  @media (max-width: 767px) {
    display: block;
  }
  & .right-section {
    width: calc(100% - 200px);
    vertical-align: top;
    padding: 0 0 0 20px;

    @media (max-width: 767px) {
      width: 100%;
      padding: 0;
    }
  }
`;

export default function Profile({ match }) {
  const [epvisible, setEpvisible] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const settings = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : {};

  if (!settings?.user?.id) {
    window.location.href = '/'
  }

  return (
    <div className="main-content">
      <br />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <GInfo
              title={settings.user.title}
              key={settings.user.id}
              subtitle={settings.user.email}
              subtitle2={settings.user.phone}
              photo={settings.user.photo}
              imgType="user"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2}>

            <GButton variant="linkable" children="Edit Profile" onClick={() => setEpvisible(true)} style={{ float: 'right', textDecoration: 'underline' }} />
          </Grid>
        </Grid>
      </Card>
      <br />

      <ProfileDiv>
        <ProfileSidebar url={match.url} />
        <div className="right-section">
          <Switch>
            <Route exact path="/profile/orders" component={Orders} />
            <Route exact path="/profile/address" component={Addresses} />
            <Route exact path="/profile/wallet" component={Wallet} />
            <Route exact path="/profile/invite" component={Invite} />
          </Switch>
        </div>
      </ProfileDiv>

      <Modal
        isOpen={epvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: '380px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)', maxHeight: '545px', borderRadius: '5px!important'
          }

        }}
        contentLabel="Example Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>
    </div>
  );
}

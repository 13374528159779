import React from "react";
import { ArrowBackIos } from "@material-ui/icons";
import ProductList from "./product";
import useDataFactory from "../useDataFactory";
import useSettings from "../useSettings";
import { Item } from "../Models/Item";

function FavouriteProducts(props) {
  // const [openSnackbar] = useSnackbar();
  const { loading, Shimmer, Placeholder, data, loadMore, pagination, refreshData } = useDataFactory('wishlist', true);
  const settings = useSettings(true);
 
  if (props.wishlistVisible === false) {
    return null;
  }
  return (
    <React.Fragment >
      <div className="modal-header" >
        <ArrowBackIos className="backIcon" onClick={() => props.setWishlistVisible(false)} /> Favorite Products
        </div>
      <div className="modal-body" style={{ padding: "0 25px", overflow: 'overlay', height: 'calc(100vh - 70px)' }}>
      {loading === true ? <Shimmer></Shimmer> :
        <ProductList data={data.map((o) => new Item(o))} Placeholder={Placeholder} settings={settings} pagination={pagination} onLoadMore={()=>loadMore()}/>
      }
       </div>

    </React.Fragment >
  );
}

export default FavouriteProducts;

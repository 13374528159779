import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import cardImage from "../assets/images/bgImage.jpeg"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 325,
    borderRadius: 15,
    boxShadow: "none",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    background: "#f3f3f3",
    // background:` linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url('${cardImage}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  heading: {
    fontWeight: 400,
  },
}));

export default function FeaturesCard({ heading, body, img }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.heading} variant="h4">
          {heading}
        </Typography>
      </CardContent>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {body}
        </Typography>
      </CardContent>
      <CardMedia
        className={classes.media}
        image={img}
        title="Paella dish"
      />
    </Card>
  );
}

import React, { useState,useEffect } from "react";
import logo from "../assets/images/app-logo.png";
import { addDefaultLogo, ImgUrl } from "../services";
import "../css/header.css";
import Navbar from "./navbar";
import useSettings from "../useSettings";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import MobileNavbar from "./MobileNavbar"
import Modal from "react-modal";
import AppPrompt from "./AppPrompt"
//icons
import MenuIcon from "@material-ui/icons/Menu";

export default function Header(props) {
  const [mobNav, setMobNav]=useState(false)
  const settings = useSettings(true);
  // console.log("settingssss",settings);
  // console.log("mobillee naavvvv ",mobNav);
  return (
    <header className="header">
     
      <Link to="/">
        {settings?.web_header_logo ? (
          <img
            src={ImgUrl("website") + "/" + settings?.web_header_logo}
            className="header-logo"
            alt={settings?.project_name}
            onError={addDefaultLogo}
          />
        ) : settings?.admin_logo ? (
          <img
            src={ImgUrl("logo") + "/" + settings?.admin_logo}
            className="header-logo"
            alt={settings?.project_name}
            onError={addDefaultLogo}
          />
        ) : (
          ""
        )}
      </Link>
      {window.screen.width>768?(<Navbar settings={settings} showCart={props.showCart} />):(<MenuIcon onClick={()=> setMobNav(true)} />)}
      <MobileNavbar setMobNav={setMobNav} nav={mobNav} settings={settings} showCart={props.showCart}/>
      {/* {window.screen.availWidth >= 850 ? (
        <Navbar settings={settings} showCart={props.showCart} />
      ) : (
        <div>
          <MenuIcon />
        </div>
      )} */}
      {window.screen.width<768?<AppPrompt/>:undefined}
    </header>
  );
}

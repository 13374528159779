import React, { useEffect, useState } from "react";
// import "../css/Category.css";
import { ApiLoginService, ImgUrl, addDefaultSrc } from "../services";
import GShimmer from "../gComponents/gShimmer";
import GEmpty from "../gComponents/gEmpty";
import { Card } from "../styledComponents/cardStyles";
// import { StyleSheet, Text, View, FlatList, Platform } from "react-native";
import { useLocation, Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import GInfo from "../gComponents/gInfo";
import { ProductFilter } from "./productFilterComponent";
import useCart from "../useCart";
import OrderProductVariants from "./orderProductVariants";
import Modal from "react-modal";
import useSettings from "../useSettings";

const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "450px",
    padding: "20px 0",
    // transform: 'translate(0%, -50%)'
  },
};
const Category = (props) => {
  const settings = useSettings();
  const location = useLocation();
  const [storeData, setStoreData] = useState({});
  const [categoryData, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { cart, addProduct, decreaseQuantity } = useCart();
  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    const getCategories = () => {
      ApiLoginService("GET", "c/stores/" + props.match.params.id)
        .then((response) => {
          console.log("gh" + JSON.stringify(response.data));
          if (response.status_code === 1) {
            setStoreData(response.data);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getCategories();
  }, [location]);

  useEffect(() => {
    if (!storeData?.id) {
      return;
    }
    const getCategories = () => {
      ApiLoginService("GET", "c/product/group?store_id=" + storeData?.id)
        .then((response) => {
          console.log("gh" + JSON.stringify(response.data));
          if (response.status_code === 1) {
            setCategory(response.data);
            setIsLoading(false);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getCategories();
  }, [storeData]);

  return (
    <div className="main-content" style={{ padding: "20px 0" }}>
      <Card style={{ width: window.innerWidth > 767 ? "70%" : "100%" }}>
        <GInfo
          title={storeData?.title}
          subtitle={storeData?.phone}
          subtitle2={storeData?.address}
          imgType="store"
          photo={storeData?.thumb_photo}
          style={{
            width: window.innerWidth > 767 ? "calc(100% - 250px)" : "100%",
          }}
        />

        <ProductFilter
          settings={settings}
          value={""}
          style={{
            margin: "10px 0",
            width: window.innerWidth > 767 ? "240px" : "100%",
          }}
          storeId={storeData?.id}
          handleProductChange={(id, item) => {
            console.log(item);
            if (item) {
              if ((item?.unlimited === 0) & (item?.stock === 0)) {
                return false;
              }
              if (item?.variants_available === false) {
                item.variants = [];
                addProduct(item);
              } else {
                setSelectedProduct(item);
                setOrderVarVisible(true);
              }
            }
          }}
        />
      </Card>

      {isLoading === true ? (
        <GShimmer></GShimmer>
      ) : categoryData.length === 0 ? (
        <GEmpty type="category"></GEmpty>
      ) : (
        <div className="product">
          {categoryData.map((category, index) => {
            return (
              <div
                className="productSection"
                style={{ padding: "20px 0" }}
                key={index}
              >
                <h4
                  style={{
                    fontSize: "18px",
                    margin: "5px",
                    textTransform: "capitalize",
                  }}
                >
                  {category.title} &nbsp;
                  <Link
                    to={{
                      pathname: "/store/" + storeData.id + "/products",
                      state: {
                        data: categoryData,
                        store: storeData,
                        catId: category.id,
                        subCat: category.subcategories[0],
                      },
                    }}
                  >
                    <span style={{ color: "#4b90e0", fontSize: "14px" }}>
                      {" "}
                      View all
                    </span>
                  </Link>
                </h4>

                <Grid container spacing={0}>
                  {category.subcategories.map((sub, index) => {
                    return (
                      <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                        <Link
                          to={{
                            pathname: "/store/" + storeData.id + "/products",
                            state: {
                              data: categoryData,
                              store: storeData,
                              catId: parseInt(sub.parent_id),
                              subCat: sub,
                            },
                          }}
                        >
                          <div
                            style={{
                              background: "#fff",
                              padding: "20px",
                              border: "1px solid #f2f2f2",
                              textAlign: "center",
                            }}
                            // className="productDetails"
                            // onClick={() =>
                            //   history.push({
                            //     pathname: "/category",
                            //     state: { "store_id": productData.store_id },
                            //   })
                            // }
                          >
                            <img
                              src={ImgUrl("category") + "/" + sub.photo}
                              alt={sub.title}
                              onError={(e) => addDefaultSrc(e, "category")}
                              style={{
                                width: "90%",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />

                            <p style={{ margin: "10px 0" }}>{sub.title}</p>
                          </div>
                        </Link>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          })}
        </div>
      )}

      <Modal
        isOpen={orderVarVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={customStyles}
        contentLabel="Variant Modal"
      >
        <OrderProductVariants
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(itemVariants) => {
            console.log("variant===element Item variants CAT",itemVariants);
            addProduct(selectedProduct, itemVariants);
            // setCart([...cart, item]);
          }}
        />
      </Modal>
    </div>
  );
};

export default Category;

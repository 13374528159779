import React from "react";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";

const CardShimmer = styled.div`
  .MuiSkeleton-root {
    width: 100%;
    background: #f6f7f8;

    &.MuiSkeleton-rect {
      margin: 0 8px 25px;
    display: inline-block;box-sizing:border-box;
    width:calc(50% - 16px)
    }
    &.MuiSkeleton-text {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
`;

function GCardShimmer() {
  return (
    <CardShimmer>
      <div style={{ boxSizing: 'border-box' }}>
        {/* <Skeleton variant="text" height={90} animation="wave" /> */}
        <Skeleton variant="rect" height={80} animation="wave" />
        <Skeleton variant="rect" height={80} animation="wave" />
        <Skeleton variant="rect" height={80} animation="wave" />
        <Skeleton variant="rect" height={80} animation="wave" />
        {/* <br />
        <br />
        <Skeleton variant="text" height={40} animation="wave" /> */}
      </div>
    </CardShimmer>
  );
}

export default GCardShimmer;

import React, { useState, useEffect } from 'react'
import "../css/GettingEmail.css";
import Image from "../assets/images/placeholders/e-store.png";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";

// Material Ui Import
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const GettingEmail = (props) => {
  const [email, setEmail] = useState("");
  const [openSnackbar] = useSnackbar();

const classes = useStyles();

  const handleChange =(e)=>{
    setEmail(e.target.value)
    // setLocation(Object.values(window.localStorage.getItem("location")))
    // console.log("selectedGeofence ######", props.storeCoordinates?.lat,props.storeCoordinates.lng )
  }

  const handleSendEmail = () => {
    ApiService({
      method: "POST",
      route: "c/collect-data",
      body: {
        email: email,
        line1: props.storeCoordinates?.address,
        latitude: props.storeCoordinates?.lat,
        longitude: props.storeCoordinates.lng
      },
    }).then((response) => {
      console.log(JSON.stringify("data return", response?.data));
      if (response.status == 26) {
        // Toast.show(strings.alert_internet);
        // console.log(strings.alert_internet);
        return;
      }
      if (response?.data?.status_code == "1") {
        openSnackbar("Thank You We Will Contact You Soon!!")
        // console.log("Thank You");
      } else {
        // Toast.show(response?.data?.message)
        console.log(response?.data?.message);
        openSnackbar(response?.data?.message)
      }
    });
    setEmail("")
  };
  return (
    <div className="gettingEmail">
      <div className="gettingEmail-img">
        <img src={Image} alt="" srcset="" />
      </div>
      <div className="gettingEmail-txt">
        <h2>We are Continuously Expanding our Service</h2>
        <p>Stay tunned, we will notify as soon as we are in your area</p>
      </div>
      <div className="gettingEmail-form">
        <div className={classes.margin}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <PersonIcon color="primary" />
            </Grid>
            <Grid item>
              <TextField
                id="input-with-icon-grid"
                type="email"
                size="medium"
                label="Email@xyz.com"
                value={email}
                onChange={handleChange}  />
            </Grid>
          </Grid>
        </div>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          endIcon={<EmailIcon />}
          onClick={handleSendEmail}>
          Send
        </Button>
      </div>
    </div>
  );
};

export default GettingEmail;

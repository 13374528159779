import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";

export const OfferDiv = styled.div`
  /* background: #fcfcfc; */
  padding: 15px;
  margin: 0;
  border: 1px solid #f4f5f9;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  & .iconStyle {
    background: ${({ theme }) => theme.button};
    color: #fff;
    height: 24px;
    border-radius: 50%;
    width: 30px;
    text-align: center;
    padding-top: 5px;
    margin: 8px 15px 0 0;
  }
  h3 {
    background: #f8f6dd;
    border: 1px dashed #f1e6cd;
    margin: 0 0 12px;
    padding: 9px 9px 7px;
    color: #63635b;
    font-size: 17px;
    line-height: 28px;
    width: 160px;
    text-align: center;
    cursor: pointer;
  }
  h4 {
    margin: 7px 0;
    font-size: 16.5px;
  }
  p {
    margin: 7px 0;
    line-height: 20px;
    color: #333;
    font-size: 15px;
  }
  h5 {
    color: #b2b2b2;
    font-size: 14px;
    margin: 7px 0;
    font-weight: 400;
  }

  &:hover .MuiSvgIcon-root {
    display: inline-block;
  }
`;

export default function Offers(props) {
  const [couponData, setCouponData] = useState({});
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    setCouponData(props.data);
  }, []);

  const handleCopy = (c) => {
    const el = document.createElement("textarea");
    el.value = c.code;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // alert("Copied : " + el.value);

    if (props.onCopy) {
      props.onCopy(c);
    }
    openSnackbar("Copied : " + c.code);
  };
  return (
    <OfferDiv>
      <div className="iconStyle">%</div>
      <div style={{ width: "calc(100% - 40px)" }}>
        <h3 onClick={() => handleCopy(couponData)}>{couponData.code}</h3>
        <h4>{couponData.title}</h4>
        <p>{couponData.description}</p>
        <h5>
          {couponData.expiry ? "Expires : " + couponData.expiry_formatted : ""}
        </h5>
      </div>
    </OfferDiv>
  );
}

import React, { useState, useEffect } from 'react';
import { Formik, Form } from "formik";
// import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from '../gComponents/gButton';

export default function StoreFilters(props) {
    const [filterOptions, setFilterOptions] = useState(props.data || []);
    useEffect(() => {
        setFilterOptions(props.data)
    }, [props])

    const initialValues = {
        filterValues: props.selectedFilterVal || [],
    };
    const onSubmit = (values) => {
        console.log(values);
        props.onSubmit(values.filterValues)
    }
    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ values }) => {
                    console.log(values);
                    if (values.filterValues && props.submitBtn === false) {
                        props.setSelectedFilterVal(values.filterValues);
                        props.onSubmit(values.filterValues)
                    }

                    return (
                        <Form>

                            <FormikControl
                                control="checkbox"
                                name="filterValues"
                                options={filterOptions}
                                key_title="title"
                                key_value="value"
                                onClick={(e) => console.log(values.filterValues)}
                            />


                            {props.applyBtn === true ? <GButton variant="condensed" children="Apply" type="submit" /> : ''}
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

import React, { useState } from "react";
import { ApiService } from "../services";
import { ArrowBackIos } from "@material-ui/icons"
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";



export default function ChangePassword(props) {
  const [openSnackbar] = useSnackbar();

  const [isBtnLoading, setBtnLoading] = useState(false)
  const initialValues = {
    old: "",
    new: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true)
    console.log(values);

    ApiService({ method: "POST", route: "c/change-password", body: values }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.setCpvisible(false);
        setBtnLoading(false)
        openSnackbar(response.data.message);
      } else {
        setBtnLoading(false)
        openSnackbar(response.data.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    old: Yup.string().required("Required"),
    new: Yup.string().required("Required").min(5).max(15),
  });


  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos className="backIcon" onClick={() => props.setCpvisible(false)} />Change Password
            </div>
      <div className="modal-body">

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (

            < Form >
              <FormikControl
                control="input"
                type="text"
                placeholder="Old Password"
                name="old"
              />

              <FormikControl
                control="input"
                type="text"
                placeholder="New Password"
                name="new"
              />
              <br />
              <GButton variant="condensed" children="Update" style={{ margin: '0 15px', width: '92%' }} type="submit" loading={isBtnLoading} />

            </Form>
          )}
        </Formik>

      </div>
    </React.Fragment >
  );
}

import React, { useState, useEffect } from "react";
import GMap from "../components/orderLocation";
import phoneIcon from "../assets/images/phone.png";

// API key of the google map
// const GOOGLE_MAP_API_KEY = '<YOUR_GOOGLE_MAP_API_KEY>';

// load google map script
const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

const App = (props) => {
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  return (
    <div>
      <br />
      {!loadMap ? <div>Loading...</div> : <GMap data={props.deliveryData} />}
      <div style={{ padding: "18px 0 8px" }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"tel:" + props.deliveryData?.agent?.phone}
        >
          <img
            style={{
              width: "25px",
              cursor: "pointer",
              float: "right",
              marginTop: "20px",
            }}
            src={phoneIcon}
            alt="Call"
          />
        </a>

        <h4>{props.deliveryData.status_display?.title}</h4>
        <p style={{ fontSize: "15px", padding: "5px 0", color: "#333" }}>
          Delivery Agent : {props.deliveryData.agent?.title}
        </p>
      </div>
    </div>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button, TextField } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import ReCAPTCHA from "react-google-recaptcha"; //Recaptcha
import { useSnackbar } from "react-simple-snackbar";
import useSettings from "../useSettings";

import "../css/genaricForm.css";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    // marginTop: "10vh",
    // marginBottom: "10vh",
    backgroundColor:"#404040",
    padding:"30px 0",
    gap:"30px"
  },
  // textfield:{
  //   backgroundColor:"#fff",

  // },
 
  // art: {
  //   backgroundColor: "#000",
  //   width: "40vw",
  //   height: "78.4vh",
  // },
  formArea: {
    // marginTop: "20px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // border: "1px solid #888",
    // borderRadius: "0 18px 18px  0",
    backgroundColor: "transparent",
    // boxShadow: "rgb(0 0 0 / 16%) 0px 2px 8px",
    padding: "10px 20px",
    paddingTop: "20px",
    width:"40%",
    minWidth: "379px",

    // "& h4": {
    //   padding: "20px",
    //   paddingLeft: "10px",
    // },

    "& div": {
      marginBottom: "7px",
      //   marginLeft: "1vw",
      width: "100%",
    },
    //   justifyContent: "center"
  },
  //   formControl: {
  //     // margin: theme.spacing(1),
  //     minWidth: 120,
  //   },
}));

const widthOfInputFields = 0;

const GenaricForm = () => {
  const classes = useStyles();
  const settings = useSettings(true);

  const [dataForm, setDataForm] = useState();
  const [dataReasons, setDataReasons] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState("");
  const [reasonData, setReasonData] = useState([]);
  const [openSnackbar] = useSnackbar();

const recaptchaChange =(response)=>{
  if(response){
    setIsVerify(true);
    setCaptchaChecked("")
  }
}

 async function getData(url = process.env.REACT_APP_API_URL + "/c/reason") {
    const response = await fetch(url);
    var identifier = await response.json();
    console.log("response data ## ", identifier.data);
    setReasonData(identifier.data);
  }

  useEffect(() => {
    getData()
  }, [])

  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    var res = await response.json();
    console.log("response contacct ## ", res);
    return res
  
  }

  // postData('https://example.com/answer', { answer: 42 })
  //   .then(data => {
  //     console.log(data); // JSON data parsed by `data.json()` call
  //   });


  const onSubmit = (e) => {
    e.preventDefault();
    if(isVerify){
      setCaptchaChecked("")
      console.log("dataform ## ", dataForm);
      postData("https://api.ripeontime.com/c/send-mail", dataForm)
      .then((response) =>openSnackbar(response.message))
      .catch((err) => console.log(err));
      e.target.reset();
      
    }else{
      setCaptchaChecked("*Please Fill Captcha!!")
    }
    // postData("https://api.grofynow.com/c/send-mail", dataForm)
    //   .then((response) => console.log(response))
    //   .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setDataForm({
      ...dataForm,
      identifier: dataReasons,
      reason: reasonData.filter((r)=>r.identifier===dataReasons).map((r)=>r.reason).toString(),
      [e.target.id]: e.target.value,
    });
  };
  const handleChoose=(e)=>{
    setDataReasons(e.target.value)
    console.log("response valuee ====> ", e.target.value)
    console.log("response title ====> ", e.eventPhase)
  }

  const formatPhoneNumber=(phoneNumberString)=> {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  return (
    <div className={classes.root} >
    
      <div className="art">
         <Typography variant="h1">Contact Us</Typography>
         <Typography paragraph="true">Feel Free To Contact Anytime!!</Typography>
         <div className="artEmail"><EmailIcon/> <a href="mailto:info@ripeontime.com">hello@ripeontime.com</a> </div> 
         <div className="artCall"><CallIcon/> <a href= {"tel:" + settings.admin_phone}>{formatPhoneNumber(settings.admin_phone)}</a> </div>
      </div>
      <div className={classes.formArea}>
        {/* <Typography variant="h4">Contact Us</Typography> */}
        <form
          onSubmit={(e) => onSubmit(e)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="cForm"
        >
          <div>
            <TextField
              required
              id="first_name"
              onChange={(e) => handleChange(e)}
              label="First Name"
              variant="outlined"
              inputProps={{
                className:"cFormText"
                }}
            />
          </div>
          <div>
            <TextField
              required
              id="last_name"
              onChange={(e) => handleChange(e)}
              label="Last Name"
              variant="outlined"
              inputProps={{
                className:"cFormText"
                }}
            />
          </div>
          <div>
            <TextField
              required
              id="email"
              onChange={(e) => handleChange(e)}
              label="Email Address"
              variant="outlined"
              inputProps={{
                className:"cFormText"
                }}
            />
          </div>
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
              Reason for Contacting Us
              </InputLabel>
              <Select
                required
                id="reason"
                onChange={(e) => handleChoose(e)}
                native
                label="Reason for Contacting Us"
                // inputProps={{
                //   name: "age",
                //   id: "outlined-age-native-simple",
                // }}
                inputProps={{
                className:"cFormText"
                }}
              >
                <option style={{color:"#9a9a9a"}}>Choose Options..</option>
                {reasonData.map((r,i)=> <option key={i} style={{color:"#9a9a9a"}} value={r.identifier}> {r.reason} </option>)}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              required
              id="other"
              onChange={(e) => handleChange(e)}
              multiline
              rows={5}
              label="Enter Comments Here"
              variant="outlined"
              inputProps={{
                className:"cFormText"
                }}
            />
          </div>
          <ReCAPTCHA
            sitekey="6LfOk4YdAAAAAHFhVqe6U90eQnLJUisuVIS0Xzch"
            render="explicit"
            onChange={recaptchaChange}
          />
           <div style={{color:"red"}}><b>{captchaChecked}</b></div>
          
          <Button
            style={{
              backgroundColor: "#f5a623",
              color: "#f4f4f4",
              padding: "9px 14px 7px 14px",
              alignSelf:"flex-start",
            }}
            type="submit"
            variant="contained"
            disableElevation
          >
            Send
          </Button>
        </form>
      </div>
    </div>
  );
};

export default GenaricForm;

import React, { useState, useEffect } from "react";
import { Cancel } from "@material-ui/icons";
import styled from "styled-components";
import TipImg from "../assets/images/tip-icon.png";
import GButton from "../gComponents/gButton";

const tipValues = [2, 5, 7, 10];
const TipDiv = styled.div`
  padding: 20px 0;
  img {
    width: 40px;
    padding-right: 20px;
  }
  p {
    padding: 5px 0;
    color: #555;
    font-size: 14px;
  }
  ul {
    list-style-type: none;
    display: inline-block;
    width: 100%;
    li {
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      width: 43px;
      text-align: center;
      display: inline-block;
      padding: 6px 7px 4px;
      font-size: 14px;
      margin: 5px 10px 0 0;
      cursor: pointer;

      &.active {
        border: 1px solid ${({ theme }) => theme.button};
        color: ${({ theme }) => theme.button};
      }
      & .MuiSvgIcon-root {
        padding: 0px 0 0 4px;
        vertical-align: top;
      }
    }
  }
  button {
    float: right;
  }
`;



export default function CheckoutOrderTip(props) {
  const [appliedTip, setAppliedTip] = useState("");
  const [tipValue, setTipValue] = useState("")

  const handleTip =(e)=>{
    console.log("tip console ## ", e.target.value);
    setTipValue(e.target.value)
    
    }
    const handleTipValue =()=>{
      props.setSelectedTip(tipValue)
    }

  useEffect(() => {
    setAppliedTip(props.selectedTip);
    

  }, [props]);
  return (
    <TipDiv>
      <img src={TipImg} alt="" />
      <React.Fragment>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            width: "calc(100% - 60px)",
          }}
        >
          <h4>Tip your delivery partner</h4>
          <p>
            100% of the tips go to your delivery partner. Tips are always
            optional.
          </p>
          <ul>
            {tipValues.map((key, ival) => {
              return (
                <li
                  key={ival}
                  onClick={() => {
                    if (appliedTip === key) {
                      props.setSelectedTip("");
                    } else {
                      props.setSelectedTip(key);
                    }
                  }}
                  className={appliedTip === key ? "active" : ""}
                >
                  {props.currencySymbol}
                  {key}
                  {appliedTip === key ? (
                    <Cancel style={{ fontSize: "14px" }} />
                  ) : (
                    ""
                  )}
                </li>
               
                
              );
            })}
          <input className="customTip" placeholder="Custom Tip" type="num" value={tipValue} onChange={handleTip} onBlur={handleTipValue}/>

          </ul>
        </div>
      </React.Fragment>
    </TipDiv>
  );
}

import React, { useState } from "react";
import { ArrowBackIos } from "@material-ui/icons";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

const addressTitle = [
  {
    key: "HOME",
    value: "home",
  },
  {
    key: "WORK",
    value: "work",
  },
  {
    key: "OTHER",
    value: "other",
  },
];

function CreateAddress(props) {
  console.log(props.settings);
  const [openSnackbar] = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [addressLocation, setAddressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
      : {
          lat: parseFloat(props.settings?.lat),
          lng: parseFloat(props.settings?.lng),
        }
  );
  const initialValues = {
    type: "saved",
    customer_id: props.id,
    title: !props.isEmpty ? props.data.title : "home",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    phone: !props.isEmpty ? props.data.phone : "",
    // name: props.data ? props.data.name : "",
    // email: props.data ? props.data.email : "",
    latitude: !props.isEmpty ? props.data.latitude : "",
    longitude: !props.isEmpty ? props.data.longitude : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
    instructions: !props.isEmpty ? props.data.instructions : "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "c/addresses/" + props.data.id
      : "c/addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setAdvisible(false);
          setBtnLoading(false);

          openSnackbar(response.data.message);
        } else {
          setBtnLoading(false);
          openSnackbar(response.data.message);
          return;
        }
      }
    );
  };

  const validationSchema = Yup.object({
    //title: Yup.string().required("Required"),
    line1: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    zipcode: Yup.string()
      .required("Required")
      .min(5, "zipcode must be at least 5 digits")
      .max(10),
    // latitude: Yup.string().required("Required"),
  });

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.advisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setAdvisible(false)}
        />
        {props.isEmpty === true ? "Create" : "Edit"} Address
      </div>
      <div className="modal-body" style={{ padding: "0 20px" }}>
      <br/>
        <h4 style={{marginLeft:"10px"}}>Type delivery address below</h4>
        <br/>
        {/* <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: 45.4211, lng: -75.6903 }}
          >
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
            {/* We will render our data here *
          </GoogleMap> */}

        {/* <GMap center={{ lat: -24.9923319, lng: 135.2252427 }} /> */}

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={addressLocation}
                search={true}
                height="30vh"
                type="createAddress"
                onChange={(address, loc) => {
                  values.latitude = loc.lat();
                  values.longitude = loc.lng();
                  values.line1 = address.address[0].name;
                  values.city = address.city;
                  values.state = address.state;
                  values.country = address.country;
                  values.zipcode = address.pincode;
                  setFieldValue("line1", address.address[0].name);
                }}
                padding={true}
              />

              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  boxSizing: "border-box",
                  fontSize: "14px",
                  margin: "3px 0",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                placeholder="Address line1"
                name="line1"
                className="noBorder"
                disabled="true"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Unit/Apartment"
                name="line2"
                className="noBorder"
              />

              <h4 style={{ padding: "15px 15px 10px" }}>Other Details</h4>
              <div style={{ display: "inline-flex" }}>
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="City"
                  name="city"
                  disabled="true"
                  maxLength={30}
                  onChange={(event) => {
                    const re = /^[A-Za-z\s\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("city", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="State"
                  disabled="true"
                  name="state"
                  maxLength={15}
                  onChange={(event) => {
                    const re = /^[A-Za-z\s\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("state", event.target.value);
                    }
                  }}
                />
              </div>
              <div style={{ display: "inline-flex" }}>
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Country"
                  name="country"
                  disabled="true"
                  maxLength={51}
                  onChange={(event) => {
                    const re = /^[A-Za-z\s\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("country", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Zipcode"
                  disabled="true"
                  name="zipcode"
                  maxLength={10}
                  onChange={(event) => {
                    const re = /^[0-9\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("zipcode", event.target.value);
                    }
                  }}
                />
              </div>
              {/* <FormikControl
                control="input"
                type="text"
                placeholder="Phone"
                name="phone"
                maxLength={13}
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              /> */}
              <FormikControl
                control="input"
                as="textarea"
                placeholder="Instructions for delivery (Optional)"
                name="instructions"
              />
              <br />
              <GButton
                variant="condensed"
                loading={isBtnLoading}
                children={!props.isEmpty ? "Update" : "Create"}
                type="submit"
                style={{ width: "94%", margin: "0 15px 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}

export default CreateAddress;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Tabs = styled.div`
  ul {
    display: inline-flex;
    list-style-type: none;
    padding-left: 0px;
    margin: 0px;

    li {
      float: left;
      margin-bottom: -1px;
      text-transform: capitalize;

      a {
        color: #000;
        border-bottom: 2px solid transparent;
        text-decoration: none;
        font-size: 15px;
        padding: 10px 25px;
        margin: 0px;
        line-height: 42px;

        &:hover {
          color: ${({ theme }) => theme.button};
          border-bottom: 2px solid ${({ theme }) => theme.button};
        }
      }
      a.active {
        color: ${({ theme }) => theme.button};
        border-bottom: 2px solid ${({ theme }) => theme.button};
      }
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.26);
    cursor: not-allowed;

    &:hover {
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &.condensed {
    background: ${(props) => (props.primary ? "palevioletred" : "white")};
    color: ${(props) => (props.primary ? "white" : "palevioletred")};
    color: white;
    background: ${({ theme }) => theme.button};
    border: 0;
  }
  &.outlined {
    color: ${({ theme }) => theme.button};
    background: transparent;
    border: 1px solid ${({ theme }) => theme.button};
  }

  &.linkable {
    color: ${({ theme }) => theme.button};
    background: transparent;
    border: 0px;
    padding: 5px;
  }

  &.text {
    background: transparent;
    padding: 6px 12px;
    border: 0px;
    color: ${({ theme }) => theme.button};

    &:hover {
      background: rgba(25, 118, 210, 0.04);
    }
  }
`;

function GTabs(props) {
  return (
    <Tabs>
      {!props.type ? (
        <ul>
          {props.routes.map((r, index) => (
            <li className="gg" key={index}>
              <Link
                to={r.link}
                className={r.title === props.active ? "active" : ""}
              >
                {r.title.replace("-", " ")}
              </Link>
            </li>
            // <p>Hello, {person.name} from {person.country}!</p>
          ))}
        </ul>
      ) : (
        <ul>
          {props.routes.map((r, index) => (
            <li className="gg" key={index}>
              <Link
                to="#"
                onClick={(e) => {
                  props.onClick(r);
                }}
                className={r === props.active ? "active" : ""}
              >
                {" "}
                {r}
              </Link>
            </li>
            // <p>Hello, {person.name} from {person.country}!</p>
          ))}
        </ul>
      )}
    </Tabs>
  );
}

export default GTabs;

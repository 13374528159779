import React, { useEffect, useState } from "react";
import { Card, CardHead } from "../styledComponents/cardStyles";
import GOffers from "../gComponents/gOffers";
import { Grid } from "@material-ui/core";
import useDataFctory from "../useDataFactory"


export default function Offers() {
  const [couponsData, setCouponsData] = useState([]);
  
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("coupon", false);

  return (
    <div className="main-content">
      <br />
      <CardHead>Offers</CardHead>
      <Card>
        {loading === true ? <Shimmer />: data.data.length === 0 ?<Placeholder/>:
        <Grid container spacing={3}>
          {data.data.map((c, i) => {
            return (
              <Grid item lg={4} sm={6} xs={12} key={i}> 
        <GOffers data={c}/>
         </Grid>
            );
          })}
        </Grid>
      }
      </Card>
      <br />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { FlatList, View } from "react-native";
import { ImgUrl, addDefaultSrc } from "../services";
import styled from "styled-components";
import GButton from "../gComponents/gButton";
import useCart from "../useCart";
import { Add, Remove } from "@material-ui/icons";
import OrderProductVariants from "./orderProductVariants";
import OrderProductInfo from "./productInfo";
import Modal from "react-modal";
const ProductDiv = styled.div`
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f4f5f9;
  p {
    font-size: 14px;
    line-height: 20px;
    .price {
      color: ${({ theme }) => theme.button};
    }
  }
`;

const QuantityDiv = styled.div`
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  width: 70px;
  padding: 6px 10px;
  border: 1px solid ${({ theme }) => theme.button};
  color: ${({ theme }) => theme.button};
  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }
`;
const AddDiv = styled.div`
  margin-left: auto;
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.button};
    font-size: 0.9rem;
    margin-left: -15px;
    position: absolute;
  }
`;
const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "450px",
    padding: "20px 0",
    // transform: 'translate(0%, -50%)'
  },
};
export default function GProductStyles(props) {
  const [items, setItems] = useState(props.data || []);
  const { cart, addProduct, decreaseQuantity } = useCart();

  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  useEffect(() => {
    console.log(props.data);
    setItems(props.data);
  }, [props]);

  return (
    <React.Fragment>
      {props.data.length > 0 ? (
        <h5 style={{ padding: "10px", color: "#777", fontStyle: "italic" }}>
          {props.data.length} Items
        </h5>
      ) : (
        ""
      )}
      <View style={{ flex: 1, flexDirection: "row" }}>
        <FlatList
          // style={{ flex: 0 }}
          initialNumToRender={items.length}
          contentContainerStyle={{ flex: 1 }}
          keyExtractor={(items) => items.id}
          data={props.data}
          numColumns={1}
          renderItem={({ item }) => (
            <ProductDiv style={{ flex: 1, display: "flex" }}>
              <img
                src={ImgUrl("item") + "/" + item?.icon}
                alt={item?.title}
                onError={(e) => addDefaultSrc(e, "item")}
                style={{
                  width: "70px",
                  height: "70px",
                  marginRight: "20px",
                  objectFit: "cover",
                }}
                onClick={() => {
                  setSelectedProduct(item);
                  setItemInfoVisible(true);
                }}
              />
              <div style={{ paddingRight: "10px" }}>
                <h4>{item?.title}</h4>
                <p> {item?.unit ? item?.unit : ""}</p>
                <p>
                  {" "}
                  <span className="price">
                    {props.settings?.currency_symbol}
                    {item?.price}
                  </span>
                </p>
              </div>
              {cart && cart.some((c) => c.id === item?.id) ? (
                cart
                  .filter((c) => c.id === item?.id)
                  .map((c, i) => {
                    return (
                      <QuantityDiv>
                        <Remove onClick={() => decreaseQuantity(c)} />
                        <span style={{ padding: "0 8px", fontSize: "14px" }}>
                          {cart
                            .filter((c) => c.id === item.id)
                            .map((o) => o.quantity)
                            .reduce((a, c) => a + c, 0)}
                        </span>
                        <Add onClick={() => addProduct(c, c.variants)} />
                      </QuantityDiv>
                    );
                  })
              ) : (
                <AddDiv>
                  {item.status === "0" ? (
                    <GButton
                      variant="outlined"
                      children="NOT AVAILABLE"
                      disabled
                      style={{
                        borderRadius: "0",
                        color: "#a2a2a2",
                        border: "1px solid #d2d2d2",
                        fontSize: "11px",
                        padding: "0.6rem 0.4rem",
                        fontFamily: "LatoWeb",
                      }}
                    />
                  ) : (item.unlimited === 0) & (item?.stock === 0) ? (
                    <GButton
                      variant="outlined"
                      children="OUT OF STOCK"
                      disabled
                      style={{
                        borderRadius: "0",
                        color: "#a2a2a2",
                        border: "1px solid #d2d2d2",
                        fontSize: "11px",
                        padding: "0.6rem o.4rem",
                        fontFamily: "LatoWeb",
                      }}
                    />
                  ) : (
                    <React.Fragment>
                      <GButton
                        variant="outlined"
                        children="Add"
                        onClick={() => {
                          if ((item?.unlimited === 0) & (item?.stock === 0)) {
                            return false;
                          }
                          if (item?.variants_available === false) {
                            item.variants = [];
                            addProduct(item);
                          } else {
                            setSelectedProduct(item);
                            setOrderVarVisible(true);
                          }
                        }}
                        style={{
                          padding: "0.45rem 2rem",
                          borderRadius: "0",
                          cursor:
                            (item?.unlimited === 0) & (item?.stock === 0)
                              ? "auto"
                              : "pointer",
                        }}
                      />
                      {item?.variants_available === true ? (
                        <Add
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if ((item?.unlimited === 0) & (item?.stock === 0)) {
                              return false;
                            }
                            setSelectedProduct(item);
                            setOrderVarVisible(true);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  )}
                </AddDiv>
              )}
            </ProductDiv>
          )}
          ListEmptyComponent={props.Placeholder}
          // onEndReachedThreshold={0.4}
          // onEndReached={loadMore}
        />
      </View>

      {props.pagination.next_page_url ? (
        <GButton
          variant="condensed"
          children="Load More"
          type="button"
          onClick={() => props.onLoadMore()}
          style={{ display: "flex", margin: "10px auto" }}
        />
      ) : (
        ""
      )}

      <Modal
        isOpen={orderVarVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={customStyles}
        contentLabel="Variant Modal"
      >
        <OrderProductVariants
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(itemVariants) => {
            console.log(itemVariants);
            addProduct(selectedProduct, itemVariants);
            // setCart([...cart, item]);
          }}
        />
      </Modal>

      <Modal
        isOpen={itemInfoVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemInfoVisible(false)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderProductInfo
          item={selectedProduct}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
          settings={props.settings}
        />
      </Modal>
    </React.Fragment>
  );
}

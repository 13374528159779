import React, { useState, useEffect } from "react";
import GButton from "../gComponents/gButton";
import { ArrowBackIos } from "@material-ui/icons";
import { ApiService, ImgUrl } from "../services";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "react-simple-snackbar";
import GImageEditor from "../gComponents/gImageEditor";
import { Check } from "@material-ui/icons";

const ReportDiv = styled.div`
  p {
    margin: 10px 15px 0;
    font-size: 14px;
    color: #555;
  }
  .checkbox {
    margin: 10px 15px;
    & input[type="checkbox"] {
      display: none;

      &:checked + label span {
        background: ${({ theme }) => theme.button};
        border: 1px solid ${({ theme }) => theme.button};
        svg {
          color: #fff;
        }
      }
    }
    & label {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #555;
      margin: 0;

      span {
        border: 1px solid #ccc;
        height: 16px;
        margin-left: 3px;
        padding: 0.5px 1px 1.5px;
        border-radius: 2px;
        margin-right: 15px;
        svg {
          width: 1rem;
          height: 1rem;
          color: #ccc;
        }
      }
    }
  }
`;

var reasonOptions = [
  {
    title: "Faulty",
    value: "faulty",
  },
  {
    title: "Expired",
    value: "expired",
  },
];

export default function OrderReport(props) {
  const [openSnackbar] = useSnackbar();
  const [orderProducts, setOrderProducts] = useState(props.products || []);

  useEffect(() => {
    console.log(props);
    props.products.map((p, i) => (p.checked = false));
  }, [props]);

  const initialValues = {
    reason: "",
    description: "",
    products: [],
    photo: "",
    thumb_photo: "",
  };
  const onSubmit = (values) => {
    console.log(orderProducts);
    values.products = orderProducts.filter((t) => t.checked === true);
    console.log(values);

    if (values.products.length === 0) {
      openSnackbar("Choose product");
      return;
    }
    if (!values.photo) {
      openSnackbar("Choose Product Image");
      return;
    }
    // return;
    ApiService({
      method: "PUT",
      route: "c/order/return-products/" + props.orderId,
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.onSubmit();
        openSnackbar(response.data.message);
        props.setOReportvisible(false);
      } else {
        openSnackbar(response.data.message);
      }
    });
  };

  if (props.oReportvisible === false) {
    return null;
  }

  const validationSchema = Yup.object({
    reason: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    photo: Yup.string().required("Required"),
  });

  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOReportvisible(false)}
        />{" "}
        Faulty/Expired
      </div>
      <div className="modal-body">
        <ReportDiv>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <p>Please Choose the Reason below:</p>
                <FormikControl
                  control="radio"
                  name="reason"
                  options={reasonOptions}
                  key_title="title"
                  selected={values.title}
                  icons={false}
                  key_value="value"
                  // style={{ display: "none" }}
                  divstyle={{
                    display: "block",
                    margin: "5px 0",
                  }}
                  labelStyle={{
                    boxSizing: "border-box",
                    fontSize: "14px",
                    margin: "0px 3px",
                    verticalAlign: "bottom",
                  }}
                />

                <div style={{ margin: "25px 0" }}>
                  <p>
                    Please Choose the Product below which are Faulty or Expired.
                  </p>

                  {orderProducts.map((item, i) => {
                    return (
                      <div className="checkbox" key={i}>
                        <input
                          type="checkbox"
                          id={"check_" + item.id}
                          value={item.id}
                          defaultChecked={item.checked === true}
                          onChange={(e) => {
                            item.checked = e.target.checked;
                            orderProducts[i] = item;
                            setTimeout(function () {
                              setOrderProducts([...orderProducts]);
                            }, 1000);
                          }}
                        />

                        <label
                          className="radioLabelStyle"
                          htmlFor={"check_" + item.id}
                        >
                          {item.checked === true ? (
                            <span>
                              <Check
                                htmlFor={"check_" + item.id}
                                style={{ fontSize: "1rem" }}
                              />
                            </span>
                          ) : (
                            <span>
                              <Check style={{ fontSize: "1rem" }} />
                              {/* <img src={Tick} alt="Check" htmlFor={"check_" + option[key_title]} /> */}
                            </span>
                          )}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <img
                              src={ImgUrl("item") + "/" + item.thumb_photo}
                              alt={item.title}
                              style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "10px",
                              }}
                            />
                            <h4
                              style={{
                                fontSize: "13px",
                                maxWidth: "calc(100% - 100px)",
                              }}
                            >
                              {item.quantity} x {item.title}
                              <br />
                              {item.variants.map((v, i) => {
                                return <small> {v.value}</small>;
                              })}
                            </h4>
                            <p
                              style={{ fontSize: "13px", margin: "0 0 0 auto" }}
                            >
                              {item.price_display}
                            </p>
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>

                <p>Description</p>
                <FormikControl
                  control="input"
                  as="textarea"
                  placeholder="Enter Details..."
                  name="description"
                />

                <p>Upload Product Image</p>
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.photo = newValue;
                    values.thumb_photo = thumb_val;
                  }}
                  id="thumb_photo"
                  image={
                    values.thumb_photo
                      ? ImgUrl("faulty") + "/" + values.thumb_photo
                      : ""
                  }
                  type="faulty"
                  style={{
                    height: "60px",
                    paddingTop: "20px",
                    background: "transparent",
                    border: "0",
                  }}
                  divstyle={{ margin: "0 auto", display: "block" }}
                />

                <GButton
                  variant="condensed"
                  children="Submit"
                  type="submit"
                  style={{ width: "94%", margin: "10px 15px 15px" }}
                />
              </Form>
            )}
          </Formik>
        </ReportDiv>
      </div>
    </React.Fragment>
  );
}

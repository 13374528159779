// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/bgImage.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".hero {\r\n    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n    background-size: cover;\r\n    background-color: \"#f1f1f1\";\r\n    color: #f4f4f4;\r\n    min-height: 360px;\r\n    /* width: 100vw; */\r\n    font-family: \"Open Sans\", sans-serif;\r\n    font-weight: 600;\r\n    padding: 3rem;\r\n}\r\n.main-content {\r\n    margin-top: 0 !important;\r\n}\r\n", ""]);
// Exports
module.exports = exports;

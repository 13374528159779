import React, { useState } from "react";
import { Add, ArrowBackIos } from "@material-ui/icons";
import AddressView from "../gComponents/gAddressView";
import AddressForm from "./addressForm";
import GButton from "../gComponents/gButton"
import useDataFctory from "../useDataFactory";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";

import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '0',
    left: 'auto',
    right: '0',
    bottom: '0',
    width: '500px'
    // transform: 'translate(0%, -50%)'
  }
}

export default function OrderCustomerAddress(props) {
  const [advisible, setAdvisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const { loading, Shimmer, Placeholder, data, refreshData } = useDataFctory(
    "address",
    true
  );
  const [openSnackbar] = useSnackbar()

  console.log(props.data + "--");
  const handleDelete = (ruleId) => {
    var hitUrl = "c/addresses/" + ruleId;
    ApiService({
      method: "DELETE",
      route: hitUrl,
      body: "",
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
          openSnackbar(response.message);
        } else {
          openSnackbar(response.message);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  if (props.ocAddvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos className="backIcon" onClick={() => props.setOcAddVisible(false)} /> Delivery Address
                <GButton
          variant="linkable"
          children="Add"
          onClick={() => {
            setSelectedAddress({});
            setIsEmpty(true);
            setAdvisible(true);
          }}
          style={{
            marginLeft: "auto", padding: '0 0.5rem', height: '21px'
          }}
        ><Add /></GButton>
      </div>
      <div className="modal-body" style={{ padding: '10px' }}>


        {loading === true ? (
          <Shimmer></Shimmer>
        ) : data.length === 0 ? (
          <Placeholder />
        ) : (
              <React.Fragment>
                <h5 style={{ padding: "10px 20px", color: '#777', fontStyle: 'italic' }}>
                  {data.length} Address
            </h5>
                {data.map((v, i) => {
                  return (
                    <AddressView
                      dropdownActions={true}
                      onSelectOrderAddress={(address) => {
                        props.onSelectAddress(address);
                        props.setOcAddVisible(false);
                      }}
                      // onDelete={handleDelete}
                      addressData={v}
                      onSubmit={refreshData}
                      default={false}
                      orderAdress={true}
                      defaultAddress={props.defaultAddress}
                      onEdit={(sAddress) => {
                        console.log(sAddress);
                        setSelectedAddress(sAddress);
                        setIsEmpty(false);
                        setAdvisible(true);
                      }}
                      onDelete={handleDelete}
                    />
                  );
                })}
              </React.Fragment>
            )}

      </div>

      <Modal
        isOpen={advisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAdvisible(false)}
        style={customStyles}
        contentLabel="Coupon Modal"
      >
        <AddressForm
          advisible={advisible}
          setAdvisible={setAdvisible}
          data={selectedAddress}
          onSubmit={refreshData}
          isEmpty={isEmpty}
        />
      </Modal>


    </React.Fragment>
  );
}

import React, { useEffect, useState,useContext } from "react";
import { ApiLoginService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { ArrowBackIos } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/untick.svg";
import styled from "styled-components";

import useCart from "../useCart";
import { CartContext } from "../contexts/CartStore";



const VariantDiv = styled.div`
  /* 
label::before{
  height:20px;width:20px;
  background:url(${Uncheck});
  content:"";background-size:20px 20px

}

input:checked + label::before{
  background:url(${Check});
} */
`;

export default function OrderProductVariants(props) {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [variant, setVariant] = useState([]);
  const [screen, setSceen] = useState(false);
  const [openSnackbar] = useSnackbar();
  const { itemCart } = useContext(CartContext);

  const {
    cart,
    setCart,
    cartStore,
    setCartStore,
    addProduct,
    removeProduct,
    decreaseQuantity,
  } = useCart();

  console.log(props.data + "--");
// console.log("variant===element CART",cart )
// console.log("variant===element ItemCART",itemCart )
  // For checking ios device screen only
  const screenFunc = ()=>{
    if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1 && window.screen.width<768){
      setSceen(true)
    }else{
      setSceen(false)
    }
  }
  useEffect(() => {
    ApiLoginService("GET", "c/products/" + props.item?.id).then((response) => {
      console.log(JSON.stringify(response));
      if (response.status_code === 1) {
        setSelectedProduct(response.data);
        console.log("product ==== data", response.data.variants.map(c=>c.options))
        setVariant([])
      } else {
        //openSnackbar(response.message);
      }
    });
    screenFunc();
  }, []);

  const validateData = () => {
    let validated = true;
    console.log(selectedProduct.variants);
    selectedProduct.variants.every((element) => {
      console.log("product ==== element",element);
      //Maximum Minimum Check
      if (element.options.length > 0) {
        let count = element.options.filter((e) => e.id === element.selected)
          .length;

        if (element.maximum < count) {
          openSnackbar(
            `Maximum Selection for ${element.title} is ${element.maximum}`
          );
          validated = false;
          return false;
        }
        // if (element.minimum > count) {
        //   openSnackbar(`${element.title} selection is required.`);
        //   validated = false;
        //   return false;
        // }
        return true;
      } else {
        return true;
      }
    });
    return validated;
  };

  const onAddVariants = () => {
    let validate = validateData();
    console.log("product ==== validate",validate);

    if (validate === true) {
      let newArray = [];
      selectedProduct.variants.map((element) => {
        // console.log("variant===element",element);
        if (element.selected) {
          let v = element.options.filter((e) => e.id === element.selected);
          console.log("product ==== elementV",v);
          console.log("product ==== elementS",element.selected);
          setVariant(v)
          if (v && v.length > 0) {
            // setVariant(v)
            newArray = newArray.concat(v);
            // console.log("variant===element new",newArray);
          }
        } else {
          return false;
        }
      });
      let newItem = props.item;
      newItem.variants = newArray;
      console.log("product ==== variant ",variant)
      // console.log("variant===element newItem",newItem);
      //       console.log("variant===element new",newArray);


      props.onSubmitVariants(variant);
      props.setOrderVarVisible(false);
    }
  };
  const handleChange = (e)=>{
    console.log("target E",e.selected,selectedProduct)
   var s = selectedProduct.variants[0].options.filter((c)=> c.id==e.target.value );
   console.log("target S",s)
   setVariant(s)

  }
  const handleAdd=()=>{
    let validate = validateData();
    addProduct(selectedProduct,variant)
    props.setOrderVarVisible(false)
  }
  console.log("product ==== variant ",variant)


  if (props.orderVarVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      {/* <h3>Choose Variants</h3> */}
      <div className="modal-header" style={{marginTop:screen?"60px":"0"}}>
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOrderVarVisible(false)}
        />{" "}
        Choose Option
      </div>

      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          overflow: "overlay",
          height: "calc(100vh - 70px)",
        }}
      >
        {selectedProduct.variants && selectedProduct.variants.length > 0 ? (
          <React.Fragment>
            {selectedProduct?.variants?.map((v, i) => {
              return (
                <React.Fragment key={i}>
                  {v.options?.length > 0 ? (
                    <React.Fragment>
                      <br />
                      <h4 style={{ margin: "10px" }}>{v.title}</h4>
                    </React.Fragment>
                  ) : (
                    ""
                  )}

                  {v.options?.map((option, index) => {
                    return (
                      <div key={index} style={{marginBottom:"5px"}}>
                      <input type="radio" id="html" name="fav_language" value={option.id} onChange={(e)=>handleChange(e)}/> &nbsp;
                      <label for="html">{option.title}</label> (${option.price}) <br/>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
            <br />
            <GButton
              variant="condensed"
              children="Add to cart"
              style={{ width: "100%", margin: "10px 0" }}
              type="button"
              onClick={handleAdd}
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

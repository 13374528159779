import React, { useState, useEffect } from "react";
import { CardHead } from "../styledComponents/cardStyles";
import { Grid } from "@material-ui/core";
import { ApiService } from "../services";
// import GEmpty from "../gComponents/gEmpty";
// import GShimmer from "../gComponents/gShimmer";
import AddressView from "../gComponents/gAddressView";
import AddressForm from "./addressForm";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import useSettings from "../useSettings";

import Modal from "react-modal";
import { setDefaultLocale } from "react-datepicker";

export default function Addresses() {
  const settings = useSettings();
  const { default_location } = settings;
  // const [addressData, setAddressData] = useState([]);
  const [advisible, setAdvisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);

  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("address", true);

  const customStyles = {
    content: {
      top: "0",
      left: "auto",
      right: "0",
      bottom: "0",
      width: "500px",
      // transform: 'translate(0%, -50%)'
    },
  };

  const [openSnackbar] = useSnackbar();

  const handleDelete = (addressId) => {
    var hitUrl = "c/addresses/" + addressId;
    ApiService({
      method: "DELETE",
      route: hitUrl,
      body: "",
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
          openSnackbar(response.data.message);
        } else {
          openSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDefaultAddress = (newVal) => {
    ApiService({
      method: "PUT",
      route: "c/addresses/" + newVal,
      body: { default: 1 },
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
        } else {
        }

        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <CardHead style={{ margin: "20px 10px 15px" }}>
        Delivery Address
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedAddress({});
            setIsEmpty(true);
            setAdvisible(true);
          }}
          style={{
            float: "right",
            padding: "0.5rem 2rem",
          }}
        >
          Add
        </GButton>
      </CardHead>

      {loading === true ? (
        <Shimmer></Shimmer>
      ) : data.length === 0 ? (
        <Placeholder />
      ) : (
        <React.Fragment>
          <Grid container spacing={0}>
            {data.map((v, i) => {
              return (
                <Grid item lg={4} md={6} xs={12} key={i}>
                  <AddressView
                    dropdownActions={true}
                    onEdit={(sAddress) => {
                      setSelectedAddress(sAddress);
                      setIsEmpty(false);
                      setAdvisible(true);
                    }}
                    onDelete={handleDelete}
                    addressData={v}
                    onSubmit={refreshData}
                    default={true}
                    onSelectDefault={handleDefaultAddress}
                    style={{ border: "1px solid #e8e8e8" }}
                  />
                </Grid>
              );
            })}
          </Grid>
          {pagination.next_page_url ? (
            <GButton
              variant="condensed"
              children="Load More"
              type="button"
              onClick={() => loadMore()}
              style={{ display: "flex", margin: "10px auto" }}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      )}

      <br />

      <Modal
        isOpen={advisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAdvisible(false)}
        style={customStyles}
        contentLabel="Address Modal"
      >
        <AddressForm
          advisible={advisible}
          setAdvisible={setAdvisible}
          data={selectedAddress}
          onSubmit={refreshData}
          isEmpty={isEmpty}
          settings={
            default_location ? JSON.parse(default_location) : default_location
          }
        />
      </Modal>
    </React.Fragment>
  );
}

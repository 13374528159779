import React, { useState, useEffect } from "react";
import {
  ApiLoginService,
  ApiService,
  ImgUrl,
  addDefaultLogo,
} from "../services";
import GButton from "../gComponents/gButton";
import FacebookIcon from "../assets/images/facebook.png";
import { useSnackbar } from "react-simple-snackbar";


export default function FbSignin(props) {
  const [openSnackbar] = useSnackbar();
  function login_me() {
    window.FB.login(function (response) {
      statusChangeCallback(response);
    },{scope: 'public_profile,email'});
  }

  function statusChangeCallback(response) {
    if (response.status == "connected") {
      console.log("Logged in and authenticated");
      testAPI();
    } else {
      console.log("Not authenticated");
    }
  }

  function checkLoginState() {
    window.FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  }

  function initUser(token) {
    fetch('https://graph.facebook.com/v2.5/me?fields=email,name,friends&access_token=' + token)
          .then((response) => response.json())
          .then((json) => {
            // Some user object has been set up somewhere, build that user here
            var myUser = {
              user_name : json.name,
              user_id : json.id,
              user_user_friends : json.friends,
              user_email : json.email,
              user_username : json.name,
            }

            console.log("User Grapgh Data: "+myUser.user_email);

          })
          .catch(() => {
            // reject('ERROR GETTING DATA FROM FACEBOOK')
            console.log("ERROR GETTING DATA FROM FACEBOOK");
          })
  }
  function testAPI() {
    window.FB.api(
      // "/me?fields=id,first_name,last_name,email",
      // "/me?fields=first_name,last_name,email",


      // FB.api(
        '/me',
        'GET',
        {"fields":"id,first_name,last_name,email"},
      //   function(response) {
      //       // Insert your code here
      //   }
      // );


      function (response) {
        if (response && !response.error) {
          var fb_access_token = window.FB.getAuthResponse()["accessToken"];
          console.log("Access Token = " + fb_access_token);
          console.log("FB respnse = "+response.email);

          // initUser(fb_access_token);

          
          var facebook_login_oauth = {
            // username: response.first_name + " " + response.last_name,
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
            grant_type: "facebook",
            token: fb_access_token,
            client_id: "2",
            client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
            provider: "customers",
          };

          console.log("#################################");
          console.log(facebook_login_oauth);
          console.log("#################################");

          console.log(fb_access_token);
          login_social(facebook_login_oauth, fb_access_token);
        }
      }
    );
  }

  const login_social = (outhData, fbToken) => {
    console.log(fbToken);

    ApiLoginService("POST", "c/social-login", {
      method: "facebook",
      token: fbToken,
    })
      .then(function (response) {
        console.log(response);
        if (response.status_code === 1) {
          getFbOauthToken(outhData, fbToken);
        } else {
          openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getFbOauthToken = (oauthData) => {
    ApiLoginService("POST", "oauth/token", oauthData)
      .then(function (response) {
        console.log(response);
        if (response) {
          localStorage.setItem("accessToken", response.access_token);
          localStorage.setItem("refreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("expires_in", now + response.expires_in);

          window.location.reload(false);
        } else {
          console.log(response.data);
          // openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const responseFacebook=(response)=>{
    console.log(response);

    if (response && !response.error) {
      var fb_access_token = response.accessToken;//window.FB.getAuthResponse()["accessToken"];
      console.log("Access Token = " + fb_access_token);
      console.log(response);

      var facebook_login_oauth = {
        username: response.first_name + " " + response.last_name,
        email: response.email,
        grant_type: "facebook",
        token: fb_access_token,
        client_id: "2",
        client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
        provider: "customers",
      };

      console.log("#################################");
      console.log(facebook_login_oauth);
      console.log("#################################");

      console.log(fb_access_token);
      // login_social(facebook_login_oauth, fb_access_token);
    }
  }

  return (
    
      <GButton
        variant="linkable"
        style={{
          margin: "8px",
        }}
        onClick={() => {
          login_me();
        }}
        type="button"
      >
        <img src={FacebookIcon} style={{ width: "40px" }} />
      </GButton>
      
  );
}

import axios from "axios";
import placeholder from "./assets/images/list-placeholder.png";
import categoryPlaceholder from "./assets/images/placeholders/p-category.png";
import itemPlaceholder from "./assets/images/placeholders/p-item.png";
import storePlaceholder from "./assets/images/placeholders/p-store.png";
import userPlaceholder from "./assets/images/placeholders/f-placeholder.png";
import offerPlaceholder from "./assets/images/placeholders/offerBanner.png";
import AppLogo from "./assets/images/app-logo.jpeg";
import moment from "moment-timezone";
import Axios from "./Axios";
require("dotenv").config();

export function addDefaultSrc(e, type) {
  e.target.src =
    type && type === "item"
      ? itemPlaceholder
      : type === "store"
      ? storePlaceholder
      : type === "offer"
      ? offerPlaceholder
      : type === "category"
      ? categoryPlaceholder
      : type === "user"
      ? userPlaceholder
      : placeholder;

  // return e.target.src;
}
export function addDefaultLogo(e) {
  e.target.src = AppLogo;
  // return e.target.src;
}
export function ImgUrl(imgType) {
  var ImageUrl = process.env.REACT_APP_IMAGE_URL;

  if (imgType) {
    ImageUrl = ImageUrl + "/" + imgType;
  }

  return ImageUrl;
}
 // "c/products?per_page=30&store_id=" + storeId + "&sale=1";
export async function ApiService(service) {
  const getParams = () => {
    var formBody = [];
    for (var property in service.body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(service.body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    console.log(formBody);
    formBody = formBody.join("&");
    return "?" + formBody;
  };
  let urlParam =
    service.method === "GET"
      ? service.body && Object.keys(service.body).length > 0
        ? getParams()
        : ""
      : "";
  let postData = service.method === "GET" ? "" : service.body;
  console.log(process.env.REACT_APP_API_URL);
 
  try {
    let response1 = await axios({
      method: service.method,
      url: process.env.REACT_APP_API_URL + "/" + service.route + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    if (response1.data.status_code === 417) {
      localStorage.clear();
      // window.location = "/";
    }

    return response1;
  } catch (error) {
    return error.response;
  }
}
export async function csvFileApiService(method, hUrl, param) {
  try {
    var fd = new FormData();
    fd.append("csv", param.csv);
    fd.append("store_id", param.store_id);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/a/" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}
export async function FileApiService(method, hUrl, param) {
  try {
    console.log(param);
    var fd = new FormData();
    fd.append("image", param);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/c/upload-image?type=" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function ApiLoginService(method, hUrl, param) {
  try {
    console.log(hUrl);
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: param,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        // Authorization: "",
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function LogoutService(hUrl) {
  try {
    let response1 = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: "",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

import React, { useState, useEffect } from 'react'
import { ArrowBackIos } from "@material-ui/icons";
import useDataFactory from "../useDataFactory";
import FoodStores from "../gComponents/gStoreType1"
import { GSearch } from "../gComponents/gSearch";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import { ApiLoginService } from "../services";
import useLocalStorage from "../useAsyncStorage"

const SearchDiv = styled.div`
padding:10px;
cursor:pointer;position:relative;
border-bottom:1px solid #f2f2f2;
 
`;

export default function StoreSearch(props) {
    let history = useHistory();
    const [searchStore, setSearchWord] = useState('');
    const [selectedGeofence, setSelectedGeofence] = useLocalStorage("area", "");
    const { loading, Shimmer, Placeholder, data, loadMore, pagination, refreshData } = useDataFactory('store', true);

    useEffect(() => {
        var hitUrl = "c/cities?per_page=30";
        ApiLoginService("GET", hitUrl)
            .then((response) => {
                console.log("gh" + response);
                if (response.status_code === 1) {
                    response.data.map((values) => {
                        setSelectedGeofence(values.geofences[0]?.id)
                    });
                    //setSelectedValue(value);
                } else {
                    return
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])
    useEffect(() => {
        refreshData({ search: searchStore });
    }, [searchStore]);



    const goToCategories = (selectedStore) => {
        props.setSSearchvisible(false);
        history.push({
            // pathname: '/store/' + selectedStore.id + '/categories',
            pathname: '/store/' + selectedStore.id + '/products',
            state: { store: selectedStore }
        });
    }

    if (props.sSearchvisible === false) {
        return null;
    }
    return (
        <React.Fragment >
            <div className="modal-header" >
                <ArrowBackIos className="backIcon" onClick={() => props.setSSearchvisible(false)} /> Search Stores
        </div>
            <div className="modal-body" style={{ padding: "0 25px", overflow: 'overlay', height: 'calc(100vh - 70px)' }}>
                <GSearch
                    placeholder="Search Store.."
                    value={searchStore}
                    handleChange={(val) => {
                        setSearchWord(val);
                    }}
                    style={{ margin: '15px 0' }}
                />
                {loading === true ? <Shimmer></Shimmer> : data.length === 0 ? <GEmpty type="store"></GEmpty> :
                    <React.Fragment>
                        <h5 style={{ fontStyle: 'italic', margin: '10px' }}>Stores ({data.length})</h5>
                        {data.map((store, i) => {
                            return (
                                <SearchDiv>
                                    <FoodStores data={store} onSelectStore={goToCategories} key={i} listView={true} />
                                </SearchDiv>
                            )
                        })

                        }
                    </React.Fragment>
                }
            </div>

        </React.Fragment >
    );
}  

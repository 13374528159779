import React, { useEffect, useState } from "react";
// import "./Offer.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { ImgUrl, addDefaultSrc } from "../services"

export default function SlideCarousel(props) {
    const [sliderData, setSliderData] = useState(props.data);
    const [screen, setScreen] = useState();
    useEffect(() => {
        setSliderData(props.data);
        window.addEventListener("load", myFunction);
        // console.log(sliderData)
    }, [props]);

    const myFunction = () => {
        // console.log("hi");
        setScreen(window.innerWidth);
        console.log("window width", window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", myFunction);

    }, []);

    return (
        <Swiper
            className="offer__swiper"
            spaceBetween={screen || window.innerWidth > 600 ? 30 : 10}
            slidesPerView={screen || window.innerWidth > 600 ? 3 : 1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            style={{ margin: '20px auto' }}
        >
            {sliderData && sliderData.map((offer, index) => {
                return (
                    <SwiperSlide key={index} className="offer__swiperImage" style={{ boxShadow: 'none', margin: '0 15px', maxWidth: screen > 200 && screen < 570 ? '70%' : screen >= 571 && screen < 991 ? '60%' : screen >= 992 && screen < 1200 ? '35%' : screen >= 1201 && screen < 1500 ? '30%' : '25%' }}>
                        <img
                            src={ImgUrl('banners') + "/" + offer.photo}
                            className="offer__swiperImages"
                            alt=""
                            onError={addDefaultSrc}
                        />
                    </SwiperSlide>
                );
            })}
        </Swiper>

    );
};

// export default function SlideCarousel(props) {

//     const [sliderData, setSliderData] = useState(props.data);
//     useEffect(() => {
//         setSliderData(props.data);
//         console.log(sliderData)
//     }, [props]);
//     // const sliderData = [
//     //     {
//     //         id: 1,
//     //         url:
//     //             "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1560431486.png",
//     //     },
//     //     {
//     //         id: 2,
//     //         url:
//     //             "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1560431475.png",
//     //     },
//     //     {
//     //         id: 3,
//     //         url:
//     //             "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1568093868.png",
//     //     },
//     //     {
//     //         id: 4,
//     //         url:
//     //             "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1601976017.jpg",
//     //     },
//     //     {
//     //         id: 5,
//     //         url:
//     //             "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1574930965.jpg",
//     //     },
//     //     {
//     //         id: 6,
//     //         url:
//     //             "https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHw%3D&w=1000&q=80",
//     //     },
//     // ];
//     return (
//         <div className="offer">
//             <Swiper
//                 className="offer__swiper"
//                 spaceBetween={30}
//                 slidesPerView={3}
//                 onSlideChange={() => console.log("slide change")}
//                 onSwiper={(swiper) => console.log(swiper)}
//             >
//                 {sliderData.map((image, id) => {
//                     return (
//                         <SwiperSlide className="offer__swiperImage">
//                             <img
//                                 src={ImgUrl('banners') + "/" + image.photo}
//                                 class="offer__swiperImages"
//                                 alt=""
//                                 onError={addDefaultSrc}
//                             />
//                         </SwiperSlide>
//                     );
//                 })}
//             </Swiper>
//         </div>

//     );
// };

// import React from "react";
// import "./Offer.css";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.scss";

// const Offers = () => {
//     const data = [
//         {
//             id: 1,
//             url:
//                 "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1560431486.png",
//         },
//         {
//             id: 2,
//             url:
//                 "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1560431475.png",
//         },
//         {
//             id: 3,
//             url:
//                 "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1568093868.png",
//         },
//         {
//             id: 4,
//             url:
//                 "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1601976017.jpg",
//         },
//         {
//             id: 5,
//             url:
//                 "https://www.saasotest.tk/api/goteso-assets/food/banners/image_1574930965.jpg",
//         },
//         {
//             id: 6,
//             url:
//                 "https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHw%3D&w=1000&q=80",
//         },
//     ];
//     return (
//         <div className="offer">
//             <div className="offer__details">
//                 <h4 className="offer__heading">Offers</h4>
//                 <p className="offer__para">Following are the offers</p>
//             </div>
//             <Swiper
//                 className="offer__swiper"
//                 spaceBetween={30}
//                 slidesPerView={3}
//                 onSlideChange={() => console.log("slide change")}
//                 onSwiper={(swiper) => console.log(swiper)}
//             >
//                 {data.map((image, id) => {
//                     return (
//                         <SwiperSlide className="offer__swiperImage">
//                             <img
//                                 src={image.url}
//                                 class="offer__swiperImages"
//                                 alt=""
//                             />
//                         </SwiperSlide>
//                     );
//                 })}
//             </Swiper>
//         </div>

//     );
// };
// export default Offers;

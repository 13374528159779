import React from "react";
import {
  View,
  Text
} from 'react-native';
import styled from "styled-components";

const P = styled.div` 
  h4 {
    color: #000;
    font-size: 1.2rem;
    font-weight: 450;
    margin: 10px 0;
    padding: 0px;
  }
  p {
    color: #000;
    font-size: 0.9rem;
    margin: 5px 0;
    font-weight: 400;
  }
`;

const GText = (props) => {
  // export default function GText(props) {
  console.log(props)
  return (
    <View style={props.style}>
      <P style={props.style}>{props.text}</P>
    </View>
  );
}


export default GText;
import React, { useState } from "react";
import useSettings from "../useSettings";
import { addDefaultLogo, ImgUrl } from "../services";
import "../css/appPrompt.css";


import Modal from "react-modal";



const AppPrompt = () => {
  const settings = useSettings(true);
  const [prompt, setPrompt]=useState(true)

  const handlePrompt=()=>{
    if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
        window.location.href = 'https://play.google.com/store/apps/details?id=com.ripeontime.customer&hl=en_US&gl=US';
    }
    else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
        window.location.href = 'https://apps.apple.com/us/app/ripeontime/id1549359984';
    }else if(navigator.userAgent.toLowerCase().indexOf("ipad") > -1){
        window.location.href = 'https://apps.apple.com/us/app/ripeontime/id1549359984';
    }else {
        setPrompt(false)
    }
  }

    return (
     <Modal
        isOpen={prompt}
        className="AppModal"
        overlayClassName="modal-overlay"
        // onRequestClose={() => setVerifyOtpvisible(false)}
        contentLabel="Verify OTP Modal"
        shouldCloseOnOverlayClick={true}
      >
    <div className="appPrompt">
    {settings?.web_header_logo ? (
          <img
            src={ImgUrl("website") + "/" + settings?.web_header_logo}
            className="header-logo"
            alt={settings?.project_name}
            onError={addDefaultLogo}
          />
        ) : settings?.admin_logo ? (
          <img
            src={ImgUrl("logo") + "/" + settings?.admin_logo}
            className="header-logo"
            alt={settings?.project_name}
            onError={addDefaultLogo}
          />
        ) : (
          ""
        )}
     <p>Most users prefer shopping in app</p>
     <div className="appPrompt-btn">
         <button onClick={handlePrompt}>Go to our App</button>
         <button onClick={()=>setPrompt(false)}>Continue To Website</button>
     </div>
    </div>
    </Modal>
    )
}

export default AppPrompt


import React, { useState } from "react";
import { FlatList, View } from "react-native"
import { ArrowBackIos } from "@material-ui/icons"
import styled from "styled-components";
import GInfo from "../gComponents/gInfo";
import useDataFctory from "../useDataFactory"
import GButton from "../gComponents/gButton";
import QuoteImage from "../assets/images/quote.png"

// const OrdersDiv = styled.div`
//   background: #fff;
//   margin: 10px;
//   padding: 12px 12px 20px;
//   box-sizing: border-box;
//   flex: 0.333;
// `;

export default function OrderReviews(props) {
    console.log(props)
    const { loading, Shimmer, Placeholder, data, loadMore, pagination, refreshData } = useDataFctory('reviews', true, { store_id: props.store.id });
    console.log(data)


    return (
        <React.Fragment>
            <div className="modal-header">
                <ArrowBackIos className="backIcon" onClick={() => props.setSRVisible(false)} /> {props.store.title}
            </div>
               <div className="modal-body">
               {loading === true ? <Shimmer></Shimmer> :
            <React.Fragment>
            {data.length > 0 ? <p style={{ marginBottom: '15px', color: '#777', fontStyle: 'italic' }}>{data.length} {data.length === 1 ? 'Review' : 'Reviews'}</p> : ''}
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <FlatList
                            // style={{ flex: 0 }}
                            initialNumToRender={data.length}
                            contentContainerStyle={{ flex: 1 }}
                            keyExtractor={data => data.id}
                            data={data}
                            numColumns={1}
                            renderItem={({ item }) => (<div style={{ flex: 1, }}>
                                <div style={{
                                    background: '#fff',
                                    padding: '15px 5px', borderBottom: '1px solid #f2f2f2'
                                }}>
                                    <div>
                                        <img src={QuoteImage} alt="Quote" style={{ width: '20px', marginRight: '15px', verticalAlign: 'top' }} />
                                        <h4 style={{
                                            marginBottom: '10px', fontFamily: 'LatoWeb', width: 'calc(100% - 40px)',
                                            display: 'inline-block', lineHeight: '22px', minHeight: '20px'
                                        }}>{item.review}</h4>
                                    </div>
                                    <GInfo
                                        title=''
                                        subtitle={item?.customer.title}
                                        subtitle2={item?.created}
                                        imgType="user"
                                        photo={item?.customer.thumb_photo}
                                        rating={item?.rating}
                                        subtitleStyle={{ fontFamily: 'LatoWeb' }}
                                    />
                                </div>
                            </div>)}
                            ListEmptyComponent={Placeholder}
                        // onEndReachedThreshold={0.4}
                        // onEndReached={loadMore}
                        />

                    </View>

                    {pagination.next_page_url ? <GButton variant="condensed" children="Load More" type="button" onClick={() => loadMore()} style={{ display: 'flex', margin: '10px auto' }} /> : ''}
                
                </React.Fragment>
                }
                </div>
            



            <br />
        </React.Fragment>
    );
}

import React from "react";
import { Assignment, LocationOn, AccountBalanceWallet, Share } from '@material-ui/icons';
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Plink = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 200px;
  margin-right: 20px;
  vertical-align: top;
  display:flex;
  flex-direction:column;

   @media(max-width:767px){
          flex-direction:row;
          width:100%;
          overflow:overlay;
        }

  li {
    a {
      text-decoration: none;
      display: block;
      padding: 10px 12px;
      color: #555;font-size:15px;
     
      .MuiSvgIcon-root{
        vertical-align:text-bottom;
        color:#b2b2b2;
        font-size:1.1rem;padding-right:5px;
         @media(max-width:767px){
           font-size:1.4rem
         }
      }

      &.active {
        background: #fff;
          color:#000;
          .MuiSvgIcon-root{
            color:#555
          }
      }


      span{
        @media(max-width:767px){
            display:block;padding-bottom:7px
        }
      }

       @media(max-width:767px){
            text-align:center;
            font-size:13px
        }
    }
     @media(max-width:767px){
            width:25%;min-width:84px
    }
  }
`;

export default function profileLinks(props) {
  return (
    <Plink>
      <li>
        <Link
          to="/profile/orders"
          className={props.url === "/profile/orders" ? "active" : ""}
        >
          <span role="img" aria-label="Order" className="icon">
            {/* 📋 */}
            <Assignment />
          </span>
          Orders
        </Link>
      </li>
      <li>
        <Link
          to="/profile/address"
          className={props.url === "/profile/address" ? "active" : ""}
        >
          <span role="img" aria-label="Order" className="icon">
            {/* 📍 */}
            <LocationOn />
          </span>{" "}
          Addresses
        </Link>
      </li>
      <li>
        <Link
          to="/profile/wallet"
          className={props.url === "/profile/wallet" ? "active" : ""}
        >
          {" "}
          <span role="img" aria-label="Order" className="icon">
            {/* 💰 */}
            <AccountBalanceWallet />
          </span>{" "}
          Wallet
        </Link>
      </li>
      <li>
        <Link
          to="/profile/invite"
          className={props.url === "/profile/invite" ? "active" : ""}
        >
          {" "}
          <span role="img" aria-label="Order" className="icon">
            <Share />
          </span>{" "}
          Invite & Earn
        </Link>
      </li>
    </Plink>
  );
}

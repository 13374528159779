import React from 'react';
import loading from "../assets/images/loading.gif"

const Spinner = ()=>{

        return (
            <div style={{textAlign: 'center'}}>
                <img style={{margin:"5px 0"}} src={loading} alt="loading" />
            </div>
        )
  
}

export default Spinner

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ApiLoginService, ImgUrl } from "../services";
import { Card, CardHead } from "../styledComponents/cardStyles";
import Slider from "../gComponents/gSlideCarousel";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import { LocationSearch } from "../components/areaSearchComponent";
import { usePosition } from "../usePosition";
import { useSnackbar } from "react-simple-snackbar";
import useLocalStorage from "../useAsyncStorage";
import useSettings from "../useSettings";
import ContentSection from "./indexContent";
import GCardShimmer from "../gComponents/gShimmer";
import Store from "./store";
import FeaturesCard from "./customFeaturesCard";
import { Typography, Button } from "@material-ui/core";
import driverCardImage from "../assets/images/driverCard.jpg";
import storeCardImage from "../assets/images/storeCard.jpg";
import "../css/registerDriverRestraunt.css"

const useStyles = makeStyles((theme) => ({
  register: {
    margin: "20px 0",
    textAlign: "center",
  },
  registerHeroText: {
    textAlign: "center",
    marginLeft: "2.5rem",
  },
  // registerSections: {
  //   width:"100%",
  //   height:"max-content",
  //   minHeight: "600px",
  //   padding:"20px 10px",
  //   display: "flex",
  //   flexDirection: "row",
  //   flexWrap:"wrap",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   gap:"30px"
  //   // maxWidth: "1290px",
  // },
  // registerSection1: {
  //   // position: "relative",
  //   // width:"40%",
  //   // minWidth:"600px",
  //   // height: "500px",
  //   // // background: ` linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url('${driverCardImage}')`,
  //   // backgroundImage: `url('${driverCardImage}')`,
  //   // alignItems: "center",
  //   // overflow: "hidden",
  //   // borderRadius:"10px"
  //   // [theme.breakpoints.down("sm")]: {
  //   //   flexDirection: "column-reverse",
  //   //   alignItems: "center",
  //   //   textAlign: "center",
  //   // },
  // },
  // registerSection1Left: {
  //   position: "relative",
  //   zIndex:"1",
  //   width: "100%",
  //   height:"100%",
  //   padding: "40px 60px",
  //   display:"flex",
  //   flexDirection: "column",
  //   justifyContent: "space-evenly",
  //   boxSizing:"border-box",
  //   // boxShadow: " inset 3px 3px 59px 25px rgba(0,0,0,0.6), inset 0px 0px 114px 74px rgba(82,82,82,1), 46px -3px 101px 52px rgba(0,0,0,1)",

  //   // [theme.breakpoints.down("sm")]: {
  //   //   width: "70%",
  //   // },
  // },
  // registerSection1LeftBlur:{
  //   position: "absolute",
  //   top:"50%",
  //   left:"50%",
  //   transform: "translate(-50%,-50%)",
  //   height: "100%",
  //   width: "90%",
  //   backgroundColor:"rgba(0,0,0,0.8)",
  //   boxSizing:"border-box",
  //   filter:"blur(50px)",
  //   // borderRadius: "50%",
  //   // boxShadow: " inset 3px 3px 59px 25px rgba(0,0,0,0.6), inset 0px 0px 114px 74px rgba(82,82,82,1), 46px -3px 101px 52px rgba(0,0,0,1)",
  // },
  
  // registerSection1Right: {
  //   width: "50%",
  //   // backgroundColor: "#c3c3c3",
  //   margin: " 0 10px",
  //   borderRadius: "14px",
  //   height: "500px",
  //   background: ` linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3)), url('${driverCardImage}')`,
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   [theme.breakpoints.down("sm")]: {
  //     width: "80%",
  //   },
  // },
  // registerSection2: {
  //   position: "relative",
  //   width:"40%",
  //   minWidth:"600px",
  //   height: "500px",
  //   backgroundImage: `url('${storeCardImage}')`,
  //   alignItems: "center",
  //   overflow: "hidden",
  //   borderRadius:"10px",
  //   // background: ` linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3)), url('${storeCardImage}')`,
  //   // background: ` linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3)), url('${storeCardImage}')`,
  //   // [theme.breakpoints.down("sm")]: {
  //   //   flexDirection: "column",
  //   //   alignItems: "center",
  //   //   textAlign: "center",
  //   // },
  // },
  
  // registerSection2Left: {
  //   width: "50%",
  //   backgroundColor: "#c3c3c3",
  //   margin: " 0 10px",
  //   borderRadius: "14px",
  //   height: "500px",
  //   background: ` linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3)), url('${storeCardImage}')`,
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   [theme.breakpoints.down("sm")]: {
  //     width: "80%",
  //   },
  // },
  // registerSection2Right: {
  //   transform: "scale(0.90)",
  //   width: "50%",
  //   marginLeft: "16px",
  //   padding: "7px",
  //   [theme.breakpoints.down("sm")]: {
  //     width: "70%",
  //   },
  // },
}));

const Register = () => {
  const classes = useStyles();

  return (
    <div className={classes.register}>
      <div className={classes.registerHeroText}>
        <Typography
          style={{
            color: "#343538",
            textAlign: "center",
            fontWeight: "lighter",
          }}
          variant="h4"
        >
          Join the movement - work with <span>RipeOnTime</span>.
        </Typography>
        
        <br />
      </div>
      <br />
      <div className="registerSections">
      <a href="/contact_us">
        <div className="registerSection registerSectionOne">
        <div className="registerSectiontBlur"></div>
          <div className="registerSectionText">
            <Typography
            className="registerSectionText-Heading"
              style={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              Become An Agent
              <hr style={{ width:"100px", margin:"10px auto", border:"1px solid #f3921d",}}/>
            </Typography>
            
            <Typography
              className="registerSectionText-Para"
              variant="h6"
            >
              Join <span>RipeOnTime</span> as a agent and help us deliver fresh
              groceries to our customers <br/>
              <small style={{fontSize:"18px", fontWeight:"500"}}><mark style={{backgroundColor:"#2b2b2b",color:"#fff"}}>&nbsp; Click Now!! &nbsp;</mark></small>
            </Typography>
            
            {/* <br /> */}
            {/* <Typography
              style={{
                color: "#343538",
                fontSize: "18px",
              }}
              variant="p"
            >
              lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              dolor sit ametlorem ipsum dolor sit amet lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet
            </Typography> */}
            {/* <br />
            <br />
             */}
              {/* <Button
              className="registerSectionButton"
                variant="contained"
                style={{ backgroundColor: "#f5a623", color: "#f4f4f4" }}
                disableElevation
              >
                Join RipeOnTime
              </Button> */}
            
          </div>
          
          {/* <div className={classes.registerSection1Right}></div> */}
        </div>
        </a>

        {/* Section 2 */}
        <a href="/contact_us">
        <div className="registerSection registerSectionTwo">
        <div className="registerSectiontBlur"></div>
          <div className="registerSectionText">
            <Typography
            className="registerSectionText-Heading"
              style={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              Become a Partner
              <hr style={{ width:"100px", margin:"10px auto", border:"1px solid #f3921d",}}/>
            </Typography>
            
            <Typography
            className="registerSectionText-Para"
              variant="h6"
            >
              Interested in taking your sales to the next level? Partner with{" "}
              <span>RipeOnTime</span> and have your store listed on{" "}
              <span>RipeOnTime</span>
              <br/>
              <small style={{fontSize:"18px", fontWeight:"500"}}><mark style={{backgroundColor:"#f3921d",color:"#fff"}}>&nbsp; Click Now!! &nbsp;</mark></small>
            </Typography>
            {/* <br /> */}
            {/* <Typography
              style={{
                color: "#343538",
                fontSize: "18px",
              }}
              variant="p"
            >
              lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              dolor sit ametlorem ipsum dolor sit amet lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet
            </Typography> */}
            {/* <br />
            <br />
             */}
              {/* <Button
              className="registerSectionButton"
                variant="contained"
                style={{ backgroundColor: "#f5a623", color: "#f4f4f4" }}
                disableElevation
              >
                Become a Partner
              </Button> */}
            
          </div>
          
          {/* <div className={classes.registerSection1Right}></div> */}
        </div>
        </a>
      </div>
    </div>
  );
};

export default Register;
// OlD SECTION 2
{/* <div className={classes.registerSection2}>
          <div className={classes.registerSection2Left}></div>
          <div className={classes.registerSection2Right}>
            <Typography
              style={{
                color: "#343538",
              }}
              variant="h4"
            >
              Become a Partner
            </Typography>
            <Typography
              style={{
                color: "#343538",
                fontWeight: "lighter",
              }}
              variant="h6"
            >
              Interested in taking your sales to the next level? Partner with{" "}
              <span>RipeOnTime</span> and have your store listed on{" "}
              <span>RipeOnTime</span>
            </Typography>
            {/* <br />
            <Typography
              style={{
                color: "#343538",
                fontSize: "18px",
              }}
              variant="p"
            >
              lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              dolor sit ametlorem ipsum dolor sit amet lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet
            </Typography> */}
        //     <br />
        //     <br />
        //     <a href="/contact_us">
        //       <Button
        //         variant="contained"
        //         style={{ backgroundColor: "#f5a623", color: "#f4f4f4" }}
        //         disableElevation
        //       >
        //         Become a Partner
        //       </Button>
        //     </a>
        //   </div>
        // </div> */}
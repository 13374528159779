import React from "react";
import useSettings from "../useSettings";
import { Card, CardHead } from "../styledComponents/cardStyles";

export default function PrivacyPolicy() {
  const settings = useSettings(true);
  console.log(settings);
  return (
    <div className="main-content">
      <br />
      <CardHead>Privacy Policy</CardHead>
      <Card>
        <div
          dangerouslySetInnerHTML={{ __html: settings.privacy_policy }}
        ></div>
      </Card>
      <br />
    </div>
  );
}

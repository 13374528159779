import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 3px;
  border: none;
  color: white;

  &.reportsTable th:first-child {
    text-align: left !important;
  }
  &.reportsTable td:first-child {
    text-align: left !important;
  }
  &.reportsTable th:last-child {
    text-align: right !important;
  }
  &.reportsTable td:last-child {
    text-align: right !important;
  }
`;

export const TableHead = styled.thead`
  display: table-header-group;
  border-collapse: separate;
`;

export const TableHeader = styled.th`
  top: 0;
  left: 0;
  z-index: auto;
  position: sticky;
  text-transform: uppercase;
  background: #fff; /*light mode*/
  color: #000; /*light mode*/
  /* background: #222529;
    color: #fff; */
  font-weight: 600;
  line-height: 1.5rem;
  display: table-cell;
  padding: 14px;
  font-size: 0.875rem;
  border-bottom: 1px solid #ebecf0;
  text-align: left;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
`;

export const TableBody = styled.tbody`
  display: table-row-group;
  background: #fff;
  color: #333;
`;

export const TableRow = styled.tr`
  outline: 0;

  div.MuiAvatar-colorDefault {
    text-transform: uppercase;
  }
  &:nth-of-type(even) {
    background-color: #f8f8f8; /*light mode*/
    /* background-color:#37383d;
            color:#f5f5f5; */
    div.MuiAvatar-colorDefault {
      background: #e688a2;
      color: #fff;
    }
  }

  &:nth-of-type(odd) {
    background-color: #fff; /*light mode*/
    /* background-color:#222529;
           color:#f5f5f5; */

    div.MuiAvatar-colorDefault {
      background: #6fa6e6;
      color: #fff;
    }
  }

  &.bordered {
    background: #fff;

    td {
      border-bottom: 1px solid #e2e2e2;
    }
  }
`;
export const TableRowData = styled.td`
  display: table-cell;
  padding: 14px;
  font-size: 0.895rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 0px;
  /*border-top: 1px solid #f8f8f8;  /*light mode*/
  /* border-top: 1px solid #444; */
  letter-spacing: 0.01071em;
  vertical-align: middle;

  &.created {
    color: #a2a2a2;
  }
  img {
    width: 60px;

    &.MuiAvatar-img {
      width: 45px;
    }

    &.rounded {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 1px -1px,
        rgba(0, 0, 0, 0.08) 0px 1px 1px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
    }
    &.thumbnail {
      border: 1px solid #f2f2f2;
      padding: 3px;
      width: 50px;
    }
  }
  a {
    font-family: LatoWebSemibold;
  }
`;

import React, { useState, useEffect } from "react";
import {
  ApiLoginService,
  ApiService,
  ImgUrl,
  addDefaultLogo,
} from "../services";
import GButton from "../gComponents/gButton";
import GoogleIcon from "../assets/images/google.png";
import { useSnackbar } from "react-simple-snackbar";
import { GoogleLogin } from "react-google-login";

const GoogleSocialLogin = () => {
  const [openSnackbar] = useSnackbar();

  function responseGoogle(response) {
    console.log(response);
    var profile = response.profileObj;
    // console.log("ID: " + profile.getId()); // Don't send this directly to your server!
    // console.log('Full Name: ' + profile.getName());
    // console.log('Given Name: ' + profile.getGivenName());
    // console.log('Family Name: ' + profile.getFamilyName());
    // console.log("Image URL: " + profile.getImageUrl());
    // console.log("Email: " + profile.getEmail());
    // The ID token you need to pass to your backend:

    var id_token = response.accessToken;
    console.log("ID Token: " + id_token);

    // var google_login_oauth = {
    //   username: profile.name,
    //   email: profile.email,
    //   grant_type: "google",
    //   token: id_token,
    //   client_id: "2",
    //   client_secret: "kXH06FUVAJf-oLzFQsXFPMfo",
    //   provider: "customers",
    // };

    var google_login_oauth = {
      username: profile.name,
      grant_type: "google",
      token: id_token,
      client_id: "2",
      // client_id: "284158232232-0cit561cumfkct79pnf1dd5hsbms5fq6.apps.googleusercontent.com",
      // client_secret: "kXH06FUVAJf-oLzFQsXFPMfo",
      client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
      provider: "customers",
    };
    g_login_social(google_login_oauth, id_token);
  }

  const g_login_social = (outhData, GToken) => {
    console.log(GToken);

    ApiLoginService("POST", "c/social-login", {
      method: "google",
      token: GToken,
      
    })
      .then(function (response) {
        console.log(response);
        if (response.status_code === 1) {
          getGOauthToken(outhData, GToken);
          // fetch("https://api.grofynow.com/oauth/token", {
          //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
          //   mode: 'cors', // no-cors, *cors, same-origin
          //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          //   credentials: 'same-origin', // include, *same-origin, omit
          //   headers: {
          //     'Content-Type': 'application/json',
          //     Authorization: "Bearer " + response.accessToken,
          //     // 'Content-Type': 'application/x-www-form-urlencoded',
          //   },
          //   redirect: 'follow', // manual, *follow, error
          //   referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          //   body: JSON.stringify(outhData) // body data type must match "Content-Type" header
          // });
        } else {
          openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

    // async function postData(url = '', data = {}) {
    //   // Default options are marked with *
    //   const response = await fetch(url, {
    //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     mode: 'cors', // no-cors, *cors, same-origin
    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     credentials: 'same-origin', // include, *same-origin, omit
    //     headers: {
    //       'Content-Type': 'application/json'
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //     redirect: 'follow', // manual, *follow, error
    //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //     body: JSON.stringify() // body data type must match "Content-Type" header
    //   });
    //   return response.json(); // parses JSON response into native JavaScript objects
    // }

  const getGOauthToken = (oauthData) => {
    ApiLoginService("POST", "oauth/token", oauthData)
      .then(function (response) {
        console.log(response);
        if (response) {
          localStorage.setItem("accessToken", response.access_token);
          localStorage.setItem("refreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("expires_in", now + response.expires_in);

          // window.location.reload(false);
        } else {
          console.log(response.data);
          // openSnackbar(response.message);
          return;
        }
      }).then(() => {window.location = "/"})
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    // <GoogleLogin
    //   clientId={
    //     "284158232232-viadnu0dq7oe4pka882u1rsm97dd4f6f.apps.googleusercontent.com"
    //   }
    //   onSuccess={responseGoogle}
    //   onFailure={responseGoogle}
    //   icon={false}
    //   className="googleLoginBtn"
    // >
    //   <img src={GoogleIcon} style={{ width: "40px" }} />
    // </GoogleLogin>
    <GoogleLogin
      clientId={
        "312652227574-q6mcchkgikt8o46dpmenk8qcq8lj96r0.apps.googleusercontent.com"
      }
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      icon={false}
      className="googleLoginBtn"
      cookiePolicy="single_host_origin"
    >
      <img src={GoogleIcon} style={{ width: "40px" }} />
    </GoogleLogin>

    // <GButton
    //   variant="linkable"
    //   style={{
    //     margin: "15px",
    //     width: "95%",
    //   }}
    //   onClick={() => {
    //     getGoogleLogin();
    //   }}
    //   type="button"
    // >
    //   <img src={GoogleIcon} style={{ width: "40px" }} />
    // </GButton>
  );
};

export default GoogleSocialLogin;

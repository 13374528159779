import React, { useState, useEffect } from "react";
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@material-ui/core'
import img1 from '../assets/images/bannerBlackFriday.jpg'
import img2 from '../assets/images/productBanner.jpg'
import img3 from '../assets/images/bannerFlashSale.jpg'
import { ApiLoginService } from "../services";


function StoreCarousel (props){
    // const [storeData, setStoreData] = useState(props.data);
    const [data,setData]=useState([]);
    const [storeData, setStoreData] = useState(props.data);
    const [bannerClicked, setBannerClicked] = useState({});

useEffect(() => {
    const url="c/offer-banners?identifier=web"
    ApiLoginService("GET", url)
    .then((response) => {
//   console.log("photoooo====>" + response.data);
  if (response.status_code === 1) {
    setData(response.data)
  } else {
    console.log(response.data);
  }
})
.catch((error) => {
  console.log(error);
});
    
},[])

// const handleClick = (selectedBanner) =>{
//   if (storeData.store_status === 0) {
//     return;
//   }
//   storeData.filter((s)=>{
//     var check = s.id===selectedBanner.id
//     console.log("banner=====>", check)
//   })
//   props.onSelectStore(storeData);
// }

const handleClick = (selectedBanner) =>{
  console.log("banner====>", selectedBanner)
  if (selectedBanner.store_id===""){
    return;
  } else{
    var store = props.data.filter((s)=> s.id==selectedBanner.store_id)[0]
    console.log("banner check ====>", store)
    props.onSelectStore(store);
  }
}

// const goToCategories = (selectedStore) => {
//     // console.log("photoooo 3====>", storeData.filter((s)=>(s.id===selectedStore.store_id)))
//     props.props.history.push({
//       // pathname: "/store/" + selectedStore.id + "/categories",
//       pathname: "/store/" + selectedStore.store_id + "/products",
//       state: { store: storeData },
//     });
//   };
  console.log("photoooo storeData ====>", props.data)
  console.log("photoooo storeData.id ====>", storeData.id)
  console.log("photoooo data.store_id ====>", data)
//   console.log("photoooo dataaa test 2 ====>", data.store_id===storeData.id?storeData:undefined)

    // var items = [
    //     {
    //         img: img1
            
    //     },
    //     {
    //         img: img2
            
    //     },
    //     {
    //         img: img3
            
    //     }
    // ]
   
    return (
      <>
      {console.log("banner click", bannerClicked)}
        <div style={{width:"1200px",height:"300px",margin:"0 auto"}}>
        <Carousel animation={"slide"} transitionTime={6000} navButtonsAlwaysVisible={true} fullHeightHover={false}>
            {
                data.map( (item, i) => <Item key={i} item={item} itemClicked={handleClick} /> )
            }
        </Carousel>
        </div>
        </>
    )
}

const Item = (props) => {
    // console.log("photoo=====|>",props.item.store_id)
    // console.log("photoo=====|>",props.item.photo)

    // Paswte below line in a tag
    
    
  return (
    <Paper style={{height:"300px", width:"100%", borderRadius:"10px",backgroundColor:"transparent"}}>
        <a onClick={() => props.itemClicked(props.item)}>
            <img src={process.env.REACT_APP_IMAGE_URL+"/"+"banners"+"/"+ props.item.photo} alt="banner" style={{height:"100%", width:"100%", objectFit:"fill", borderRadius:"10px"}} />
        </a>

    </Paper>
)
  
}

export default StoreCarousel

import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import useSettings from "../useSettings";
import { Card, CardHead } from "../styledComponents/cardStyles";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";

const Div = styled.div`
.right-sub-section{
  @media (min-width: 600px) {
  border-left:1px solid #f2f2f2;
  }
  @media (max-width: 599px) {
    border-top:1px solid #f2f2f2;
   }
}
`;
const InviteDiv = styled.div`
margin:10px;
padding:0 10px;
@media (max-width: 599px) {
  margin:0;padding:10px 0;
 }
img{
  width:18px;height:17px;padding-right:15px;vertical-align:top;padding-top:5px;
}
h4{
  margin:0 0 10px;
  font-size:16px;text-transform:uppercase
}
p{
  margin:5px 0;
  font-size:15px;
  line-height:23px;
  color:#333
}
hr{
  border:0;border-bottom:1px solid #f2f2f2;margin:15px 0;
}
h3{
    border: 1px dashed #a2a2a2;
    margin: 5px 0;
    padding: 8px 25px 7px;
    color: #333;
    font-size: 17px;
    line-height: 28px;display:inline-block;
    min-width: 150px;
    text-align: center;
    cursor: pointer;
}

  `;

export default function Invite() {
  const settings = useSettings(true);

  const [openSnackbar] = useSnackbar();
  console.log(settings);

  useEffect(() => {

  }, []);

  const handleCopy = (c) => {
    const el = document.createElement("textarea");
    el.value = settings.user.referral_code;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    openSnackbar("Copied : " + el.value);
  };

  return (
    <Div>
      <CardHead style={{ margin: '20px 10px 15px' }}>Invite & Earn</CardHead>
      <Card>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={4} xs={12} style={{ verticalAlign: 'top' }}>
            <h5 style={{ color: '#777', margin: '10px 0', fontSize: '15px' }}>Available Points</h5>
            {/* <p></p> */}
            {settings?.user?.wallet ? <h4 style={{ margin: '10px 0', fontSize: '20px' }}>{settings.user.wallet}</h4> :

              <p>You have 0 wallet points</p>
            }
          </Grid>
          <Grid item lg={9} sm={8} xs={12} className="right-sub-section">

            <InviteDiv>
              <h4>Earn wallet points</h4>
              <p>Invite friends & earn points when they sign up using your referral code.</p>
              <hr />
              <p>Share your referral code</p>
              <h3 onClick={() => handleCopy()}>{settings.user.referral_code}</h3>
            </InviteDiv>

          </Grid>
        </Grid>

      </Card>
      <br />
    </Div>
  );
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CouponImg from "../assets/images/coupon.png";
import ApplyCouponPopup from "./couponPopup";
import GButton from "../gComponents/gButton";

import Modal from 'react-modal';
const CouponDiv = styled.div`
  padding: 20px 0;
  img {
    width: 40px;
    padding-right: 20px;
  }
  p {
    padding: 5px 0;
    color: #555;
    font-size: 14px;
  }

  button {
    float: right;
  }
`;

const customStyles = {
  content: {
    top: '0',
    left: 'auto',
    right: '0',
    bottom: '0',
    width: '450px'
    // transform: 'translate(0%, -50%)'
  }
}

export default function CheckoutOrderCoupon(props) {
  const [appliedCoupon, setAppliedCoupon] = useState('');
  useEffect(() => {
    setAppliedCoupon(props.selectedCoupon);
  }, [props]);
  return (
    <CouponDiv>
      <img src={CouponImg} alt="" />
      {!appliedCoupon || props.isCouponValid === false ? (
        <React.Fragment>
          <GButton
            variant="linkable"
            children="Apply"
            onClick={() => props.setCouponVisible(true)}
          />
          <div style={{ display: "inline-block", verticalAlign: "middle" }}>
            <h4>Coupon</h4>
            <p>Choose from the available offers</p>
          </div>
        </React.Fragment>
      ) : (
          <React.Fragment>
            <GButton
              variant="linkable"
              children="Remove"
              onClick={() => {
                setAppliedCoupon('');
                props.setSelectedCoupon('');
                props.setIsCouponValid(false)
              }}
            />
            <div style={{ display: "inline-block", verticalAlign: "middle" }}>
              <h4>{appliedCoupon}</h4>
              <p>Promo Code Added</p>
            </div>
          </React.Fragment>
        )}

      <Modal
        isOpen={props.couponVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => props.setCouponVisible(false)}
        style={customStyles}
        contentLabel="Coupon Modal"
      >
        <ApplyCouponPopup
          couponVisible={props.couponVisible}
          applyCoupon={true}
          setCouponVisible={props.setCouponVisible}
          storeId={props.storeId}
          onApplyCoupon={(coupon) => {
            setAppliedCoupon(coupon);
            props.setSelectedCoupon(coupon);
            props.setIsCouponValid(true)
          }}
        />
      </Modal>


    </CouponDiv>
  );
}

import styled from "styled-components";

export const Card = styled.div`
  background: #fff;
  padding: 25px;
  margin: 10px 0 20px;
  box-shadow: 0 0 10px #cccccc85;box-sizing:border-box;

  &:hover .MuiSvgIcon-root {
    display: inline-block;
  }
`;

export const CardHead = styled.h2`
  color: #000;
  font-size: 25px;
  margin: 20px 0 15px;
  padding: 0;
  font-weight: 500;
  & .MuiSvgIcon-root {
    float: right;
    color: #555;
    font-size: 1.2rem;
    display: none;
  }
`;

export const CardBody = styled.p`
  color: #333;
  font-size: 15px;
  margin: 0px;
  line-height: 20px;
`;

import React, { useState } from "react";
import { ArrowBackIos } from "@material-ui/icons"
import { ApiLoginService, ImgUrl, addDefaultLogo } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import VerifyOtpForm from "./verifyOtp";
import NewPassForm from "./resetPassword"

import Modal from 'react-modal';
import { CalendarContainer } from "react-datepicker";

export default function ForgotPassword(props) {
  const [verifyOtpvisible, setVerifyOtpvisible] = useState(false);
  const [npvisible, setNpvisible] = useState(false);
  const [forgotData, setForgotData] = useState({});
  const [isBtnLoading, setBtnLoading] = useState(false)
  const [openSnackbar] = useSnackbar();
  // const location = useLocation();


  const customStyles = {
    content: {
      width: '350px', top: '0%',
      left: '0%',
      right: '0',
      bottom: '0',
      margin: 'auto', height: '270px'
      // transform: 'translate(0%, -50%)'
    }
  };


  const initialValues = {
    username: ""
  };

  const onSubmit = (values) => {
    setBtnLoading(true)
    if (props.data) {
      delete values.password;
    }
    console.log(values);

    ApiLoginService("POST", "c/forgot-otp", values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        setForgotData(values);
        setVerifyOtpvisible(true)
        openSnackbar(response.message);
        setBtnLoading(false)
      } else {
        openSnackbar(response.message);
        setBtnLoading(false)
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
  });



  const onHandleVerify = (otp) => {
    setForgotData({ ...forgotData, otp: otp })
    console.log(forgotData)
    setNpvisible(true)
  }
  const onHandleChange = () => {
    props.setFpvisible(false);
    props.setSigninvisible(true);
  }

  if (props.fpvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;<ArrowBackIos className="backIcon" onClick={() => props.setFpvisible(false)} />Forgot Password
            </div>
      <img src={ImgUrl('logo') + "/" + props.settings?.email_logo} className="header-logo" alt={props.settings?.project_name} onError={addDefaultLogo} style={{ margin: '10px 0 0 30px' }} />

      <div className="modal-body">
        <h2 style={{ margin: '10px 15px 10px', fontFamily: 'LatoWebSemibold', fontSize: '21px' }}>Forgot Password</h2>
        <p style={{ margin: '0 15px 20px' }}>Please enter your Email ID or Phone to reset password</p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Email / Phone"
                name="username"
              // className="noBorder"
              />

              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: 'calc(100% - 30px)',
                }}
                children="Reset Password"
                type="submit"
                loading={isBtnLoading}
              />
              <br />



            </Form>
          )}
        </Formik>

      </div>

      <Modal
        isOpen={verifyOtpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVerifyOtpvisible(false)}
        style={customStyles}
        contentLabel="Verify OTP Modal"
      >
        <VerifyOtpForm verifyOtpvisible={verifyOtpvisible} setVerifyOtpvisible={setVerifyOtpvisible} data={forgotData} onVerify={(otp) => onHandleVerify(otp)} />
      </Modal>

      <Modal
        isOpen={npvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setNpvisible(false)}
        style={{ content: { width: '400px' } }}
        contentLabel="Example Modal"
      >
        <NewPassForm npvisible={npvisible} setNpvisible={setNpvisible} data={forgotData} onChange={() => onHandleChange()} />
      </Modal>

    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import { ApiLoginService, ImgUrl, addDefaultSrc } from "../services";
// import "../css/Category.css";
import { StyleSheet, Text, View } from "react-native";
import GShimmer from "../gComponents/gShimmer";
import { useLocation } from "react-router-dom";
import Products from "./products";
import styled from "styled-components";
import ProductsMobile from "./products-mobile";
import GInfo from "../gComponents/gInfo";
import { Card } from "../styledComponents/cardStyles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SaleProduct from "./SaleProduct";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const P = styled.p`
  padding: 10px 0;
  &.active {
    color: ${({ theme }) => theme.button};
  }
`;

const CategoryProductNavMob = (props) => {
  
  const location = useLocation();
  console.log("location data" + JSON.stringify(props.match));
  console.log("location data" + JSON.stringify(props.location));
  const [storeData, setStoreData] = useState(
    props.location?.state?.store || {}
  );
  const [category, setCategory] = useState(props.location?.state?.data || []);
  const [isLoading, setIsLoading] = useState(
    !props.location?.state?.data || props.location?.state?.data.length === 0
      ? true
      : false
  );
  const [selectedCategory, setSelectedCategory] = useState(
    props.location?.state?.catId
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    props.location?.state?.subCat
  );
  const [divDisplay, setDivDisplay] = useState(false);
  const [display, setDisplay] = useState(true);
  const [divClicked, setDivClicked] = useState(null);
  const [sale, setSale] = useState(true)
  const [saleData, setSaleData]=useState([])

  useEffect(() => {
 
    const getSaleData = () => {
      ApiLoginService("GET", "c/products?sale=1&store_id=" + storeData?.id)
        .then((response) => {
          setSaleData(response.data.data)
          
          console.log("sale####", response.data.data);
          if (response.status_code === 1) {
            setIsLoading(false);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getSaleData();
  }, [storeData]);
  
  useEffect(() => {
    const getCategories = () => {
      ApiLoginService("GET", "c/stores/" + props.match.params.id)
        .then((response) => {
          console.log("gh" + JSON.stringify(response.data));
          if (response.status_code === 1) {
            setStoreData(response.data);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getCategories();
  }, [props.match.params]);

  useEffect(() => {
    if (props.location?.state?.data) {
      console.log(props.location?.state?.data);
      setIsLoading(false);
      return;
    }

    if (!storeData?.id) {
      return;
    }
    const getCategories = () => {
      ApiLoginService("GET", "c/product/group?store_id=" + storeData?.id)
        .then((response) => {
          console.log("gh" + JSON.stringify(response.data));
          if (response.status_code === 1) {
            setCategory(response.data);
            setSelectedCategory(response.data[0].id);
            setSelectedSubCategory(response.data[0].subcategories[0]);

            setIsLoading(false);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getCategories();
  }, [storeData]);


 

  return (
    <div className="categoryMobile" style={{ padding: "20px 0" }}>
     

      <div style={{ flex: "1", display: "flex" }}>
        
          <div
            className="categoryProductNav"
            style={{
              width: "280px",
              height: "auto",
              minHeight: "max-content",
              background:
                "linear-gradient(0deg, rgba(254,226,155,1) 0%, rgba(242,232,207,1) 100%)",
              overflowY: "scroll",
              display: "inline-block",
              boxSizing: "border-box",
              padding: "20px 30px",
            }}>
            <span
            style={{display: saleData.length!==0?"flex":"none",
             flexWrap: "nowrap",
             alignItems: "center",
              justifyContent: "space-between",
             }}>
            <h3
              style={{
                fontSize: "17px",
                color: "#777",
                padding: "10px 0",
                width: "70%",
                cursor:"pointer",
                fontWeight:"600"
              }}
              onClick={()=>setSale(true)}>
              See Sale Products 
            </h3>
            <ArrowForwardIosIcon/>
            </span>
            {(!category || category.length === 0) && isLoading === true ? (
              <GShimmer></GShimmer>
            ) : (
              category.map((categories, index) => {
                return (
                  <div
                    style={{
                      borderBottom: "1px solid #e2e2e2",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    id={index}
                    key={index}
                    onClick={(e) => {
                      setSelectedCategory(categories.id);
                      setDivClicked(index);
                      
                      // handleToggle(index,e);
                    }}>
                    <span
                      key={index}
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <h4
                        style={{
                          fontSize: "17px",
                          color: "#777",
                          padding: "10px 0",
                          width: "70%",
                        }}>
                        {categories.title}
                      </h4>
                      {divClicked === index ? <RemoveIcon /> : <AddIcon />}
                      
                    </span>
                    <div
                      style={
                        divClicked === index
                          ? { display: "block", padding: "0 10px" }
                          : { display: "none" }
                      }>
                      {categories.subcategories.map((sub, i) => {
                        return (
                          <React.Fragment key={i}>
                            {selectedCategory &&
                            selectedCategory === parseInt(sub.parent_id) ? (
                              <P
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedSubCategory(sub);
                                  setSale(false)
                                }}
                                className={
                                  sub.id === selectedSubCategory?.id
                                    ? "active"
                                    : ""
                                }>
                                {sub.title}
                              </P>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            )}
          </div>
       
        
      </div>
    </div>
  );
};

export default CategoryProductNavMob

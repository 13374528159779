import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import styled, { ThemeConsumer } from "styled-components";
import GButton from "./gButton";
import { Edit, Delete, Settings } from "@material-ui/icons";
import GConfirmDialog from "./gConfirmDialog";
import GActions from "./gActionsStyle";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import Uncheck from "../assets/images/untick.png";
import { object } from "yup/lib/locale";

const ListCard = styled.div`
  padding: 0.9em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  background: #fff;
  vertical-align: top;
  margin: 10px;
  height: auto;
  overflow: overlay;
  /*  
  &:hover{
    color:rgba(0, 0, 0, 0.26);
  }  */

  label {
    display: flex;

    img {
      margin-top: 5px;
    }
  }

  span svg {
    width: 25px;
    height: 25px;
    margin-top: 5px;
    path {
      fill: ${({ theme }) => theme.button};
    }
  }

  h4 {
    margin: 0px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    width: 70%;
    text-transform: uppercase;
  }
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #777;
    font-size: 0.9rem;
    padding: 5px 0;
    height: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
  }
  p {
    margin: 0px;
    font-weight: 400;
    display: inline-block;
    width: 50%;
    font-size: 0.95rem;
    color: #818183;
  }
`;

function GAddressList(props) {
  const [addressData, setAddressData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  useEffect(() => {
    setAddressData(props.addressData);
  }, [props]);

  const handleDelete = (e) => {
    //setValue(e.target.value);
    console.log(selectedAddressId);
    props.onDelete(selectedAddressId);
  };
  const handleDefault = () => {
    props.onSelectDefault(selectedAddressId);
  };

  return (
    <React.Fragment>
      <ListCard
        style={Object.assign(
          {},
          {
            borderBottom:
              props.orderAdress === true ? "1px solid #f2f2f2" : "0px",
            padding:
              props.orderAdress === true || props.default === true
                ? "0.9rem"
                : "0px",
          },
          props.style
        )}
      >
        {props.default === true ? (
          <div
            className="radio"
            style={{
              cursor: "pointer",
              // display: "inline-block",
              // width: "35px",
              verticalAlign: "top",
            }}
          >
            <label>
              <input
                type="radio"
                value="cash"
                checked={addressData.default === 1}
                style={{
                  cursor: "pointer",
                  height: "17px",
                  width: "17px",
                  display: "none",
                }}
              />

              {addressData.default === 1 ? (
                <span className="active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setSelectedAddressId(addressData.id);
                    setDefaultDialogOpen(true);
                  }}
                >
                  <img src={Uncheck} style={{ width: "25px" }} alt="" />
                </span>
              )}

              <div
                onClick={() => {
                  setSelectedAddressId(addressData.id);
                  setDefaultDialogOpen(true);
                }}
                style={{
                  marginLeft: "20px",
                  display:
                    props.default || props.orderAdress
                      ? "inline-block"
                      : "block",
                  width:
                    props.default || props.orderAdress
                      ? "calc(100% - 90px)"
                      : "100%",
                }}
              >
                <h4>{addressData.title}</h4>
                <h5
                  style={{ height: props.default === true ? "80px" : "auto" }}
                >
                  {addressData.formatted}{" "}
                </h5>
              </div>
              <div
                style={{
                  color: "#555",
                  fontWeight: "400",
                  display: "inline-block",
                  width: "40px",
                  minHeight: props.default ? "75px" : "auto",
                }}
              >
                &nbsp;&nbsp;
                {props.dropdownActions === true ? (
                  <GActions
                    onEdit={() => props.onEdit(addressData)}
                    onDelete={() => {
                      setSelectedAddressId(addressData.id);
                      setDialogOpen(true);
                    }}
                    style={{ float: "right", marginTop: "22px" }}
                  />
                ) : (
                  ""
                )}
              </div>
            </label>
          </div>
        ) : (
          ""
        )}

        {props.orderAdress === true ? (
          <div
            className="radio"
            style={{
              cursor: "pointer",
              // display: "inline-block",
              // width: "35px",
              verticalAlign: "top",
            }}
          >
            <label>
              <input
                type="radio"
                value={addressData.id}
                checked={addressData.id === props.defaultAddress?.id}
                style={{
                  cursor: "pointer",
                  height: "17px",
                  width: "17px",
                  display: "none",
                }}
              />
              {addressData.id === props.defaultAddress?.id ? (
                <span className="active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span
                  onClick={() => {
                    props.onSelectOrderAddress(addressData);
                  }}
                >
                  <img src={Uncheck} style={{ width: "25px" }} alt="" />
                </span>
              )}

              <div
                onClick={() => {
                  props.onSelectOrderAddress(addressData);
                }}
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                  display:
                    props.default || props.orderAdress
                      ? "inline-block"
                      : "block",
                  width:
                    props.default || props.orderAdress
                      ? "calc(100% - 90px)"
                      : "100%",
                }}
              >
                <h4>{addressData.title}</h4>
                <h5
                  style={{ height: props.default === true ? "80px" : "auto" }}
                >
                  {addressData.formatted}{" "}
                </h5>
              </div>
              <div
                style={{
                  color: "#555",
                  fontWeight: "400",
                  display: "inline-block",
                  width: "40px",
                  minHeight: props.default ? "75px" : "auto",
                }}
              >
                &nbsp;&nbsp;
                {props.dropdownActions === true ? (
                  <GActions
                    onEdit={() => props.onEdit(addressData)}
                    onDelete={() => {
                      setSelectedAddressId(addressData.id);
                      setDialogOpen(true);
                    }}
                    style={{ float: "right", marginTop: "22px" }}
                  />
                ) : (
                  ""
                )}
              </div>

              {/* {props.actions === true ? (
                  <div style={{ float: "right" }}>
                    <GButton
                      variant="linkable"
                      onClick={() => {
                        props.onEdit(addressData);
                      }}
                    >
                      <Edit fontSize="small" />
                    </GButton>
                    <GButton
                      variant="linkable"
                      onClick={() => {
                        setSelectedAddressId(addressData.id);
                        setDialogOpen(true);
                      }}
                    >
                      <Delete fontSize="small" />
                    </GButton>
                  </div>
                ) : (
                    ""
                  )} */}
            </label>
          </div>
        ) : (
          ""
        )}
      </ListCard>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete"
          text="Are you sure you want to delete?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDelete();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {defaultDialogOpen === true ? (
        <GConfirmDialog
          open={defaultDialogOpen}
          title="Set Default Address"
          text="Are you sure you want to set this as a default address?"
          handleClose={() => setDefaultDialogOpen(false)}
          onConfirmClose={() => {
            setDefaultDialogOpen(false);
            handleDefault();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default GAddressList;

import React, { useState, useEffect, useRef } from "react";
import { ApiLoginService } from "../services";
import styled from "styled-components";
import { Search, Close, KeyboardArrowDown, Room } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import marker from "../assets/images/pickup_location.png";

const LI = styled.li`
  padding: 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #f4f5f9;
  }
`;
const Input = styled.input`
  border: 0;
  outline: none;
  width: 90%;
`;
// const Input = styled.input`
//   width: 82%;
//   /* min-width: 350px; */
//   max-width: 450px;
//   margin: 5px 0 0;
//   height: 30px;
//   background: transparent;
//   border: 0px;
//   outline: 0;
//   box-shadow: none;
//   color: #777;
//   padding-left: 10px;
// `;
export const LocationSearch = ({
  value,
  handleAreaChange,
  handleLocationChange,
  handleBlur,
  handleFocus,
  placeholder,
  style,
}) => {
  const [searchWord, setSearchWord] = useState("");
  const [address, setAddress] = useState("");
  const wrapperSRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [cityList, setCities] = useState([]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    console.log(results[0].geometry.location);
    setAddress(value);
    let location = {
      lat: results[0].geometry.location.lat(),
      lng: results[0].geometry.location.lng(),
      address: value,
    };
    console.log("location ==",location);
    handleLocationChange(location);
    // setOfVisible(false);
  };
  useEffect(() => {
    var hitUrl = "c/settings";
    ApiLoginService("GET", hitUrl)
       .then((response) => {
        console.log("gh ==>" + response.data.default_location);
        console.log("gh ==>" + JSON.parse(response.data.default_location).title);
        if (response.status_code === 1) {
          console.log("Default Data ==>", response.data)
          // setCities(response.data);
          // response.data.map((values) => {
          //   values.geofences.map((g, i) => {
          //     if (g.id === value) {
          //       setSelectedTitle(values.title);
          //       // console.log("title ===> ",values.title )
          //     }
          //   });
          // });
          //setSelectedValue(value);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchWord, value]);

  useEffect(() => {
    var hitUrl = "c/cities?per_page=30&search=" + searchWord;
    ApiLoginService("GET", hitUrl)
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          setCities(response.data);
          response.data.map((values) => {
            values.geofences.map((g, i) => {
              if (g.id === value) {
                setSelectedTitle(values.title);
                // console.log("title ===> ",values.title )
              }
            });
          });
          //setSelectedValue(value);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchWord, value]);

  return (
    <div
      ref={wrapperSRef}
      style={Object.assign({}, style, {
        width: "calc(100% - 120px)",
        maxWidth: "450px",
        display: "inline-block",
        position: "relative",
        // verticalAlign: "middle",
      })}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid rgb(226, 226, 226)",
          height: "45px",
          fontSize: "0.97rem",
          textAlign: "left",
          color: "#555",
          boxSizing: "border-box",
          padding: "10px",
          fontFamily: "sans-serif",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          //setShowFilterList(true);
          setSearchWord("");
        }}
      >
        {selectedTitle ? (
          selectedTitle
        ) : (
          // <span style={{ color: "#555", verticalAlign: 'middle' }}>Choose Area </span>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ width: "100%", textAlign: "left" }}>
                <p
                  style={{
                    width: "100%",
                    //  border: "1px solid red",
                    // display: "flex",
                    // justifyContent: "space-between",
                    padding: "0px 5px",
                    alignItems: "center",
                    boxSizing: "border-box",
                    maxWidth: "100%",
                  }}
                >
                  <Input style={{width:"100%"}}
                    {...getInputProps({
                      placeholder: "Enter Zip Code or Address",
                    })}
                  />
                </p>

                {/* {loading ? "" : null} */}
                <div
                  style={{
                    position: "absolute",
                    zIndex: "999",
                    marginTop: "3px",
                    width:"200%",
                  }}
                >
                  {suggestions.map((suggestion) => {
                    const style = {
                      display: "flex",
                      backgroundColor: suggestion.active
                        ? "#e2e2e2"
                        : "#ffffff",
                      padding: "0px 5px",
                      marginRight: "10px",
                      cursor: "pointer",
                      color: "#2D2F33",
                      border: "1px solid #bbbbbb",
                      alignItems: "center",
                    };
                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        <img src={marker} alt="" />
                        <p style={{ marginLeft: "10px" }}>
                          {suggestion.description}
                        </p>
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
        {selectedTitle || address ? (
          <Close
            fontSize="small"
            style={{
              marginLeft: "auto",
              fontSize: "18px",
              color: "#777",
              cursor: "pointer",
            }}
            onClick={() => {
              handleAreaChange();
              setSelectedTitle();
              handleLocationChange({});
              setAddress();
            }}
          />
        ) : (
          ""
        )}
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            width: "100%",
            maxWidth: "450px",
            boxSizing: "border-box",
            padding: "0 5px 5px",
            border: "1px solid rgb(226, 226, 226)",
          }}
        >
          {/* <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment> */}
          <ul
            style={{
              listStyleType: "none",
              padding: "15px 10px",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              maxHeight: "220px",
              overflow: "auto",
              textAlign: "left",
            }}
          >
            {cityList.map((c, i) => {
              return (
                <li key={i} style={{ color: "#ccc", fontSize: "15px" }}>
                  {c.title}

                  <ul style={{ padding: "5px 0 10px", listStyleType: "none" }}>
                    {c.geofences.map((g, ind) => {
                      return (
                        <LI
                          key={ind}
                          onClick={() => {
                            setSelectedTitle(g.title);
                            setShowFilterList(false);
                            handleAreaChange(g.id);
                          }}
                        >
                          <Room
                            style={{
                              fontSize: "18px",
                              color: "#ccc",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                          {g.title}
                        </LI>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

// export default storeFilterComponent;

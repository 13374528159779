import React, { useState } from "react";
import { ArrowBackIos } from "@material-ui/icons";
import { ApiLoginService, ImgUrl, addDefaultLogo } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import VerifyOtpForm from "./verifyOtp";
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

import FBLogin from "../socialLogin/facebookLogin";
import GLogin from "../socialLogin/googleLogin";

import ReCAPTCHA from "react-google-recaptcha"; //Recaptcha

import Modal from "react-modal";

const customStyles = {
  content: {
    width: "350px",
    top: "0%",
    left: "0%",
    right: "0",
    bottom: "0",
    margin: "auto",
    height: "270px",
    // transform: 'translate(0%, -50%)'
  },
};

export default function Signup(props) {
  const [verifyOtpvisible, setVerifyOtpvisible] = useState(false);
  const [signupData, setSignupData] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [password, setPassword] = useState("");
  const [confirmpPassword, setConfirmPassword] = useState("");
  const [captchaChecked, setCaptchaChecked] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  // const location = useLocation();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    // confirm_password: "",
  };
  const recaptchaChange =(response)=>{
    if(response){
      setIsVerify(true);
      setCaptchaChecked("")
    }
  }

  const onSubmit = (values) => {
    // values.phone = "";
    
    if (confirmpPassword == password && isVerify) {
      setBtnLoading(true);
      setCaptchaChecked("")
      localStorage.setItem("values", values);
      if (props.data) {
        delete values.password;
      }
      console.log(values);

      ApiLoginService("POST", "c/signup/otp", values).then((response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          setSignupData(values);
          setVerifyOtpvisible(true);
          setBtnLoading(false);
          openSnackbar(response.message);
        } else {
          setBtnLoading(false);
          openSnackbar(response.message);
          return;
        }
      });
    } else if (confirmpPassword != password ) {
      alert('Confirm password doesn"t match the password');
      
    }else if (!isVerify){
      setCaptchaChecked("*Please Fill Captcha!!")
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid Email Address").required("Required"),
    // phone: Yup.string()
    //   .matches(
    //     /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
    //     "Invalid phone number"
    //   )
    //   .min(9, "Min length is 10")
    //   .max(14, "Too Long!")
    //   .required("Required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });

  const getOauthToken = () => {
    var refresh_token_data = {
      first_name: signupData.first_name,
      last_name: signupData.last_name,
      username: signupData.email,
      password: signupData.password,
      // password: signupData.password,

      grant_type: "password",
      client_id: 2,
      client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
      provider: "customers",
    };
    // kXH06FUVAJf-oLzFQsXFPMfo updated
    //f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4 old
    console.log(refresh_token_data);

    ApiLoginService("POST", "oauth/token", refresh_token_data)
      .then(function (response) {
        if (response) {
          console.log(response);
          localStorage.setItem("accessToken", response.access_token);
          localStorage.setItem("refreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("expires_in", now + response.expires_in);
          props.setSignupvisible(false);
          window.location.reload(false);
        } else {
          // openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onHandleVerify = () => {
    getOauthToken();
  };

  if (props.signupvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setSignupvisible(false)}
        />
        Signup
      </div>
      <img
        src={ImgUrl("logo") + "/" + props.settings?.web_header_logo}
        className="header-logo"
        alt={props.settings?.project_name}
        onError={addDefaultLogo}
        style={{ margin: "10px 0 0 30px" }}
      />
      <h2 style={{ marginBottom: "10px", fontFamily: "LatoWeb", display:"inline",position:"relative",top:"-20px" }}>
          &nbsp;&nbsp;Sign Up
        </h2>

      <div className="modal-body">
        
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => {
            return (
              <Form>
              
                <FormikControl
                className="Frmikmain"
                  control="input"
                  type="text"
                  placeholder="First name"
                  name="first_name"
                  // className="noBorder"
                />
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  // className="noBorder"
                />
                <FormikControl
                control="numberInput"
                type="text"
                placeholder="Phone"
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9.+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
                <FormikControl
                  control="input"
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  // className="noBorder"
                />
                <FormikControl
                  control="input"
                  type="password"
                  placeholder="Password"
                  name="password"
                  // required
                  // value={password}
                  // onChange={(e) => {
                  //   setPassword(e.target.value);
                  // }}
                />
                <FormikControl
                  control="input"
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                />
                <br />

                {/* {
                confirmpPassword === password ? ( <GButton
                  variant="condensed"
                  disabled={!isValid}
                  style={{
                    margin: "0 15px",
                    width: "calc(100% - 30px)",
                  }}
                  children="SIGN UP"
                  type="submit"
                  loading={isBtnLoading}
                />) : ( <GButton
                  variant="condensed"
                  disabled={true}
                  style={{
                    margin: "0 15px",
                    width: "calc(100% - 30px)",
                  }}
                  children="SIGN UP"
                  type="submit"
                  loading={isBtnLoading}
                />)
              } */}
                <ReCAPTCHA
                  sitekey="6LfOk4YdAAAAAHFhVqe6U90eQnLJUisuVIS0Xzch"
                  render="explicit"
                  onChange={recaptchaChange}
                />
                <div style={{ color: "red" }}>
                  <b>{captchaChecked}</b>
                </div><br/>

                <GButton
                  type="submit"
                  variant="condensed"
                  // disabled={!isValid}
                  style={{
                    margin: "0 15px",
                    width: "calc(100% - 30px)",
                  }}
                  children="SIGN UP"
                  // loading={isBtnLoading}
                />
              </Form>
            );
          }}
        </Formik>

        <GButton
          variant="linkable"
          style={{
            margin: "15px",
            width: "calc(100% - 30px)",
          }}
          children="Login to your Account"
          onClick={() => {
            props.setSigninvisible(true);
            props.setSignupvisible(false);
          }}
          type="button"
        />

        <div style={{ margin: "0 auto", textAlign: "center" }}>
          {/* <FBLogin /> */}
          <GLogin />
        </div>
      </div>

      <Modal
        isOpen={verifyOtpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVerifyOtpvisible(false)}
        style={customStyles}
        contentLabel="Verify OTP Modal"
        shouldCloseOnOverlayClick={false}
      > <CloseIcon style={{position:"relative",top:"-10px",right:"-40%",width:"100%"}} onClick={()=>setVerifyOtpvisible(false)}/>
        <VerifyOtpForm
          verifyOtpvisible={verifyOtpvisible}
          setVerifyOtpvisible={setVerifyOtpvisible}
          type="signup"
          data={signupData}
          onVerify={() => onHandleVerify()}
        />
      </Modal>
    </React.Fragment>
  );
}

// import React, { useState } from "react";
// import { ArrowBackIos } from "@material-ui/icons";
// import { ApiLoginService, ImgUrl, addDefaultLogo } from "../services";
// import { useSnackbar } from "react-simple-snackbar";
// import GButton from "../gComponents/gButton";
// import { Formik, Form } from "formik";
// import * as Yup from "yup";
// import FormikControl from "../formikComponents/formikControl";
// import VerifyOtpForm from "./verifyOtp";

// import Modal from "react-modal";

// const customStyles = {
//   content: {
//     width: "350px",
//     top: "0%",
//     left: "0%",
//     right: "0",
//     bottom: "0",
//     margin: "auto",
//     height: "270px",
//     // transform: 'translate(0%, -50%)'
//   },
// };

// export default function Signup(props) {
//   const [verifyOtpvisible, setVerifyOtpvisible] = useState(false);
//   const [signupData, setSignupData] = useState(false);
//   const [isBtnLoading, setBtnLoading] = useState(false);
//   const [openSnackbar] = useSnackbar();
//   // const location = useLocation();

//   const initialValues = {
//     email: "",
//     phone: "",
//     password: "",
//   };

//   const onSubmit = (values) => {
//     setBtnLoading(true);
//     if (props.data) {
//       delete values.password;
//     }
//     console.log(values);

//     ApiLoginService("POST", "c/signup/otp", values).then((response) => {
//       console.log("r" + response.status_code);
//       if (response.status_code === 1) {
//         setSignupData(values);
//         setVerifyOtpvisible(true);
//         setBtnLoading(false);
//         openSnackbar(response.message);
//       } else {
//         setBtnLoading(false);
//         openSnackbar(response.message);
//         return;
//       }
//     });
//   };

//   const validationSchema = Yup.object({
//     email: Yup.string().email("Invalid Email Address").required("Required"),
//     phone: Yup.string()
//       .matches(
//         /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
//         "Invalid phone number"
//       )
//       .min(9, "Min length is 10")
//       .max(14, "Too Long!")
//       .required("Required"),
//     password: Yup.string()
//       .required("Required")
//       .min(6, "Password is too short - should be 6 chars minimum"),
//   });

//   const getOauthToken = () => {
//     var refresh_token_data = {
//       username: signupData.email,
//       password: signupData.password,
//       grant_type: "password",
//       client_id: 2,
//       client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
//       provider: "customers",
//     };
//     console.log(refresh_token_data);

//     ApiLoginService("POST", "oauth/token", refresh_token_data)
//       .then(function (response) {
//         if (response) {
//           console.log(response);
//           localStorage.setItem("accessToken", response.access_token);
//           localStorage.setItem("refreshToken", response?.refresh_token);
//           let now = (Date.now() / 1000) | 0;
//           localStorage.setItem("expires_in", now + response.expires_in);
//           props.setSignupvisible(false);
//           window.location.reload(false);
//         } else {
//           // openSnackbar(response.message);
//           return;
//         }
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };
//   const onHandleVerify = () => {
//     getOauthToken();
//   };

//   if (props.signupvisible === false) {
//     return null;
//   }
//   return (
//     <React.Fragment>
//       <div className="modal-header">
//         &nbsp;&nbsp;
//         <ArrowBackIos
//           className="backIcon"
//           onClick={() => props.setSignupvisible(false)}
//         />
//         Signup
//       </div>
//       <img
//         src={ImgUrl("logo") + "/" + props.settings?.web_header_logo}
//         className="header-logo"
//         alt={props.settings?.project_name}
//         onError={addDefaultLogo}
//         style={{ margin: "10px 0 0 30px" }}
//       />

//       <div className="modal-body">
//         <h2 style={{ marginBottom: "10px", fontFamily: "LatoWeb" }}>
//           &nbsp;&nbsp;Sign Up
//         </h2>
//         <Formik
//           initialValues={initialValues}
//           onSubmit={onSubmit}
//           validationSchema={validationSchema}
//           validateOnBlur={false}
//         >
//           {({ values, isValid, setFieldValue }) => (
//             <Form>
//               <FormikControl
//                 control="input"
//                 type="email"
//                 label="Email ID"
//                 name="email"
//                 // className="noBorder"
//               />
//               <FormikControl
//                 control="numberInput"
//                 type="text"
//                 label="Phone"
//                 name="phone"
//                 onChange={(event) => {
//                   const re = /^[0-9.+\b]+$/;
//                   if (event.target.value && !re.test(event.target.value)) {
//                     return;
//                   } else {
//                     setFieldValue("phone", event.target.value);
//                   }
//                 }}
//               />
//               <FormikControl
//                 control="input"
//                 type="text"
//                 label="Password"
//                 name="password"
//               />
//               <br />
//               <GButton
//                 variant="condensed"
//                 disabled={!isValid}
//                 style={{
//                   margin: "0 15px",
//                   width: "calc(100% - 30px)",
//                 }}
//                 children="SIGN UP"
//                 type="submit"
//                 loading={isBtnLoading}
//               />
//             </Form>
//           )}
//         </Formik>

//         <GButton
//           variant="linkable"
//           style={{
//             margin: "15px",
//             width: "calc(100% - 30px)",
//           }}
//           children="Login to your Account"
//           onClick={() => {
//             props.setSigninvisible(true);
//             props.setSignupvisible(false);
//           }}
//           type="button"
//         />
//       </div>

//       <Modal
//         isOpen={verifyOtpvisible}
//         className="modal"
//         overlayClassName="modal-overlay"
//         onRequestClose={() => setVerifyOtpvisible(false)}
//         style={customStyles}
//         contentLabel="Verify OTP Modal"
//       >
//         <VerifyOtpForm
//           verifyOtpvisible={verifyOtpvisible}
//           setVerifyOtpvisible={setVerifyOtpvisible}
//           type="signup"
//           data={signupData}
//           onVerify={() => onHandleVerify()}
//         />
//       </Modal>
//     </React.Fragment>
//   );
// }

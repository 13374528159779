import React, { useState, useEffect } from "react";
import Header from "./components/header";
import GenaricForm from "./components/genaricForm";
import Footer from "./components/footer";
import Index from "./components/index";
import AboutUs from "./components/AboutUs";
import IndexContent from "./components/indexContent";
import Stores from "./components/stores";
import Categories from "./components/category";
import categoryProducts from "./components/categoryProducts";
import Checkout from "./components/checkout";
import PrivacyPolicy from "./components/privacyPolicy";
import Terms from "./components/terms&Conditions";
import Offers from "./components/offers";
import Faqs from "./components/faqs";
import Profile from "./components/profile";
import "./App.css";
import useSettings from "./useSettings";
import { Switch, Route, useLocation } from "react-router-dom";

//custom comps

import { initFacebookSdk } from "./socialLogin/facebookLoginSdk";

import { ThemeStore } from "./contexts/ThemeStore";

import { CartItemStore } from "./contexts/CartStore";
import Theme from "./styledComponents/theme";
import  {CartProvider}  from "./useCart";

function App() {
  const location = useLocation();
  const { settings } = useSettings();
  const [toggle, setToggle] = useState(false);

  initFacebookSdk();
  const initiateMobiNav = (e) => {
    setToggle(e);
  };

  return (
    <ThemeStore>
      <Theme>
      <CartProvider>
        <CartItemStore>
          <div style={{ backgroundColor: "#fff" }} id="webElement">
            {toggle ? (
              ""
            ) : (
              <>
                {" "}
                <div style={{ position: "relative", padding: "40px 0", zIndex:"13"}}>
                  <Header
                    mobile={initiateMobiNav}
                    settings={settings}
                    showCart={location.pathname === "/checkout" ? false : true}
                  />
                </div>
                <div className="App-content" style={{ minHeight: "500px" }}>
                  <Switch>
                    <Route exact path="/" component={Index}></Route>
                    <Route exact path="/about-us" component={AboutUs} ></Route>
                    <Route exact path="/how-it-works" component={IndexContent}></Route>
                    <Route exact path="/contact_us" component={GenaricForm}></Route>
                    <Route path="/stores" component={Stores}></Route>
                    <Route
                      path="/store/:id/categories"
                      component={Categories}
                    ></Route>
                    <Route
                      path="/store/:id/products"
                      component={categoryProducts}
                    ></Route>
                    <Route path="/checkout" component={Checkout}></Route>
                    <Route
                      path="/privacy-policy"
                      component={PrivacyPolicy}
                    ></Route>
                    <Route path="/terms" component={Terms}></Route>
                    <Route path="/offers" component={Offers}></Route>
                    <Route path="/faqs" component={Faqs}></Route>
                    <Route path="/profile/:type" component={Profile}></Route>
                  </Switch>
                </div>
                <Footer settings={settings} />
              </>
            )}
          </div>
        </CartItemStore>
        </CartProvider>
      </Theme>
    </ThemeStore>
  );
}

export default App;

import React, { useState } from "react";
import { ApiLoginService, ImgUrl, addDefaultLogo } from "../services";
import GButton from "../gComponents/gButton";
import { ArrowBackIos, Facebook } from "@material-ui/icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "react-simple-snackbar";
import FBLogin from "../socialLogin/facebookLogin";
import GLogin from "../socialLogin/googleLogin";
import ReCAPTCHA from "react-google-recaptcha"; //Recaptcha

export default function Signin(props) {
  // const classes = useStyles();
  const [res, setRes] = useState();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  // const location = useLocation();

  const [isVerify, setIsVerify] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState("");

  const recaptchaChange = (response) => {
    if (response) {
      setIsVerify(true);
      setCaptchaChecked("");
    }
  };

  const initialValues = {
    username: "",
    password: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (props.data) {
      delete values.password;
      setCaptchaChecked("")
    }else if (!isVerify) {
      setCaptchaChecked("*Please Fill Captcha!!")
    }
    console.log(values);

    if(isVerify) {ApiLoginService("POST", "c/login", values).then((response) => {
      setRes(response);
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        getOauthToken(values);
        openSnackbar(response.message);
      } else {
        setBtnLoading(false);
        openSnackbar(response.message);
        return;
      }
    });
  }else if (!isVerify) {
    setCaptchaChecked("*Please Fill Captcha!!")
    setBtnLoading(false);
  }
  };

  // const onSubmit = (values) => {
  //   setBtnLoading(true);
  //   if (props.data && isVerify) {
  //     delete values.password;
  //     setCaptchaChecked("")
  //     setBtnLoading(true);
    
  //   console.log(values);

  //   ApiLoginService("POST", "c/login", values).then((response) => {
  //     setRes(response);
  //     console.log("r" + response.status_code);
  //     if (response.status_code === 1) {
  //       getOauthToken(values);
  //       openSnackbar(response.message);
  //     } else {
  //       setBtnLoading(false);
  //       openSnackbar(response.message);
  //       return;
  //     }
  //   });
  // }else if (!isVerify) {
  //   setCaptchaChecked("*Please Fill Captcha!!")
  //   setBtnLoading(false);
  // }
  // };


  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const getOauthToken = (signinData) => {
    var refresh_token_data = {
      username: signinData.username,
      password: signinData.password,
      grant_type: "password",
      client_id: 2,
      client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
      provider: "customers",
    };
    console.log(refresh_token_data);

    ApiLoginService("POST", "oauth/token", refresh_token_data)
      .then(function (response) {
        console.log(response);
        if (response) {
          setBtnLoading(false);
          localStorage.setItem("accessToken", response.access_token);
          localStorage.setItem("refreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("expires_in", now + response.expires_in);
          props.setSigninvisible(false);
          // window.location.reload(false);
        } else {
          setBtnLoading(false);
          return;
        }
      })
      .then(() => {
        window.location = "/";
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  if (props.signinvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;{" "}
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setSigninvisible(false)}
        />
        Sign In
      </div>

      <img
        src={ImgUrl("logo") + "/" + props.settings?.web_header_logo}
        className="header-logo"
        alt={props.settings?.project_name}
        onError={addDefaultLogo}
        style={{ margin: "10px 0 0 30px" }}
      />
      <h2 style={{ marginBottom: "10px", fontFamily: "LatoWeb", display:"inline",position:"relative",top:"-20px" }}>
          &nbsp;&nbsp;Sign In
        </h2>

      <div className="modal-body">
        
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                placeholder="Email or Phone"
                name="username"
                // className="noBorder"
              />

              <FormikControl
                control="input"
                type="password"
                placeholder="Password"
                name="password"
              />

              <span
                style={{
                  margin: "0 15px 25px",
                  float: "right",
                  color: ({ theme }) => theme.button,
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setFpvisible(true);
                  props.setSigninvisible(false);
                }}
              >
                Forgot Password?
              </span>
              
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px 20px 15px",
                  width: "calc(100% - 30px)",
                }}
                children="SIGN IN"
                type="submit"
                loading={isBtnLoading}
              />
              <br />
            </Form>
          )}
          
        </Formik>
        <ReCAPTCHA
                sitekey="6LfOk4YdAAAAAHFhVqe6U90eQnLJUisuVIS0Xzch"
                render="explicit"
                onChange={recaptchaChange}
                style={{marginLeft:"20px"}}
              />
              <div style={{ color: "red" }}>
                <b>{captchaChecked}</b>
              </div>
              

        <GButton
          variant="linkable"
          style={{
            margin: "15px",
            width: "95%",
          }}
          children="Create an Account"
          onClick={() => {
            props.setSignupvisible(true);
            props.setSigninvisible(false);
          }}
          type="button"
        />

        <div style={{ margin: "0 auto", textAlign: "center" }}>
          {/* <FBLogin /> */}
          {/* <Facebook2 /> */}
          <GLogin />
        </div>
      </div>
    </React.Fragment>
  );
}

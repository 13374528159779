import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { ArrowBackIos } from "@material-ui/icons";
import styled, { ThemeConsumer } from "styled-components";
import useCart from "../useCart";

const TimeslotDayDiv = styled.div`
  margin: 20px 0;
  h4 {
    border: 1px solid #e2e2e2;
    border-radius: 20px;
    color: #777;
    text-align: center;
    padding: 7px 12px 5px;
    font-size: 14px;
    cursor: pointer;
    font-family: LatoWeb;
    font-weight: 400;
  }

  &.active h4 {
    background: ${({ theme }) => theme.button};
    color: #fff;
    border: 1px solid ${({ theme }) => theme.button};
  }
`;

const TimeslotDiv = styled.div`
  p {
    background: #f4f5f9;
    color: #555;
    padding: 10px 17px;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    margin: 8px;
  }
`;

export default function OrderTimeslots(props) {
  const [timeslotsList, setTimeslots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate || "");
  const {cartStore } = useCart();

  // console.log(props, "-- props data");

  // #### Hitting APi To get timeslots --> 

  useEffect(() => {
    ApiService({ method: "GET", route: `c/timeslots?store_id=${cartStore}` })
      .then((response) => {
        // console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setTimeslots(response.data.data);
          // console.log("time slotsssss= ",response.data.data)
          setSelectedDate(
            props.selectedDate
              ? props.selectedDate
              : response.data.data[0].display
          );
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (props.otslotsvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      {/* <h3>Choose Variants</h3> */}
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOTslotsVisible(false)}
        />
        Choose Timeslot
      </div>
      <div className="modal-body">
        <div
          style={{
            width: "140px",
            display: "inline-block",
            padding: "5px 12px 5px 4px",
            borderRight: "1px solid #e2e2e2",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
        >
          {timeslotsList.map((slots, i) => {
            return (
              <TimeslotDayDiv
                key={i}
                onClick={() => setSelectedDate(slots.display)}
                className={slots.display === selectedDate ? "active" : ""}
              >
                <h4>{slots.display}</h4>
              </TimeslotDayDiv>
            );
          })}
        </div>

        <div
          style={{
            width: "calc(100% - 140px)",
            display: "inline-block",
            boxSizing: "border-box",
            padding: "5px",
            verticalAlign: "top",
          }}
        >
          {timeslotsList.map((slots, i) => {
            return (
              <React.Fragment key={i}>
                {slots.display === selectedDate ? (
                  <TimeslotDiv>
                    {slots.slots.map((t, i) => {
                      return (
                        <p
                          key={i}
                          onClick={() => {
                            props.onChooseTimeslot(slots, t);
                            props.setOTslotsVisible(false);
                          }}
                        >
                        {t.show_time}
                        </p>
                      );
                    })}
                  </TimeslotDiv>
                ) : (
                  ""
                )}{" "}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

import React from "react";
import useSettings from "../useSettings";
import { Card, CardHead } from "../styledComponents/cardStyles";

export default function Terms() {
  const settings = useSettings();
  return (
    <div className="main-content">
      <br />
      <CardHead>Terms & Conditions</CardHead>
      <Card>
        <div
          dangerouslySetInnerHTML={{ __html: settings.terms_and_conditions }}
        ></div>
      </Card>
      <br />
    </div>
  );
}

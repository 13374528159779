import React, { useEffect, useState, useRef } from "react";
import { Avatar } from "@material-ui/core";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { Edit, PhotoCamera } from "@material-ui/icons";
import { FileApiService, ImgUrl, addDefaultSrc } from "../services";

const Img = styled.img`
  width: 80px;
`;
const Div = styled.div`
  & svg.MuiSvgIcon-root {
    bottom: 0;
    right: -5px;
  }
`;
const styleObj = {
  background: "#e4f1f7",
  border: "2px dashed #f23938",
  textAlign: "center",
  padding: "25px",
  width: "100%",
  margin: "0 5px",
  boxSizing: "border-box",
  color: "#f08c37",
  cursor: "pointer",
};
const IconStyle = {
  position: "absolute",
  background: "#fff",
  padding: "5px",
  fontSize: "18px",
  border: "3px solid #f4f5f9",
  bottom: "2px",
  borderRadius: "20px",
  marginLeft: "-15px",
  cursor: "pointer",
};

function GImageEditor(props) {
  const [imageType] = useState(props.type);
  const fileInput = useRef(null);
  const [editableId] = useState(props.id);
  const [openSnackbar] = useSnackbar();
  const [imageValue, setImageValue] = useState(props.image);

  useEffect(() => {
    setImageValue(props.image);
  }, [props]);

  const changeFileHandler = (e) => {
    var files = fileInput.current.files[0];

    FileApiService("POST", imageType, files)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          if (props.edit !== false) {
            setImageValue(ImgUrl(imageType) + "/" + response.data.thumb_image);
          }
          props.onSubmit(
            response.data.image,
            props.id,
            response.data.thumb_image
          );
          fileInput.current.value = "";
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <input
        id={props.type + "_imgInput"}
        type="file"
        ref={fileInput}
        onChange={changeFileHandler}
        style={{ display: "none" }}
      //onChange={(e) => setSelectedValue(e.target.files[0])}
      />
      {props.isPreviewImg === true ? (
        <Div
          style={{
            padding: "5px",
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          {imageType === "logo" ? (
            <React.Fragment>
              <Img alt="image" src={imageValue} onError={addDefaultSrc} />
              <PhotoCamera style={IconStyle}
                onClick={() => {
                  document.getElementById(props.type + "_imgInput").click();
                }} /></React.Fragment>
          ) : (
              <React.Fragment>

                {imageValue ? <Img alt="image" src={imageValue} onError={(e) => addDefaultSrc(e, imageType)} style={{
                  height: "60px",
                  width: "60px", borderRadius: '50%', border: '1px solid #f2f2f2', background: '#fff'
                }} /> : <Avatar
                    alt={props.alt ? props.alt : "image"}
                    src={imageValue}
                    style={{
                      height: "60px",
                      width: "60px",
                    }}
                  />}

                <Edit
                  style={IconStyle}
                  onClick={() => {
                    document.getElementById(props.type + "_imgInput").click();
                  }}
                /></React.Fragment>)}
        </Div>
      ) : (
          <Div
            style={Object.assign(
              {},
              {
                padding: "10px 15px",
                position: "relative",
                boxSizing: "border-box",
                display: "inline-block",
                verticalAlign: "top",
                width: "33%",
                minWidth: "250px",
              },
              props.divstyle
            )}
          >
            {imageValue ? (
              <React.Fragment>
                <Img
                  alt="Add Image"
                  src={imageValue}
                  style={{
                    width: "80%",
                    maxHeight: "140px",
                    minHeight: "120px",
                    display: "flex",
                    margin: "0",
                    borderRadius: '5px'
                  }}
                />
                {props.edit === false ? (
                  ""
                ) : (
                    <Edit
                      style={Object.assign({}, IconStyle, {
                        // top: "20px",
                        marginRight: "40px",
                      })}
                      onClick={() => {
                        document.getElementById(props.type + "_imgInput").click();
                      }}
                    />
                  )}
              </React.Fragment>
            ) : (
                <p
                  style={Object.assign({}, styleObj, props.style)}
                  onClick={() => {
                    document.getElementById(props.type + "_imgInput").click();
                  }}
                >
                  Add Image
            </p>
              )}
          </Div>
        )}
    </React.Fragment>
  );
}

export default GImageEditor;

export class Item {
  constructor(obj) {
    this.id = obj.id;
    this.title = obj.title;
    this.unit = obj.unit;
    this.price = parseFloat(obj.price).toFixed(2);
    this.icon = obj.thumb_photo
      ? `${obj.thumb_photo}`
      : '';
    this.variants_available = obj.variants_available;

    this.store_id = obj.store_id;

    this.unlimited = obj.unlimited;
    this.stock = obj.stock;

    this.status = obj.status;
    this.sale = obj.sale;
    this.sale_discount = obj.sale_discount;
    this.sale_price = obj.sale_price;
  }
}

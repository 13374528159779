import React, {useState} from 'react'
import "../css/bottomToTop.css"
import NavigationIcon from '@material-ui/icons/Navigation';

const BottomToTop = () => {
    const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
    const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth',
    });
  };
  window.addEventListener('scroll', toggleVisible);
    return (
        <div className="circle" style={{display:!visible?"none":""}} onClick={scrollToTop}>
            <NavigationIcon className="btn-Top"/>
        </div>
    )
}

export default BottomToTop

import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Receipt,
  AccountCircle,
  ExitToAppRounded,
  Search,
  Security,
  ExitToApp,
} from "@material-ui/icons";
import {
  Button,
  MenuItem,
  MenuList,
  Popper,
  Avatar,
  Paper,
  Grow,
  ClickAwayListener,
  Divider,
} from "@material-ui/core";
import { LogoutService, ImgUrl } from "../services";
import ChangePassword from "./ChangePassword";
import EditProfile from "./editProfile";
import SignUp from "./signup";
import SignIn from "./login";
import CartModal from "./cartModal";
import FavouriteProducts from "./favouriteProducts";
import StoreSearch from "./storeSearch";
import GInfo from "../gComponents/gInfo";
// import useSettings from "../useSettings";
import ForgotPassword from "./forgotPassword";
import useLocalStorage from "../useAsyncStorage";
import { useHistory } from "react-router-dom";
import { CartContext } from "../contexts/CartStore";
import CartImg from "../assets/images/cart.png";
import FavouriteImg from "../assets/images/heart-empty.png";
import Modal from "react-modal";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { Typography } from "@material-ui/core";
import "../css/header.css";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import CharityImg from '../assets/images/donation.svg';
import deliveryVan from '../assets/images/delivery-van.svg';
import shoppingCart from '../assets/images/shopping-cart.svg';
// import { useCrossTabState } from "./hooks";

const Navbar = styled.div`
  /* display: inline-block; */

  display: inline-flex;
  width: calc(100% - 140px);
  text-align: right;
  align-items: center;
  float: right;
  height: 100%;
  vertical-align: middle;

  ul {
    display: inline-flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    vertical-align: -webkit-baseline-middle;
    margin-left: auto;
    li {
      a {
        padding: 8px 16px;
        color: #000;
        text-decoration: none;
        display: block;
      }

      &.cart {
        position: relative;
        span {
          background: red;
          color: #fff;
          width: 21px;
          height: 17px;
          font-size: 12px;
          font-family: "LatoWebBold";
          padding-top: 4px;
          border-radius: 20px;
          text-align: center;
          display: inline-block;
          position: absolute;
          right: -8px;
          top: -5px;
        }
      }
    }
  }
`;

Modal.setAppElement("#root");

export default function HeaderNav(props) {
  let history = useHistory();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [settings, setSettings] = useState(
    props.settings?.user
      ? localStorage.getItem("settings")
        ? JSON.parse(localStorage.getItem("settings"))
        : props.settings
      : props.settings
  );
  // const settings = props.settings;
  // console.log("login setting" + JSON.stringify(settings))

  const [dialogOpen, setDialogOpen] = useState(false);
  const { itemCart } = useContext(CartContext);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);
  const [sSearchvisible, setSSearchvisible] = useState(false);
  const [cartvisible, setCartvisible] = useState(false);
  const [wishlistVisible, setWishlistVisible] = useState(false);
  const [showCartBtn, setShowCartBtn] = useState(props.showCart);
  

  useEffect(() => {
    console.log(props);
    setShowCartBtn(props.showCart);
  }, [props.showCart]);

  useEffect(() => {
    setTimeout(async function () {
      let s = (await localStorage.getItem("settings"))
        ? JSON.parse(localStorage.getItem("settings"))
        : localStorage.getItem("settings");
      await setSettings(s);

      // window.location = "/";
      // console.log("login settings" + JSON.stringify(settings))
    }, 500);
  }, [settings]);
  useEffect(() => {}, [itemCart]);

  const customStyles = {
    content: {
      top: "0",
      left: "auto",
      right: "0",
      bottom: "0",
      width: "400px",
      // transform: 'translate(0%, -50%)'
    },
  };
  const customStyles1 = {
    content: {
      top: "0",
      left: "auto",
      right: "0",
      bottom: "0",
      width: "450px",
      // transform: 'translate(0%, -50%)'
    },
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = (event) => {
    setOpen(false);
    setPlacement();
  };

  const handleLogout = () => {
    LogoutService("c/logout")
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.clear();
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <React.Fragment>
      <Navbar className="navbar">
        <div className="navbar-links">
          <div className="navbar-norm-links">
            <div>
              <a
                onClick={() => {
                  
                    window.location.pathname = "/how-it-works";
                 
                }}
                // href="#how_it_works"
              >
                <Typography variant="p">How it works</Typography>
              </a>
            </div>
            <div>
            <a
                onClick={() => {
                  
                    window.location.pathname = "/about-us";
                  
                }}
                // href="#about_us"
              >
              <Typography variant="p">About us</Typography>
              </a>
            </div>
            <div>
              <a
              onClick={() => {
                  
                  window.location.pathname = "/contact_us";
               
              }}
              //  href="contact_us"
               >
                <Typography variant="p">Contact us</Typography>
              </a>
            </div>
          </div>
          <div className="navText">
            <div className="navText-sub">
            <span><img src={shoppingCart} alt="CharityImg" /></span>
            <p>$10 off your first order</p>
            </div>
            <div className="navText-sub">
            <span><img src={CharityImg} alt="CharityImg" /></span>
            <p>10% of profits go to charities</p>
            </div>
            <div className="navText-sub">
            <span><img src={deliveryVan} alt="CharityImg" /></span>
              <p>Free shipping over $75</p>
            </div>
          </div>
          {!settings?.user?.id ? (
            <div className="navbar-imp-links">
            {/* Uncomment below both div to show login and signup button */}
               <div
                onClick={() => {
                  setSignupvisible(true);
                  setAnchorEl(null);
                  setOpen(false);
                }}
                className="sign-up"
              >
                <Typography variant="p">&nbsp; Sign Up &nbsp;</Typography>
              </div>
              <div
                onClick={() => {
                  setAnchorEl(null);
                  setOpen(false);
                  setSigninvisible(true);
                }}
                className="log-in"
              >
                <p> Login</p> 
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <ul>
          {/* <li style={{ padding: "7px" }}> */}
          {/* Uncomment below li to show Search button */}
           <li>
            <Search
              style={{
                fontSize: "1.60rem",
                margin: "0 13px",
                marginTop: "5px",
              }}
              onClick={() => setSSearchvisible(true)}
            />
          </li>
          {/* <li>
            <Link to="/faqs">FAQ</Link>
          </li> */}
          {/* <li>
            <Link to="/offers">Offers</Link>
          </li> */}

          {settings?.user?.id ? (
            <li
              style={{ cursor: "pointer", padding: "4px 15px" }}
              onClick={() => setWishlistVisible(true)}
            >
              <img
                src={FavouriteImg}
                alt="Favourite"
                style={{ width: "25px" }}
              />
            </li>
          ) : (
            ""
          )}

          {showCartBtn === false ? (
            ""
          ) : itemCart && itemCart.length > 0 ? (
            <li
              className="cart"
              style={{ cursor: "pointer", padding: "4px 10px 0" }}
              onClick={() => setCartvisible(true)}
            >
              <img src={CartImg} alt="cart" style={{ width: "25px" }} />
              <span>{itemCart.length}</span>
            </li>
          ) : (
            ""
          )}
        </ul>
        {settings?.user?.id ? (
          <Button
            aria-controls={open ? "menu-list-grow" : undefined}
            onClick={handleClick("bottom-end")}
            aria-haspopup="true"
            // onClick={handleToggle}
            className="dropdownMenu"
          >
            {settings?.user?.id ? (
              <Avatar
                alt={settings.user.title}
                src={ImgUrl("user") + "/" + settings.user.photo}
              />
            ) : (
              <AccountCircle fontSize="small" />
            )}
          </Button>
        ) : (
          ""
        )}

        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          role={undefined}
          transition
          style={{ zIndex: "99" }}
          //    disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              // style={{ transformOrigin:placement==='bottom'? 'bottom right':  'top right' , anchorOrigin : 'bottom right'}}
            >
              <Paper>
                <div className="paperAdminInfo">
                  {!settings?.user ? (
                    <span>
                      Hello, <b>there</b>
                    </span>
                  ) : (
                    <GInfo
                      title={settings.user.title}
                      key={settings.user.id}
                      subtitle={settings.user.email}
                      photo={settings.user.photo}
                      imgType="user"
                    />
                  )}
                </div>
                <Divider />
                <ClickAwayListener onClickAway={handleClose}>
                  {settings?.user ? (
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          setOpen(false);
                        }}
                      >
                        <Link
                          to="/profile/orders"
                          style={{
                            textDecoration: "none",
                            color: "#222",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <Receipt fontSize="small" /> My Orders/Profile
                        </Link>
                      </MenuItem>
                      {/* <MenuItem
                        onClick={() => {
                          setEpvisible(true);
                          setAnchorEl(null);
                          setOpen(false);
                        }}
                      >
                        <AccountCircle fontSize="small" /> Edit Profile
                      </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          setCpvisible(true);
                          setAnchorEl(null);
                          setOpen(false);
                        }}
                      >
                        <Security fontSize="small" /> Change Password
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          setOpen(false);
                          setDialogOpen(true);
                        }}
                      >
                        <ExitToApp fontSize="small" /> Logout
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          setOpen(false);
                          setSigninvisible(true);
                        }}
                      >
                        <ExitToAppRounded fontSize="small" /> Login
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setSignupvisible(true);
                          setAnchorEl(null);
                          setOpen(false);
                        }}
                      >
                        <AccountCircle fontSize="small" /> Signup
                      </MenuItem>
                    </MenuList>
                  )}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Navbar>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Confirm"
          text="Are you sure you want to logout?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleLogout();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={epvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "380px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "310px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={cartvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCartvisible(false)}
        style={customStyles1}
        contentLabel="Cart Modal"
      >
        <CartModal
          cartvisible={cartvisible}
          setCartvisible={setCartvisible}
          onProceed={() => {
            console.log(history);
            history.push({ pathname: "/checkout" });
          }}
        />
      </Modal>

      <Modal
        isOpen={wishlistVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWishlistVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Favorite Products Modal"
      >
        <FavouriteProducts
          wishlistVisible={wishlistVisible}
          setWishlistVisible={setWishlistVisible}
        />
      </Modal>

      <Modal
        isOpen={sSearchvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSSearchvisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Store Search Modal"
      >
        <StoreSearch
          sSearchvisible={sSearchvisible}
          setSSearchvisible={setSSearchvisible}
        />
      </Modal>
    </React.Fragment>
  );
}

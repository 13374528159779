import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import { Avatar } from "@material-ui/core";
import { StarRate } from "@material-ui/icons";

const ItemImage = styled.div`
  /* width: 75px; */
  margin-right: 15px;
  .MuiAvatar-root {
    height: 50px;
    width: 50px;
  }
  &.headerInfo {
    /* width: 70px; */
    .MuiAvatar-root {
      height: 50px;
      width: 50px;
      text-transform: uppercase;
      background: ${({ theme }) => theme.button};
      color: #fff;
    }
    .MuiAvatar-fallback {
      width: 85%;
      height: 85%;
    }
  }
  & img {
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
`;

const ItemText = styled.div`
  width: calc(100% - 65px);

  h4 {
    color: ${({ theme }) => theme.button};
    font-size: 16px;
    margin: 5px 0;
    font-weight: 400;
  }
  p {
    color: #333;
    font-size: 15px;
    margin: 5px 0;
    font-family: LatoWebSemibold;
    line-height: 20px;
  }
  h5 {
    margin: 5px 0;
    color: #aaa;
  }
  & .amount {
    background: #f4f5f9;
    color: #777;
    float: right;
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 20px;
  }
  & .rating {
    background: ${({ theme }) => theme.button};
    float: right;
    font-size: 14px;
    color: #fff;
    padding: 2px 7px;
    display: flex;
    align-items: flex-end;
    border-radius: 15px;
    margin-top: -25px;
  }

  &.headerInfo {
    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

function GInfo(props) {
  const [infoData, setInfoData] = useState({});
  // console.log(props);
  useEffect(() => {
    setInfoData(props);
    //  props.onChange(value)
    // action on update of movies
  }, [props]);

  return (
    <div
      style={Object.assign(
        {},
        { display: "inline-flex", width: "70%" },
        props.style
      )}
    >
      <ItemImage className={infoData.className}>
        {infoData.photo && infoData.imgType !== "user" ? (
          <img
            src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
            alt={infoData.title}
            onError={(e) => addDefaultSrc(e, "user")}
          />
        ) : (
          <Avatar
            alt={infoData.title}
            src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
          />
        )}
      </ItemImage>
      <ItemText className={infoData.className}>
        {infoData.rightTitle ? (
          <span className="amount">{infoData.rightTitle}</span>
        ) : (
          ""
        )}
        <h4>{infoData.title}</h4>
        <p style={props.subtitleStyle}>{infoData.subtitle}</p>
        {infoData.subtitle2 ? <h5>{infoData.subtitle2}</h5> : ""}
        {infoData.status ? (
          <span
            className="orderStatus"
            style={{
              // background: "#" + infoData.status.color2,
              background: "#" + infoData.status.color1,
            }}
          >
            {infoData.status.title}
          </span>
        ) : (
          ""
        )}
        {infoData.rating ? (
          <span className="rating">
            <StarRate style={{ fontSize: "1.1rem" }} />
            {infoData.rating}
          </span>
        ) : (
          ""
        )}
      </ItemText>
    </div>
  );
}

export default GInfo;

import React, { useState, useEffect } from "react";
import { ApiLoginService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import useSettings from "../useSettings";
import "../css/checkoutOtpVerifyModal.css"

// Material ui import {  } from "module";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));


 function CheckoutOtpVerifyModal(props) {
    const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false)
  const settings = useSettings(true);

//   FOR OTP VERIFY NEW
    const [viewNum, setViewNum] = useState(false);
    const [viewOtp, setViewOtp] = useState(false);
    const [otpInput, setOtpInput] = useState("");
    const [numInput, setNumInput] = useState("");
    const [numRes, setNumRes] = useState(false);
    const [otpRes, setOtpRes] = useState(false);
    const [enterNum, setEnterNum] = useState(false);
//  Const for use settings ---->
    const first_name = settings?.user?.first_name
    const oldPhone = settings?.user?.phone
    // console.log("olldd phone", oldPhone)

    // For Checking old phone is there or not, 
    useEffect(() => {
        if(oldPhone===""){
            setViewNum(true)
        }else{
            setViewNum(false)
        }
        
    }, [oldPhone])
    
    // console.log("valuessss##",values.phone)
    const handleOldNum=()=>{
        const values ={phone:oldPhone};

        if (settings?.user?.phone !==""){
            ApiLoginService("POST", "c/verify/otp", values).then((response) => {
        // console.log("rotp" + response)
            if (response.status_code === 1){
                setNumRes(true)
                setViewNum(false)
                setViewOtp(true)
                openSnackbar(response.message);
            } else {
                setNumRes(false)
                setBtnLoading(false)
                openSnackbar(response.message);
                 return;
            }
        });
        } else{
            openSnackbar("You Don't Have any number");
        }
        
    }

    const handleNumSubmit=()=>{
        const values ={phone:numInput};

        ApiLoginService("POST", "c/verify/otp", values).then((response) => {
        // console.log("rotp" + response)
            if (response.status_code === 1){
                setNumRes(true)
                setViewNum(false)
                setViewOtp(true)
                openSnackbar(response.message);
            } else {
                setNumRes(false)
                setBtnLoading(false)
                openSnackbar(response.message);
                 return;
            }
        }); 
    }
    const handleOtpSubmit=()=>{
        const values ={phone:enterNum?numInput:oldPhone,otp:otpInput};
        ApiLoginService("POST", "c/verify", values).then((response) => {
        console.log("otp =>" + response)
            if (response.status_code === 1){
                setOtpRes(true)
                openSnackbar(response.message);
                setEnterNum(false)
                window.location.pathname = "/checkout"
            } else {
                setOtpRes(false)
                setBtnLoading(false)
                openSnackbar(response.message);
                 return;
            }
        });
    }

    
//   const initialValues = {
//     otp: ""
//   };

//   const onSubmit = (values) => {
//     setBtnLoading(true)
//     console.log(values);

//     if (props.type === 'signup') {
     
//       values.phone = props.data.phone;
//       ApiLoginService("POST", "c/signup", values).then((response) => {
//         console.log("r" + response.status_code);
//         if (response.status_code === 1) {
//           props.onVerify();
//           props.setVerifyOtpvisible(false);
//           setBtnLoading(false)
//           openSnackbar(response.message);
//         } else {
//           setBtnLoading(false)
//           openSnackbar(response.message);
//           return;
//         }
//       });
//     }
//     else {

//       values.username = props.data.username;

//       ApiLoginService("POST", "c/verify-otp", values).then((response) => {
//         console.log("r" + response.status_code);
//         if (response.status_code === 1) {
//           setBtnLoading(false)
//           props.onVerify(values.otp);
//           props.setVerifyOtpvisible(false);
//           openSnackbar(response.message);
//         } else {
//           setBtnLoading(false)
//           openSnackbar(response.message);
//           return;
//         }
//       });
//     }
//   };

//   const validationSchema = Yup.object({
//     otp: Yup.string().required("Required"),
//   });



  if (props.verifyOtpvisible === false) {
    return null;
  }
  return (
    <div className="modal-body verify_body" >
      <h3>Phone verification required prior to checkout.</h3>
      <p> Hello <b> {first_name} </b>, </p>
      <br/>
      <p style={{display:oldPhone!==""?"block":"none"}}>
       Your number is <b>{oldPhone}</b> ?
      </p>
       {oldPhone!==""?<div className="verify_options">
           <span>
           
           <Button
            variant="contained"
            color="secondary"
            className={classes.button, "buttonYes"}
            endIcon={<DoneAllIcon/>} onClick={()=>{
                    setViewOtp(true)
                    setViewNum(false)
                    setEnterNum(false)
                    handleOldNum()
                    }}>
           If yes <b>&nbsp; Click Here!!</b> 
      </Button>
           </span>
           <span>
           
           <Button
                variant="contained"
                color="secondary"
                className={classes.button, "buttonNo"}
                endIcon={<EditIcon/>} onClick={()=>{
                    setViewNum(true)
                    setViewOtp(false)
                    setEnterNum(true)
                    }}>
                If No <b>&nbsp; Click Here!!</b> 
            </Button>
           </span>
       </div>:""}
       <div className="verify_form">
           <div className="verify_input number" style={{display:viewNum?"flex":"none"}}>
           <h4>Enter Your New Number</h4>
           <input type="tel" style={{height:"70px",width:"60%"}} value={numInput} onChange={(e)=>setNumInput(e.target.value)} />
           <button type="button" disabled={!numInput} onClick={handleNumSubmit}>Submit</button>
           </div>
           <div className="verify_input otp" style={{display:!viewOtp?"none":"flex"}}>
           <h4>Enter OTP </h4>
           <input type="number" style={{height:"70px",width:"60%"}} value={otpInput} onChange={(e)=>setOtpInput(e.target.value)} />
           <button type="button" disabled={!otpInput} onClick={handleOtpSubmit}>Submit</button>
           </div>
       </div>
    </div>
  );
}


export default CheckoutOtpVerifyModal

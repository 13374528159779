import React, { useEffect, useState } from "react";
import { ApiLoginService, ImgUrl, addDefaultLogo } from "../services";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/app-logo-white.png";
import useSettings from "../useSettings";
import "../css/footer.css";
import FacebookIcon from "../assets/images/social/facebook.png";
import InstagramIcon from "../assets/images/social/instagram.png";
import TwitterIcon from "../assets/images/social/twitter.png";
import WebIcon from "../assets/images/social/web.png";
import useLocalStorage from "../useAsyncStorage";

import ShopIcon from "@material-ui/icons/Shop";
import AppleIcon from "@material-ui/icons/Apple";
import "../css/footer.scss";
const Footer = styled.div`
  background: #c3682d; //#EED6C4;
  // height: 45vh;
  & .footer {
    div {
      flex: 50%;
      padding: 10px;
      // height: 200px;

      p {
        color: #fff;
        font-size: 15px;
        line-height: 20px;
        padding-right: 30px;
      }
      & .listTitle {
        color: #fff;
        margin: 20px 0;
        text-transform: uppercase;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          padding: 8px 0px;
          a {
            color: #ccc;
            text-decoration: none;
            display: block;
          }
        }
      }
    }

    .social {
      display: inline-flex;
      li {
        padding: 0;
        a {
          margin: 5px;
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  .footer-bottom {
    a {
      color: ${({ theme }) => theme.button};
      font-family: "LatoWeb";
    }
  }
`;

export default function FooterView() {
  const settings = useSettings(true);
  const [socialLinks, setSocialLinks] = useLocalStorage("social", []);

  useEffect(() => {
    if (socialLinks && socialLinks.length > 0) {
      return;
    }
    ApiLoginService("GET", "c/social")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setSocialLinks(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formatPhoneNumber=(phoneNumberString)=> {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
  return (
    <Footer>
      <div className="footer">
        <div
          style={{
            width:"max-content",
            display:"flex",
            flexDirection:"column",
            alignItems: window.innerWidth < 768 ? "center" : "flex-start",
            borderRight:window.innerWidth > 768 ?"1.5px solid #fff":"none",
            borderBottom:window.innerWidth < 768 ?"1.5px solid #fff":"none",
            padding:"5px 10px",
            paddingLeft: window.innerWidth < 768 ? "20px" : "5%",
          }}
          >
          <div
            className="logo_social"
            style={{
              // margin: "0 14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap:"25px",
            }}
            >
            <div className="foot-logo">
              <Link to="/">
                {settings?.web_footer_logo ? (
                  <img
                    src={ImgUrl("website") + "/" + settings?.web_footer_logo}
                    className="footer-logo"
                    alt={settings?.project_name}
                    onError={addDefaultLogo}
                  />
                ) : (
                  // settings?.admin_logo ? (
                  //   <img
                  //     src={ImgUrl("logo") + "/" + settings?.admin_logo}
                  //     className="footer-logo"
                  //     alt={settings?.project_name}
                  //     onError={addDefaultLogo}
                  //   />
                  // ) :
                  ""
                )}
              </Link>
            </div>
            <ul className="social" style={{display:"flex", flexWrap:"nowrap", gap:"10px"}}>
              {socialLinks.map((link, i) => {
                return (
                  <li
                    style={{ transform: "scale(1)" }}
                    key={i}
                  >
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.title === "facebook" ||
                        link.title === "Facebook" ? (
                        <img src={FacebookIcon} alt={link.title} />
                      ) : link.title === "twitter" ||
                        link.title === "Twitter" ? (
                        <img src={TwitterIcon} alt={link.title} />
                      ) : link.title === "instagram" ||
                        link.title === "Instagram" ? (
                        <img src={InstagramIcon} alt={link.title} />
                      ) : (
                        <img src={WebIcon} alt={link.title} />
                      )}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div style={{ padding: "0", margin: "0" }} class="flex social-btns">
            <a class="app-btn blu flex vert" href="https://apps.apple.com/us/app/ripeontime/id1549359984">
              <i class="fab fa-apple">
                <AppleIcon style={{ fontSize: "24px" }} />
              </i>
              <p style={{ fontSize: "10px", paddingRight: "0" }}>
                Available on <br /> <span class="big-txt">App Store</span>
              </p>
            </a>

            <a class="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.ripeontime.customer&hl=en_US&gl=US">
              <i class="fab fa-google-play">
                <ShopIcon style={{ fontSize: "24px" }} />
              </i>
              <p style={{ fontSize: "10px", paddingRight: "0" }}>
                Get it on <br /> <span class="big-txt">Google Play</span>
              </p>
            </a>
          </div>
          {/* <img src={logo} className="footer-logo" alt="logo" /> */}
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p> */}
        </div>
        {/* <div>
          <h4 className="listTitle">Get &nbsp;to know us</h4>
          <ul>
            <li>
              <Link to="/faqs">FAQ's</Link>
            </li>
            <li>
              <Link to="/terms">T & C</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div> */}
        <div>
          <h4 className="listTitle">Legal</h4>
          <ul>
            <li>
              <Link to="/faqs">FAQ's</Link>
            </li>
            <li>
              <Link to="/terms">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="listTitle">Quick Links</h4>
          <ul>
            <li>
              <a href="/how-it-works">How it works</a>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            {/* <li>
              <Link to="/contact_us">Contact Us</Link>
            </li> */}
          </ul>
        </div>
        <div>
          <h4 className="listTitle">Contact</h4>
          <ul>
            <li style={{ padding: "0" }}>
              {/* <ul className="social">
                {socialLinks.map((link, i) => {
                  return (
                    <li key={i}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.title === "facebook" ||
                        link.title === "Facebook" ? (
                          <img src={FacebookIcon} alt={link.title} />
                        ) : link.title === "twitter" ||
                          link.title === "Twitter" ? (
                          <img src={TwitterIcon} alt={link.title} />
                        ) : link.title === "instagram" ||
                          link.title === "Instagram" ? (
                          <img src={InstagramIcon} alt={link.title} />
                        ) : (
                          <img src={WebIcon} alt={link.title} />
                        )}
                      </a>
                    </li>
                  );
                })}
              </ul> */}
              {/* <ul>
                <li>
                  <a href={} target="_blank" rel="noopener noreferrer" >

                  </a>
                </li>
                <li>
                  <Link></Link>
                </li>
                <li>
                  <Link></Link>
                </li>
              </ul> */}
            </li>
            <li>
              <a href="/contact_us">Contact Us</a>
            </li>
            <li>
              <a href={"mailto:" + settings.admin_email}>
                {settings.admin_email}
              </a>
            </li>
            <li>
              <a href={"tel:" + settings.admin_phone}>
                {formatPhoneNumber(settings.admin_phone)}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="main-contentt">
        <hr
          style={{
            width: "calc(100% - 42px)",
            border: "0px",
            borderBottom: "0.5px solid #a2a2a2",
            margin: "0 20px",
          }}
        />
        <div className="main-content">
          <div className="footer-bottom">
            <div className="l-bottom">
              <p>
                (c) {new Date().getFullYear()} RipeOnTime. All Rights Reserved
              </p>
            </div>
            <div className="r-bottom">
              <p>
                Powered by{" "}
                <a
                  href="https://www.RipeOnTime.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RipeOnTime
                </a>
              </p>
            </div>
          </div>
        </div>
        <br />
      </div>
    </Footer>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { ApiLoginService } from "../services";
import { ArrowBackIos } from "@material-ui/icons";
import useCart from "../useCart";
import { GTableContent } from "../gComponents/gContent";
import GItemTable from "../gComponents/gCartItems";
// import { useSnackbar } from "react-simple-snackbar";
import { CartContext } from "../contexts/CartStore";
import useSettings from "../useSettings";
import { Link } from "react-router-dom";
import SignIn from "./login";
import SignUp from "./signup";
import ForgotPassword from "./forgotPassword";
import GEmpty from "../gComponents/gEmpty";
import GShimmer from "../gComponents/gShimmer";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import CartItemFeatureRequest from "./cartItemFeatureRequest";
import styled from "styled-components";
import Modal from "react-modal";
import OtpVerify from "./CheckoutOtpVerifyModal";
import CloseIcon from '@material-ui/icons/Close';

const StockDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.button};
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;

  p {
    text-align: left;
  }
`;
const customStyles = {
  content: {
    width: "350px",
    top: "0%",
    left: "0%",
    right: "0",
    bottom: "0",
    margin: "auto",
    height: "270px",
    // transform: 'translate(0%, -50%)'
  },
};

function CartModal(props) {
  // const [openSnackbar] = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [signinvisible, setSigninvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [featureRequestVisible, setFeatureRequestVisible] =
    React.useState(false);
  const [selectedCartItem, setSelectedCartItem] = useState({});
  const { itemCart } = useContext(CartContext);
  const [openSnackbar] = useSnackbar();
  const {
    cart,
    setCart,
    cartStore,
    setCartStore,
    addProduct,
    removeProduct,
    decreaseQuantity,
  } = useCart();
  const settings = useSettings(true);
  const [newOrderData, setNewOrderData] = useState({
    products: [],
    summary: {
      data: [],
    },
  });
  const [value, setValue] = useState(false);
  // For otp verification
  const [verifyOtpvisible, setVerifyOtpvisible] = useState(false);
  const [isVerify, setIsVerify] = useState(false);


  const customStyles = {
    content: {
      top: "0",
      left: "auto",
      right: "0",
      bottom: "0",
      width: "400px",
      // transform: 'translate(0%, -50%)'
    },
  };


  useEffect(() => {
    //setCart(itemCart);

    // if(itemCart.length === 0 && cart.length === 0){
    //   props.setCartvisible(false);
    //   return;
    // }

    if (props.storeId) {
      setCartStore(props.storeId);
    }

    // console.log(cartStore);
    // console.log(props.storeId);
    // console.log(itemCart[0]?.store_id);
    // console.log(itemCart);

    // if (itemCart.length === 0) {
    //   return;
    // }

    // if (props.storeId || cartStore || itemCart[0]?.store_id) {
    // } else {
    //   return;
    // }

    ApiLoginService("POST", "c/order/cart", {
      store_id: props.storeId || cartStore || cart[0]?.store_id,
      products: cart,
    })
      .then((response) => {
        // console.log(JSON.stringify("cart response == ",response));
        // console.log("cart response == ",response);

        // Getting only value of minimum_order as Integer
        // const minimum = parseInt(response.data.minimum_order.split("$")[1],10)

        // console.log("cart minimum_order == ", minimum);
        console.log("temp ==== cart CartMo", cart)
        if (response.status_code === 1) {
          setNewOrderData(response.data);
          setIsLoading(false);
          // setCart(response.data.products);
        } else {
          setNewOrderData(response.data);
          setIsLoading(false);
          openSnackbar(response.message);
          // setCart(response.data.products);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cart, cartStore]);



  const goToCheckout = () => {
    if (settings?.user?.id) {
      if(settings?.user?.no_verified==="1"){
        props.onProceed();
        props.setCartvisible(false);
      } else if(settings?.user?.no_verified==="0"){
        setVerifyOtpvisible(true)
      }
      
    } else {
      setSigninvisible(true);
    }
  };

  if (props.cartvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setCartvisible(false)}
        />
        Cart
      </div>
      <div className="modal-body">
        {isLoading === true ? (
          <GShimmer />
        ) : (
          <div style={{ padding: "5px" }}>
            {cart && newOrderData ? (
              <React.Fragment>
                {newOrderData.products.length !==
                newOrderData.original_count ? (
                  <StockDiv>
                    <p>
                      {newOrderData.products.length} out of{" "}
                      {newOrderData.original_count} products are unavailable and
                      need to be removed to proceed
                    </p>
                    <GButton
                      variant="linkable"
                      children="Remove"
                      type="button"
                      onClick={() => {
                        setCart(newOrderData.products);
                        setCartStore(cartStore);
                        if (newOrderData.products.length === 0) {
                          props.setCartvisible(false);
                        }
                      }}
                    />
                  </StockDiv>
                ) : (
                  ""
                )}

                <h4>Items ({newOrderData.products.length})</h4>
                <br />
                <GItemTable
                  items={newOrderData.products}
                  currencySymbol={settings.currency_symbol}
                  editable={true}
                  onRemove={(item) => removeProduct(item)}
                  onIncQuantity={(item) => addProduct(item, item.variants)}
                  onDecQuantity={(item) => decreaseQuantity(item)}
                  onSpecialRequest={(item) => {
                    setSelectedCartItem(item);
                    setFeatureRequestVisible(true);
                  }}
                />

                <React.Fragment>
                  <br />
                  <br />
                  <hr style={{ border: "1px solid #f2f2f2" }} />

                  <br />
                  <h4>Bill Details</h4>
                  <br />
                  {newOrderData?.summary?.data.map((p) => {
                    return (
                      <GTableContent
                        key={p.title}
                        title={p.title}
                        value={settings.currency_symbol + "" + p.value}
                        align="right"
                        lAlign="right"
                        color="#555"
                        padding="3px 5px"
                      />
                    );
                  })}
                </React.Fragment>

                <hr
                  style={{
                    border: "0",
                    borderBottom: "1px solid #e2e2e2",
                    margin: "5px 0",
                  }}
                />

                <GTableContent
                  title="To Pay"
                  value={
                    settings.currency_symbol +
                    "" +
                    newOrderData?.summary?.total_display
                  }
                  align="right"
                  lAlign="right"
                  bold={true}
                />
                {/* ### Div to show message for free delivery and minimum order => */}
                <div style={{ margin: "10px 0", fontSize: "14px" }}>
                  {newOrderData.minimum_order !== "" ? (
                    <span style={{ color: "orangered" }}>
                      *{newOrderData.minimum_order}{" "}
                    </span>
                  ) : newOrderData.free_delivery !== "" ? (
                    <span style={{ color: "#f3921d" }}>
                      *{newOrderData.free_delivery}
                    </span>
                  ) : (
                    <span style={{ color: "#f3921d" }}>
                      <b>Great!! You are Eligible for Free Delivery</b>
                    </span>
                  )}
                </div>

                <GButton
                  style={{ width: "100%", margin: "15px 0" }}
                  type="button"
                  variant="condensed"
                  disabled={
                    newOrderData.products.length !==
                      newOrderData.original_count ||
                    newOrderData.minimum_order !== ""
                      ? true
                      : false
                  }
                  children="Proceed to Checkout"
                  onClick={() => goToCheckout()}
                />
               
              </React.Fragment>
            ) : (
              <GEmpty
                type="cart"
                title="Nothing To Order"
                subtitle="Your cart is empty. Add some items from the menu items."
              />
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={featureRequestVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFeatureRequestVisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Item Special Request Modal"
      >
        <CartItemFeatureRequest
          featureRequestVisible={featureRequestVisible}
          setFeatureRequestVisible={setFeatureRequestVisible}
          settings={settings}
          item={selectedCartItem}
          onSubmit={(item) => {
            let existing = cart.findIndex((c) => c.id == item.id);
            if (existing >= 0) {
              let newArray = [...cart];
              let newItem = item;
              newArray[existing] = newItem;
              setCart(newArray);
            }
          }}
        />
      </Modal>
      {/* Otp modal ======> */}
      <Modal
        isOpen={verifyOtpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVerifyOtpvisible(false)}
        style={customStyles}
        contentLabel="Verify OTP Modal"
        shouldCloseOnOverlayClick={false}
      > <CloseIcon style={{position:"relative",top:"-10px",right:"-40%",width:"100%"}} onClick={()=>setVerifyOtpvisible(false)}/>
        <OtpVerify
          verifyOtpvisible={verifyOtpvisible}
          setVerifyOtpvisible={setVerifyOtpvisible}
          
        />
      </Modal>
    </React.Fragment>
  );
}

export default CartModal;

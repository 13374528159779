import React, { useEffect, useState } from "react";
import { ApiLoginService, ImgUrl, addDefaultSrc } from "../services";
// import "../css/Category.css";
import GShimmer from "../gComponents/gShimmer";
import { useLocation } from "react-router-dom";
import Products from "./products";
import styled from "styled-components";
import ProductsMobile from "./products-mobile";
import GInfo from "../gComponents/gInfo";
import { Card } from "../styledComponents/cardStyles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// import SaleProduct from "./SaleProduct";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CategoryProductNavMob from "./CategoryProductNavMob";
import "../css/categoryProductMobile.css"

const P = styled.p`
  padding: 10px 0;
  &.active {
    color: ${({ theme }) => theme.button};
  }
`;

const CategoryProducts = (props) => {
  const location = useLocation();
  const [storeData, setStoreData] = useState(
    props.location?.state?.store || {} //  props.location?.state?.store => store data
  );
  const [category, setCategory] = useState(props.location?.state?.data || []);
  const [isLoading, setIsLoading] = useState(
    !props.location?.state?.data || props.location?.state?.data.length === 0
      ? true
      : false
  );
  const [selectedCategory, setSelectedCategory] = useState(props.location?.state?.catId);

  const [selectedSubCategory, setSelectedSubCategory] = useState(
    props.location?.state?.subCat
  );
  const [divClicked, setDivClicked] = useState(null);
  const [sale, setSale] = useState(false); //to display sale component or not
  const [categoryMob, setCategoryMob] = useState(false);
  const [saleData, setSaleData] = useState([]); // sale data

  // console.log("locat====>", props.location)

const getSaleData = () => {
  ApiLoginService("GET", "c/products?sale=1&store_id=" + storeData?.id)
    .then((response) => {
      setSaleData(response.data.data);
      console.log("sale####", response.data.data);
      if(response.data.data.length!==0){
        setSale(true)
      }else{
        setSale(false)
      }
      if (response.status_code === 1) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

  const getStoreInfo = () => {
    ApiLoginService("GET", "c/stores/" + props.match.params.id) //props.match.params.id is store id
      .then((response) => {
        if (response.status_code === 1) {
          console.log("storeData====>",response.data)
          setStoreData(response.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCategories = () => {
    ApiLoginService("GET", "c/product/group?store_id=" + storeData?.id)
      .then((response) => {
        console.log("gh" + JSON.stringify(response.data));
        if (response.status_code === 1) {
          setCategory(response.data);
          setSelectedCategory(response.data[0].id);
          setSelectedSubCategory(response.data[0].subcategories[0]);

          setIsLoading(false);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    getSaleData();
    getStoreInfo();
    getCategories();
  }, [props.match.params]);

  // useEffect(() => {
  //   if (props.location?.state?.data) {
  //     setIsLoading(false);
  //     return;
  //   }

  //   if (!storeData?.id) {
  //     return;
  //   }
   

  //   getCategories();
  // }, [storeData,window.history]);

  return (
    <div className="main-content" style={{ padding: "20px 0", position:"relative" }}>
      <Card style={{ margin: "30px" }}>
        <GInfo
          title={storeData?.title}
          subtitle={storeData?.phone}
          subtitle2={storeData?.address}
          imgType="store"
          photo={storeData?.thumb_photo}
        />
      </Card>

      <div style={{ flex: "1", display: "flex" }}>
        {window.innerWidth > 767 ? (
          <div
            className="categoryProductNav"
            style={{
              width: "280px",
              height: "auto",
              minHeight: "max-content",
              background:
                "linear-gradient(0deg, rgba(254,226,155,1) 0%, rgba(242,232,207,1) 100%)",
              overflowY: "scroll",
              display: "inline-block",
              boxSizing: "border-box",
              padding: "20px 30px",
            }}>
            <span
              style={{
                display: saleData.length !== 0 ? "flex" : "none",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <P
                    style={{ cursor: "pointer" }}
                    onClick={() => setSale(true)}
                    className={sale?"active":""}>
                     Sale Products
                    </P> 
              {/* <h3
                style={{
                  fontSize: "17px",
                  color: "#777",
                  padding: "10px 0",
                  width: "70%",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
                onClick={() => setSale(true)}>
                Sale Products
              </h3> */}
              <ArrowForwardIosIcon />
            </span>
            {(!category || category.length === 0) && isLoading === true ? (
              <GShimmer></GShimmer>
            ) : (
              category.map((categories, index) => {
                return (
                  <div
                    style={{
                      borderBottom: "1px solid #e2e2e2",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    id={index}
                    key={index}
                    onClick={(e) => {
                      setSelectedCategory(categories.id);
                      setDivClicked(index);
                      // console.log("categories id====>",categories.id)

                      // handleToggle(index,e);
                    }}>
                    <span
                      key={index}
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <h4
                        style={{
                          fontSize: "17px",
                          color: "#777",
                          padding: "10px 0",
                          width: "70%",
                        }}>
                        {categories.title}
                      </h4>
                      {/* {divClicked===index && !divDisplay ?<RemoveIcon onClick={()=>{
                        setDivDisplay(true);
                        console.log("divdisplllllll", divDisplay)
                        }}/>:<AddIcon/>} */}
                      {divClicked === index ? <RemoveIcon /> : <AddIcon />}
                      {/* {divClicked===index && !divDisplay ?<RemoveIcon />:<AddIcon/>} */}
                    </span>
                    <div
                      style={
                        //  divClicked!==index?{display:"block",padding: "0 10px"}
                        // divDisplay?{display:"none"}
                        divClicked === index
                          ? { display: "block", padding: "0 10px" }
                          : { display: "none" }
                      }>
                      {categories.subcategories.map((sub, i) => {
                        return (
                          <React.Fragment key={i}>
                            {/* {isOpen && <p onClick={()=>manageState()} className="categories__subtitle">{items.title}</p>} */}
                            {selectedCategory &&
                            selectedCategory === parseInt(sub.parent_id) ? (
                              <P
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedSubCategory(sub);
                                  setSale(false);
                                  // console.log("subcategories id====>",sub)
                                }}
                                className={
                                  sub.id === selectedSubCategory?.id && !sale
                                    ? "active"
                                    : ""
                                }>
                                {sub.title}
                              </P>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        ) : (
          <>
          <div className="nav-open-btn" onClick={()=>setCategoryMob(true)}>
          <ArrowForwardIosIcon/>
          </div>
          <div className="categoryMobile" style={{visibility:categoryMob?"visible":"hidden"}}>
            {/* ***************##############Mobile View Navbar Starts here ####**************** */}
            <div className="nav-close-btn" onClick={()=>setCategoryMob(false)}>
            <ArrowBackIosIcon/>
            </div>
            <div style={{ flex: "1", display: "flex", height:"100%" }}>
              <div
                className="categoryProductNav"
                style={{
                  width: "100%",
                  height: "auto",
                  minHeight: "max-content",
                  background:
                    "linear-gradient(0deg, rgba(254,226,155,1) 0%, rgba(242,232,207,1) 100%)",
                  overflowY: "scroll",
                  display: "inline-block",
                  boxSizing: "border-box",
                  padding: "20px 30px",
                }}>
                <span
                  style={{
                    display: saleData.length !== 0 ? "flex" : "none",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <P
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSale(true)
                      setCategoryMob(false)}
                    }
                    className={sale?"active":""}>
                     Sale Products
                    </P> 
                  {/* <h3
                    style={{
                      fontSize: "17px",
                      color: "#777",
                      padding: "10px 0",
                      width: "70%",
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                    onClick={() => setSale(true)}>
                    Sale Products
                  </h3> */}
                  <ArrowForwardIosIcon />
                </span>
                {(!category || category.length === 0) && isLoading === true ? (
                  <GShimmer></GShimmer>
                ) : (
                  category.map((categories, index) => {
                    return (
                      <div
                        style={{
                          borderBottom: "1px solid #e2e2e2",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        id={index}
                        key={index}
                        onClick={(e) => {
                          setSelectedCategory(categories.id);
                          setDivClicked(index);
                        }}>
                        <span
                          key={index}
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}>
                          <h4
                            style={{
                              fontSize: "17px",
                              color: "#777",
                              padding: "10px 0",
                              width: "70%",
                            }}>
                            {categories.title}
                          </h4>
                          {divClicked === index ? <RemoveIcon /> : <AddIcon />}
                        </span>
                        <div
                          style={
                            divClicked === index
                              ? { display: "block", padding: "0 10px" }
                              : { display: "none" }
                          }>
                          {categories.subcategories.map((sub, i) => {
                            return (
                              <React.Fragment key={i}>
                                {selectedCategory &&
                                selectedCategory === parseInt(sub.parent_id) ? (
                                  <P
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSelectedSubCategory(sub);
                                      setSale(false);
                                      setCategoryMob(false);
                                    }}
                                    className={
                                      sub.id === selectedSubCategory?.id
                                        ? "active"
                                        : ""
                                    }>
                                    {sub.title}
                                  </P>
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
          </>
        )}
        <div
          style={{
            background: "#fff",
            width: window.innerWidth > 767 ? "calc(100% - 280px)" : "100%",
            verticalAlign: "top",
            display: "inline-block",
            padding: "20px 0",
            boxSizing: "border-box",
          }}>
          {/* {window.innerWidth > 767 ? (
            saleData.length !== 0 && sale ? (
              <SaleProduct storeId={storeData?.id} props={props} category={selectedSubCategory} />
            ) : (
              <Products
                storeId={storeData?.id}
                search={category}
                category={selectedSubCategory}
              />
            )
          ) : (
            <ProductsMobile
              storeId={storeData?.id}
              category={selectedSubCategory}
            />
          )} */}
          {window.innerWidth > 767 ? (
           
              <Products
                storeId={storeData?.id}
                search={category}
                category={sale?"":selectedSubCategory}
                sale={sale}
                setSale={setSale}
              />
           
          ) : (
            <ProductsMobile
              storeId={storeData?.id}
                search={category}
                category={sale?"":selectedSubCategory}
                sale={sale}
                setSale={setSale}
              
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CategoryProducts;

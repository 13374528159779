import React, { useState, useEffect } from "react";
import { FlatList, View, Platform } from "react-native"
// import { ApiService } from "../services";
import useSettings from "../useSettings";
// import { Grid } from "@material-ui/core";
import styled from "styled-components";
import GInfo from "../gComponents/gInfo";
// import GShimmer from "../gComponents/gShimmer";
// import GEmpty from "../gComponents/gEmpty";
import OrderInfo from "./orderDetail";
import useDataFctory from "../useDataFactory"
import GButton from "../gComponents/gButton";
import Modal from 'react-modal';

// const OrdersDiv = styled.div`
//   background: #fff;
//   margin: 10px;
//   padding: 12px 12px 20px;
//   box-sizing: border-box;
//   flex: 0.333;
// `;

export default function Orders(props) {
  const settings = useSettings(true);
  console.log(settings);
  const [orderInfovisible, setOrderInfovisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(props.location?.state?.order_id || '');
  const { loading, Shimmer, Placeholder, data, loadMore, pagination, refreshData } = useDataFctory('order', true);


  console.log(data)

  useEffect(() => {
    setBtnDisabled(false)
  }, [data]);

  useEffect(() => {
    console.log(props.location.state)
    if (props.location?.state?.order_id) {
      setSelectedOrderId(props.location?.state?.order_id)
      setTimeout(function () { setOrderInfovisible(true) }, 1000)
    }
  }, []);

  let count = Math.round(window.innerWidth / (Platform.OS === 'web' ? 600 : 300))


  return (
    <React.Fragment>
      {loading === true ? <Shimmer></Shimmer> :
        // ordersData.length === 0 ? <GEmpty type="order"></GEmpty> :
        <React.Fragment>

          <View style={{ flex: 1, flexDirection: 'row' }}>
            <FlatList
              // style={{ flex: 0 }}
              initialNumToRender={data.length}
              contentContainerStyle={{ flex: 1 }}
              keyExtractor={data => data.id}
              data={data}
              numColumns={count}
              renderItem={({ item }) => (<div style={{ flex: 1 / count, }}>
                <div onClick={() => { setSelectedOrderId(item?.id); setOrderInfovisible(true) }} style={{
                  background: '#fff',
                  padding: '12px 12px 20px',
                  margin: '10px', cursor: 'pointer', border: '1px solid #e8e8e8'
                }}>
                  <GInfo
                    title={"#" + item?.alpha_order_no}
                    rightTitle={settings.currency_symbol + item?.amount_display}
                    subtitle={item?.store.title}
                    subtitle2={item?.created}
                    imgType="store"
                    photo={item?.store.thumb_photo}
                    status={item?.status}
                  />
                </div>
              </div>)}
              ListEmptyComponent={Placeholder}
            // onEndReachedThreshold={0.4}
            // onEndReached={loadMore}
            />

          </View>

          {pagination.next_page_url ? <GButton variant="condensed" loading={btnDisabled} children="Load More" type="button" onClick={() => { setBtnDisabled(true); loadMore() }} style={{ display: 'flex', margin: '10px auto', minWidth: '150px' }} /> : ''}
        </React.Fragment>
        // <Grid container spacing={0}> {ordersData.map((o, i) => {
        //   return (
        //     <Grid item lg={4} md={6} xs={12} key={i}>
        //       <OrdersDiv onClick={() => { setSelectedOrderId(o.id); setOrderInfovisible(true) }}>
        //         <GInfo
        //           title={"#" + o.id}
        //           rightTitle={settings.currency_symbol + o.amount}
        //           subtitle={o.store.title}
        //           subtitle2={o.created}
        //           imgType="store"
        //           photo={o.store.thumb_photo}
        //           status={o.status}
        //         />
        //       </OrdersDiv>
        //     </Grid>
        //   );
        // })}
        // </Grid>
      }


      <Modal
        isOpen={orderInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => { setOrderInfovisible(false) }}
        style={{
          content: {
            top: '0',
            left: 'auto',
            right: '0',
            bottom: '0',
            width: '450px',
            padding: '20px 0px',
            overflow: 'hidden'
          }
        }}
        contentLabel="Order Detail Modal"
      >
        <OrderInfo orderInfovisible={orderInfovisible} setOrderInfovisible={setOrderInfovisible} linkedId={selectedOrderId} settings={settings} onCancelOrder={() => refreshData()} />
      </Modal>

      <br />
    </React.Fragment>
  );
}

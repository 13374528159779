import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GOffers from "../gComponents/gOffers";
import useDataFctory from "../useDataFactory";

export default function OrderCustomerAddress(props) {
  const { loading, Shimmer, Placeholder, data, refreshData } = useDataFctory(
    "coupon",
    false,
    { store_id: props.storeId }
  );
  const [couponCode, setCouponCode] = useState("");

  console.log(props.data + "--");

  if (props.couponVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      {/* <h3>Choose Variants</h3> */}

      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setCouponVisible(false)}
        />
        Apply Coupon
      </div>
      <div className="modal-body">
        {loading === true ? (
          <Shimmer />
        ) : data.data.length === 0 ? (
          <Placeholder />
        ) : (
          <React.Fragment>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Coupon code..."
                value={couponCode || ""}
                style={{
                  border: "0",
                  outline: "0",
                  background: "#f4f5f9",
                  width: "63%",
                  borderRadius: "4px",
                  padding: "12px",
                }}
                onBlur={(e) => setCouponCode(e.target.value)}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <GButton
                variant="condensed"
                children="Apply"
                style={{ marginLeft: "10px" }}
                disabled={couponCode ? false : true}
                onClick={() => {
                  // console.log(couponCode);
                  props.onApplyCoupon(couponCode);
                  // props.setCouponVisible(false);
                }}
              />
            </div>
            <br />
            <Grid container spacing={2}>
              {data.data.map((c, i) => {
                return (
                  <Grid item sm={12} xs={12} key={i}>
                    <GOffers
                      data={c}
                      onCopy={(coupon) => {
                        setCouponCode(coupon.code);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import { Info } from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "../assets/images/address-home.svg";
import { ReactComponent as OfficeIcon } from "../assets/images/address-office.svg";
import { ReactComponent as OtherIcon } from "../assets/images/address-other.svg";
import { ImgUrl } from "../services";

const Div = styled.div`
  color: #000;
  background: transparent;

  &.selected {
    background: ${({ theme }) => theme.button};
    color: #fff;
    .icon svg {
      #Artboard {
        stroke: #fff;
      }
      path {
        stroke: #fff;
      }
      #Rectangle,
      #Circle,
      circle#Oval {
        fill: #fff;
      }
    }
  }
  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0 5px;
  }
  .icon svg {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
`;

function radioButton(props) {
  const {
    label,
    name,
    options,
    selected,
    key_title,
    style,
    icons,
    key_value,
    ...rest
  } = props;
  return (
    <div className="form-control" style={props.mainStyle}>
      {label ? (
        <label
          style={{
            fontSize: "0.98rem",
            color: "#333",
            marginBottom: "5px",
          }}
        >
          {label}
        </label>
      ) : (
        ""
      )}
      <Field name={name} {...rest}>
        {({ field }) => {
          console.log(field.value);
          return options.map((option, index) => {
            return (
              <Div
                key={index}
                className={selected === option[key_value] ? "selected" : ""}
                style={Object.assign(
                  {},
                  {
                    display: "inline-block",
                  },
                  props.divstyle
                )}
              >
                <input
                  type="radio"
                  id={name + "_option_" + option[key_value]}
                  {...field}
                  value={option[key_value]}
                  checked={field.value == option[key_value]}
                  style={style}
                />
                <label
                  style={Object.assign({}, props.labelStyle, {
                    color: selected === option[key_value] ? "#fff" : "#000",
                  })}
                  htmlFor={name + "_option_" + option[key_value]}
                >
                  {props.showImg === true ? (
                    <React.Fragment>
                      <img
                        src={ImgUrl(props.imgType) + "/" + option.photo}
                        alt=""
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "cover",
                        }}
                      />{" "}
                      <br />
                    </React.Fragment>
                  ) : (
                    ""
                  )}

                  {icons === true ? (
                    <span className="icon">
                      {option[key_title] === "HOME" ? (
                        // <HomeOutlined
                        //   style={{
                        //     verticalAlign: "sub", fontSize: '21px',
                        //     color:
                        //       selected === option[key_value] ? "#fff" : "#000",
                        //   }}
                        // />
                        <HomeIcon />
                      ) : option[key_title] === "WORK" ? (
                        // <BusinessCenterOutlined
                        //   style={{
                        //     verticalAlign: "sub", fontSize: '21px',
                        //     color:
                        //       selected === option[key_value] ? "#fff" : "#000",
                        //   }}
                        // />
                        <OfficeIcon />
                      ) : (
                        // <PinDropOutlined
                        //   style={{
                        //     verticalAlign: "sub", fontSize: '21px',
                        //     color:
                        //       selected === option[key_value] ? "#fff" : "#000",
                        //   }}
                        // />
                        <OtherIcon />
                      )}{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {option[key_title]}

                  {option.tooltipValue ? (
                    <TooltipComponent
                      title={option.tooltipValue}
                      position="bottom"
                      id={option[key_title]}
                    >
                      <Info fontSize="small" />
                    </TooltipComponent>
                  ) : (
                    ""
                  )}
                </label>
              </Div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default radioButton;

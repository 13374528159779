import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import useSettings from "../useSettings";
import { Card, CardHead } from "../styledComponents/cardStyles";
import styled from "styled-components";
import GTableShimmer from "../gComponents/gTableShimmer";
import pointsEarn from "../assets/images/point-earn.png";
import pointsNotEarn from "../assets/images/point-not-earn.png";
import GButton from "../gComponents/gButton";

import useDataFctory from "../useDataFactory"

const Div = styled.div`
.right-sub-section{
  @media (min-width: 600px) {
  border-left:1px solid #f2f2f2;
  }
  @media (max-width: 599px) {
    border-top:1px solid #f2f2f2;
   }
}
`;
const WalletDiv = styled.div`
margin:10px;
padding:10px 0;
border-bottom:1px solid #f2f2f2;
@media (max-width: 599px) {
  margin:0
 }
img{
  width:18px;height:17px;padding-right:15px;vertical-align:top;padding-top:5px;
}
h4{
  margin:0 0 5px;
  font-size:16px;
  span{
    float:right;color:#b2b2b2;font-weight:400;font-size:13px;font-family:LatoWeb;
  }
}
p{
  margin:5px 0;
  font-size:15px;line-height:23px
}

  `;

export default function PrivacyPolicy() {
  const settings = useSettings(true);
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [walletData, setWalletData] = useState([]);
  console.log(settings);

  const { loading, Shimmer, Placeholder, data, loadMore, pagination, refreshData } = useDataFctory('wallet', true)
  useEffect(() => {
    setBtnDisabled(false)
  }, [data]);


  return (
    <Div>
      <CardHead style={{ margin: '20px 10px 15px' }}>Wallet</CardHead>
      <Card style={{ padding: '25px 0 25px 25px' }}>
        {loading === true ? <GTableShimmer></GTableShimmer> :
          data.length === 0 ? <Placeholder /> :
            <Grid container spacing={3}>
              <Grid item lg={3} sm={4} xs={12} style={{ verticalAlign: 'top' }}>
                <h5 style={{ color: '#777', margin: '10px 0', fontSize: '15px' }}>Available Points</h5>
                {/* <p></p> */}
                <h4 style={{ margin: '10px 0', fontSize: '20px' }}>{settings?.user?.wallet}</h4>
              </Grid>
              <Grid item lg={9} sm={8} xs={12} className="right-sub-section">
                <h5 style={{ margin: '10px 0', color: '#a2a2a2' }}> &nbsp;{data.length} Transactions</h5>
                <div style={{ maxHeight: '600px', overflow: 'auto', padding: '0 25px 0 0' }}>
                  {data.map((w, i) => {
                    return (
                      <WalletDiv>
                        <img src={w.amount >= 0 ? pointsEarn : pointsNotEarn} alt="" />
                        <div style={{ width: 'calc(100% - 35px)', display: 'inline-block' }}>
                          <h4>{w.amount} <span>{w.created}</span></h4>
                          <p>{w.title}</p>
                        </div>
                      </WalletDiv>
                    )
                  })}

                  {pagination.next_page_url ? <GButton variant="condensed" loading={btnDisabled} children="Load More" type="button" onClick={() => { setBtnDisabled(true); loadMore() }} style={{ display: 'flex', margin: '20px auto 10px', minWidth: '150px' }} /> : ''}
                </div>

              </Grid>
            </Grid>
        }
      </Card>
      <br />
    </Div>
  );
}
